<template>
  <div class="sticky-button">
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      size="lg"
      class="btn-icon rounded-circle"
      v-b-tooltip.hover
      title="Add Task"
      v-on:click="showModal"
      v-if="
        $permissionAbility(TASK_MANAGEMENT_GLOBAL_TRACKER_ACCESS, permissions)
      "
    >

      <feather-icon icon="PlusIcon" />
    </b-button>
    <b-modal
      id="global-task-add-modal"
      centered
      title="Create New Task"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="globalTaskAddValidation">
        <b-form v-on:submit.prevent="globalTaskAddValidationForm">
          <b-form-group
            label="Milestone Task Board *"
            label-for="milestone_task_board_id"
          >
            <ValidationProvider
              name="milestone task board"
              v-slot="{ errors }"
              vid="milestone_task_board_id"
            >
              <v-select
                id="milestone_task_board_id"
                placeholder="Search by Project Name / Board Name"
                v-model="milestoneTaskBoardId"
                :options="milestoneTaskBoardOptions"
                :reduce="(item) => item.id"
                label="name"
                rules="required"
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-group label="Task Title *" label-for="title">
            <ValidationProvider
              #default="{ errors }"
              name="title"
              vid="title"
              rules="required|max:255"
            >
              <b-form-input
                id="title"
                type="text"
                v-model="taskTitle"
                :state="errors.length > 0 ? false : null"
                placeholder="Enter Task Title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>
          <b-form-group label="Member *" label-for="member_id">
            <ValidationProvider
              name="member"
              v-slot="{ errors }"
              vid="member_id"
            >
              <v-select
                id="member_id"
                placeholder="Search by Employee Name / ID"
                v-model="memberIds"
                :options="memberIdOptions"
                :reduce="(item) => item?.id"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
              >
                <template #option="{ name, avatar }">
                  <span>
                    <b-avatar :src="avatar" size="26" class="mr-1" />{{
                      name
                    }}</span
                  >
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <template v-if="isLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Create
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  VBTooltip,
  BButton,
  BForm,
  BModal,
  BFormGroup,
  BAvatar,
  BFormInput,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { TASK_MANAGEMENT_GLOBAL_TRACKER_ACCESS } from "@/helpers/permissionsConstant";

import { mapGetters } from "vuex";
export default {
  name: "GlobalAddTaskModal",
  data() {
    return {
      isLoading: false,
      TASK_MANAGEMENT_GLOBAL_TRACKER_ACCESS,
      milestoneTaskBoardId: "",
      milestoneTaskBoardOptions: [],
      memberIds: [],
      memberIdOptions: [],
      taskTitle: "",
    };
  },

  components: {
    BButton,
    VBTooltip,
    BButton,
    BForm,
    BModal,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BAvatar,
    BFormInput,
    BSpinner,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  created() {
    this.loadItems();
  },

  methods: {
    showModal() {
      this.$bvModal.show("global-task-add-modal");
    },
    hiddenModal() {
      this.$bvModal.hide("global-task-add-modal");
    },

    resetModal() {
      this.milestoneTaskBoardId = "";
      this.memberIds = [];
      this.milestoneTaskBoardOptions = [];
      this.taskTitle = "";
    },

    onShowPage(id) {
      // Build the URL for the route
      const routeURL = this.$router.resolve({
        name: "admin-task-board-details",
        params: { id },
      }).href;

      // Open the route URL in a new tab/window
      window.open(routeURL, "_blank");
    },

    async getAllUsers() {
      return await this.$api.get(`api/users/active-all`);
    },

    // async getApprovedProjects() {
    //   return await this.$api.get("api/projects/approve");
    // },

    //milestone-task-boards
    async getMilestoneTaskBoards() {
      return await this.$api.get("api/milestone-task-boards/all/boards");
    },
    async loadItems() {
      const [allMembers, allMilestoneBoards] = await Promise.all([
        this.getAllUsers(),
        this.getMilestoneTaskBoards(),
      ]);

      //filter milestone task board
      this.milestoneTaskBoardOptions = (allMilestoneBoards?.data?.data || []).map((item) => {
        let name = item?.title;
          return {
            name,
            id: item?.id,
          };
        });

      //filter Member
      this.memberIdOptions = (allMembers?.data?.data || []).map((item) => {
        let name = item?.name;
        if (item?.employee_number) {
          name = `${item?.name}  (${item?.employee_number})`;
        }

        return {
          name,
          id: item?.id,
          avatar: item?.avatar,
        };
      });
    },

    globalTaskAddValidationForm: async function () {
      this.$refs.globalTaskAddValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.globalTaskAddValidation.reset();
            this.isLoading = true;
            await this.$api.post(
              `/api/milestone-task-boards/global/tasks/store`,
              {
                title: this.taskTitle,
                member_id: this.memberIds,
                milestone_task_board_id: this.milestoneTaskBoardId,
              }
            );
            this.isLoading = false;
            this.onShowPage(this.milestoneTaskBoardId);
            this.loadItems();
            this.hiddenModal();
            this.resetModal();
          } catch (error) {
            this.isLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }
            if (error?.response?.data?.errors) {
              this.$refs.globalTaskAddValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.sticky-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* background-color: #007bff;
  color: white; */
  /* border-radius: 50%; */
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9999; /* Ensure it's above other content */
}
</style>
