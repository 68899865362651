var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "invoice-add-wrapper"
  }, [_c('b-row', {
    staticClass: "invoice-add"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "9",
      "md": "8"
    }
  }, [_c('b-card', {
    staticClass: "invoice-preview-card",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', [_c('div', {
    staticClass: "logo-wrapper"
  }, [_vm.companyLogo ? [_c('img', {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      "src": _vm.companyLogo,
      "alt": "Company Logo",
      "height": "60"
    }
  })] : _vm._e()], 2)]), _c('div', {
    staticClass: "invoice-number-date mt-md-0 mt-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', [_vm.status === _vm.paymentPaidConstants ? _c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v("Paid")]) : _vm.status === _vm.paymentDueConstants ? _c('b-badge', {
    attrs: {
      "variant": "light-danger",
      "f": ""
    }
  }, [_vm._v("Due")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-md-end mb-1"
  }, [_c('h4', {
    staticClass: "invoice-title"
  }, [_vm._v("Invoice")]), _c('b-input-group', {
    staticClass: "input-group-merge invoice-edit-input-group disabled"
  }, [_c('b-form-input', {
    attrs: {
      "id": "invoice-data-id",
      "disabled": ""
    },
    model: {
      value: _vm.invoiceId,
      callback: function callback($$v) {
        _vm.invoiceId = $$v;
      },
      expression: "invoiceId"
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex align-items-center mb-1"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Generated Date: ")]), _c('b-form-group', [_c('b-form-input', {
    staticClass: "form-control invoice-edit-input",
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.invoiceDate,
      callback: function callback($$v) {
        _vm.invoiceDate = $$v;
      },
      expression: "invoiceDate"
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v(" Payment Date: ")]), _c('b-form-group', [_c('b-form-input', {
    staticClass: "form-control invoice-edit-input",
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.paymentDate,
      callback: function callback($$v) {
        _vm.paymentDate = $$v;
      },
      expression: "paymentDate"
    }
  })], 1)], 1)])])]), _c('hr', {
    staticClass: "invoice-spacing"
  }), _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('b-row', {
    staticClass: "invoice-spacing"
  }, [_c('b-col', {
    staticClass: "mb-lg-1",
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('h5', {
    staticClass: "mb-30"
  }, [_vm._v("Invoice From:")]), _c('div', [_c('b', [_vm._v("Address")]), _c('div', {
    domProps: {
      "innerHTML": _vm._s(this.officeAddress)
    }
  }), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v(_vm._s(this.officeEmail))]), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v(_vm._s(this.officeMobile))])])]), _c('b-col', {
    staticClass: "mt-xl-0 mt-2 d-flex align-items-end flex-column",
    attrs: {
      "xl": "6",
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-30"
  }, [_vm._v("Invoice To:")]), _c('h6', {
    staticClass: "d-inline mb-25"
  }, [_vm._v(" " + _vm._s(_vm.sellerName) + " ")]), _c('b-badge', {
    staticClass: "ms-2",
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(_vm._s(_vm.sellerWebsite))]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Email: " + _vm._s(_vm.sellerEmail) + " ")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Contact No: " + _vm._s(_vm.sellerContact) + " ")])], 1)], 1)], 1), _c('b-card-body', {
    staticClass: "invoice-padding form-item-section"
  }, [_c('div', {
    ref: "form",
    staticClass: "repeater-form"
  }, [_c('b-row', {
    ref: "row",
    staticClass: "pb-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-none d-lg-flex"
  }, [_c('b-row', {
    staticClass: "flex-grow-1 px-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, [_vm._v(" Item ")]), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_vm._v(" Project ")]), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "2"
    }
  }, [_vm._v(" Type ")]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12",
      "lg": "2"
    }
  }, [_vm._v(" Amount ")])], 1), _c('div', {
    staticClass: "form-item-action-col"
  })], 1), _c('div', {
    staticClass: "border rounded"
  }, _vm._l(_vm.invoiceItems, function (item, index) {
    var _item$project, _item$project$data, _item$currency, _item$currency$data;
    return _c('div', {
      key: index,
      staticClass: "d-flex"
    }, [_c('b-row', {
      staticClass: "flex-grow-1 p-2"
    }, [_c('b-col', {
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "5"
      }
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v("Backlink: ")]), _c('a', {
      attrs: {
        "href": _vm.sellerWebsite + '/' + item.backlink_url,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatWebsite(_vm.sellerWebsite + '/' + item.backlink_url)) + " ")]), _c('br'), _c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v("URL: ")]), _c('a', {
      attrs: {
        "href": item.targeted_url,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(" " + _vm._s(item.targeted_url) + " ")])]), _c('b-col', {
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "3"
      }
    }, [_vm._v(" " + _vm._s((_item$project = item.project) === null || _item$project === void 0 ? void 0 : (_item$project$data = _item$project.data) === null || _item$project$data === void 0 ? void 0 : _item$project$data.title) + " ")]), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "2"
      }
    }, [_vm._v(" " + _vm._s(item.backlinks_type_text) + " ")]), _c('b-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "2"
      }
    }, [_vm._v(" " + _vm._s((_item$currency = item.currency) === null || _item$currency === void 0 ? void 0 : (_item$currency$data = _item$currency.data) === null || _item$currency$data === void 0 ? void 0 : _item$currency$data.currency) + " " + _vm._s(item.cost_price) + " ")])], 1)], 1);
  }), 0)])], 1)], 1)]), _c('b-card-body', {
    staticClass: "invoice-padding pb-0 mb-4"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-md-0 mt-3 d-flex align-items-center",
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "2",
      "order-md": "1"
    }
  }), _c('b-col', {
    staticClass: "mt-md-6 d-flex justify-content-end",
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "1",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "invoice-total-wrapper custom-width"
  }, [_c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Sub Total:")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" " + _vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.subTotalAmount) + " ")])]), _c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Discount"), _vm.discountType === 'percentage' ? [_vm._v(" (" + _vm._s(_vm.discount_value) + "%)")] : _vm._e(), _vm._v(":")], 2), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" " + _vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.discountAmount) + " ")])]), _c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Fee"), _vm.feeType === 'percentage' ? [_vm._v(" (" + _vm._s(_vm.fee_value) + "%)")] : _vm._e(), _vm._v(":")], 2), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" " + _vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.feeAmount) + " ")])]), _c('hr', {
    staticClass: "my-50"
  }), _c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Grand Total:")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" " + _vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.grandTotalAmount) + " ")])])])])], 1)], 1), _vm.remarks ? _c('hr', {
    staticClass: "invoice-spacing"
  }) : _vm._e(), _vm.remarks ? _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Note: ")]), _c('b-form-textarea', {
    attrs: {
      "disabled": ""
    },
    model: {
      value: _vm.remarks,
      callback: function callback($$v) {
        _vm.remarks = $$v;
      },
      expression: "remarks"
    }
  })], 1) : _vm._e()], 1)], 1), _c('b-col', {
    staticClass: "invoice-actions",
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "3"
    }
  }, [_c('b-card', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "disabled": _vm.status === _vm.paymentPaidConstants,
      "variant": "success",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.payInvoice();
      }
    }
  }, [_vm._v(" Pay ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-secondary",
      "block": ""
    },
    on: {
      "click": _vm.printInvoice
    }
  }, [_vm._v(" Print ")]), _c('div', {
    staticClass: "my-3"
  }, [_c('h6', [_vm._v("Reviewer:")]), _c('UserCard', {
    attrs: {
      "user": _vm.approver
    }
  })], 1), _c('b-col', {
    staticClass: "mt-xl-0 mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('h6', {
    staticClass: "mb-30"
  }, [_vm._v("Payment Details:")]), _c('table', [_c('tbody', [_c('div', [_c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Payment Method:")]), _c('td', [_vm._v(_vm._s(_vm.paymentMethod))])]), _vm.paymentDetails ? _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Remarks:")]), _c('td', [_vm._v(_vm._s(_vm.paymentDetails))])]) : _vm._e()])])])])])], 1)], 1)], 1), _c('div', {
    staticStyle: {
      "display": "none",
      "flex-direction": "column",
      "width": "100%",
      "padding": "20px",
      "font-family": "Arial, sans-serif"
    },
    attrs: {
      "id": "content"
    }
  }, [_c('div', {
    staticStyle: {
      "font-family": "Arial, sans-serif",
      "margin": "20px",
      "padding": "20px",
      "border": "1px solid #ccc"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "margin-bottom": "20px"
    }
  }, [_c('div', [_c('div', {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_vm.companyLogo ? [_c('img', {
    attrs: {
      "src": _vm.companyLogo,
      "alt": "Company Logo",
      "height": "60"
    }
  })] : _vm._e()], 2)]), _c('div', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('div', [_vm.status === _vm.paymentPaidConstants ? [_c('span', {
    staticStyle: {
      "background-color": "#d4edda",
      "color": "#155724",
      "padding": "5px 10px",
      "border-radius": "5px"
    }
  }, [_vm._v("Paid")])] : _vm.status === _vm.paymentDueConstants ? [_c('span', {
    staticStyle: {
      "background-color": "#f8d7da",
      "color": "#721c24",
      "padding": "5px 10px",
      "border-radius": "5px"
    }
  }, [_vm._v("Due")])] : _vm._e()], 2), _c('h4', {
    staticStyle: {
      "margin": "10px 0"
    }
  }, [_vm._v("Invoice")]), _c('div', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c('strong', [_vm._v("Invoice ID:")]), _vm._v(" " + _vm._s(_vm.invoiceId) + " ")]), _c('div', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c('strong', [_vm._v("Generated Date:")]), _vm._v(" " + _vm._s(_vm.invoiceDate) + " ")]), _c('div', [_c('strong', [_vm._v("Payment Date:")]), _vm._v(" " + _vm._s(_vm.paymentDate) + " ")])])]), _c('hr', {
    staticStyle: {
      "margin": "20px 0"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "margin-bottom": "20px"
    }
  }, [_c('div', [_c('h5', [_vm._v("Invoice From:")]), _c('div', [_c('strong', [_vm._v("Address:")]), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.officeAddress)
    }
  }), _c('p', [_vm._v("Email: " + _vm._s(_vm.officeEmail))]), _c('p', [_vm._v("Contact No: " + _vm._s(_vm.officeMobile))])])]), _c('div', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('h5', [_vm._v("Invoice To:")]), _c('h6', [_vm._v(_vm._s(_vm.sellerName))]), _c('span', {
    staticStyle: {
      "background-color": "#cce5ff",
      "color": "#004085",
      "padding": "5px 10px",
      "border-radius": "5px"
    }
  }, [_vm._v(_vm._s(_vm.sellerWebsite))]), _c('p', [_vm._v("Email: " + _vm._s(_vm.sellerEmail))]), _c('p', [_vm._v("Contact No: " + _vm._s(_vm.sellerContact))])])]), _c('div', {
    staticStyle: {
      "border": "1px solid #ccc",
      "border-radius": "5px",
      "padding": "10px"
    }
  }, [_vm._m(0), _c('div', [_vm._l(_vm.invoiceItems, function (item, index) {
    var _item$project2, _item$project2$data, _item$currency2, _item$currency2$data;
    return [_c('div', {
      staticStyle: {
        "display": "flex",
        "padding": "10px 0"
      }
    }, [_c('div', {
      staticStyle: {
        "flex": "1"
      }
    }, [_c('strong', [_vm._v("Backlink:")]), _vm._v(" "), _c('a', {
      attrs: {
        "href": _vm.sellerWebsite + '/' + item.backlink_url,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(_vm.formatWebsite(_vm.sellerWebsite + '/' + item.backlink_url)))]), _c('br'), _c('strong', [_vm._v("URL:")]), _vm._v(" "), _c('a', {
      attrs: {
        "href": item.targeted_url,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(item.targeted_url))])]), _c('div', {
      staticStyle: {
        "flex": "1"
      }
    }, [_vm._v(_vm._s((_item$project2 = item.project) === null || _item$project2 === void 0 ? void 0 : (_item$project2$data = _item$project2.data) === null || _item$project2$data === void 0 ? void 0 : _item$project2$data.title))]), _c('div', {
      staticStyle: {
        "flex": "1"
      }
    }, [_vm._v(_vm._s(item.backlinks_type_text))]), _c('div', {
      staticStyle: {
        "flex": "1",
        "text-align": "right"
      }
    }, [_vm._v(_vm._s((_item$currency2 = item.currency) === null || _item$currency2 === void 0 ? void 0 : (_item$currency2$data = _item$currency2.data) === null || _item$currency2$data === void 0 ? void 0 : _item$currency2$data.name) + " " + _vm._s(item.cost_price))])])];
  })], 2)]), _c('div', {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between"
    }
  }, [_c('div', {
    staticStyle: {
      "flex": "1"
    }
  }), _c('div', {
    staticStyle: {
      "flex": "1",
      "text-align": "right"
    }
  }, [_c('div', [_c('p', [_c('strong', [_vm._v("Sub Total:")]), _vm._v(" " + _vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.subTotalAmount))]), _c('p', [_c('strong', [_vm._v("Discount "), _vm.discountType === 'percentage' ? [_vm._v(" (" + _vm._s(_vm.discount_value) + "%)")] : _vm._e(), _vm._v(":")], 2), _vm._v(" " + _vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.discountAmount))]), _c('p', [_c('strong', [_vm._v("Fee:")]), _vm._v(" " + _vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.feeAmount))]), _c('hr', {
    staticStyle: {
      "margin": "10px 0"
    }
  }), _c('p', [_c('strong', [_vm._v("Grand Total:")]), _vm._v(" " + _vm._s(_vm.getCurrency()) + " " + _vm._s(_vm.grandTotalAmount))])])])])]), _vm.remarks ? _c('hr', {
    staticStyle: {
      "margin": "20px 0"
    }
  }) : _vm._e(), _vm.remarks ? _c('div', {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('strong', [_vm._v("Note:")]), _c('p', [_vm._v(_vm._s(_vm.remarks))])]) : _vm._e()])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "flex",
      "border-bottom": "1px solid #ccc",
      "padding": "10px 0"
    }
  }, [_c('div', {
    staticStyle: {
      "flex": "1"
    }
  }, [_c('strong', [_vm._v("Item")])]), _c('div', {
    staticStyle: {
      "flex": "1"
    }
  }, [_c('strong', [_vm._v("Project")])]), _c('div', {
    staticStyle: {
      "flex": "1"
    }
  }, [_c('strong', [_vm._v("Type")])]), _c('div', {
    staticStyle: {
      "flex": "1",
      "text-align": "right"
    }
  }, [_c('strong', [_vm._v("Amount")])])]);
}]

export { render, staticRenderFns }