var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sticky-button"
  }, [_vm.$permissionAbility(_vm.TASK_MANAGEMENT_GLOBAL_TRACKER_ACCESS, _vm.permissions) ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "btn-icon rounded-circle",
    attrs: {
      "variant": "primary",
      "size": "lg",
      "title": "Add Task"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PlusIcon"
    }
  })], 1) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": "global-task-add-modal",
      "centered": "",
      "title": "Create New Task",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "globalTaskAddValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.globalTaskAddValidationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Milestone Task Board *",
      "label-for": "milestone_task_board_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "milestone task board",
      "vid": "milestone_task_board_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "milestone_task_board_id",
            "placeholder": "Search by Project Name / Board Name",
            "options": _vm.milestoneTaskBoardOptions,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name",
            "rules": "required"
          },
          model: {
            value: _vm.milestoneTaskBoardId,
            callback: function callback($$v) {
              _vm.milestoneTaskBoardId = $$v;
            },
            expression: "milestoneTaskBoardId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Task Title *",
      "label-for": "title"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Task Title"
          },
          model: {
            value: _vm.taskTitle,
            callback: function callback($$v) {
              _vm.taskTitle = $$v;
            },
            expression: "taskTitle"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Member *",
      "label-for": "member_id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "member",
      "vid": "member_id"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "member_id",
            "placeholder": "Search by Employee Name / ID",
            "options": _vm.memberIdOptions,
            "reduce": function reduce(item) {
              return item === null || item === void 0 ? void 0 : item.id;
            },
            "label": "name",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "multiple": ""
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(_ref4) {
              var name = _ref4.name,
                avatar = _ref4.avatar;
              return [_c('span', [_c('b-avatar', {
                staticClass: "mr-1",
                attrs: {
                  "src": avatar,
                  "size": "26"
                }
              }), _vm._v(_vm._s(name))], 1)];
            }
          }], null, true),
          model: {
            value: _vm.memberIds,
            callback: function callback($$v) {
              _vm.memberIds = $$v;
            },
            expression: "memberIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Create ")])]], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }