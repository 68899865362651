<template>
  <b-card title="Lead Contact Status">
    <!-- chart -->

      <template v-if="data?.datasets?.length > 0">
        <template v-if="enableWidget">
          <chartjs-component-doughnut-chart
              :height="275"
              :data="data"
              :options="options"
              class="mb-3"
          />
        </template>
      </template>
      <template v-else>
      <span class="d-flex justify-content-center align-items-center">
        <b-spinner variant="primary"/>
      </span>
      </template>
    <!--/ chart -->

    <!-- stocks -->
    <div
        v-for="(lead,index) in leadStatus"
        :class="index < leadStatus.length-1 ? 'mb-1':''"
        class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <feather-icon
            :icon="'TargetIcon'"
            size="16"
        />
        <span class="font-weight-bold ml-75 mr-25">
          {{ index === 'contacted_count'
            ? "Contacted" :
            index === 'not_contacted_count'
                ? "Not Contacted" :
                "To be contact" }}
        </span>
      </div>
      <div class="mt-1">
        <b-badge :style="{ backgroundColor: index === 'contacted_count'
        ? chartColors.successColorShade :
    index === 'not_contacted_count'
    ? chartColors.warningColorShade :
    $themeColors.primary, color: 'white' }">
          {{ lead }}
        </b-badge>

      </div>
    </div>
    <!--/ stocks -->

  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormTextarea,
  BFormDatepicker,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { dashBoardNextTimeFilterConstants } from "@/helpers/constant/dashBoardNextTimeFilterConstant.js";
import { dashBoardPreviousTimeFilterConstants } from "@/helpers/constant/dashBoardPreviousTimeFilterConstant.js";
import { co } from '@fullcalendar/core/internal-common';
import ChartjsComponentDoughnutChart
  from "@/layouts/components/dash-board/widgets/charts-components/ChartjsComponentDoughnutChart.vue";
import chartjsData from "@/layouts/components/dash-board/widgets/chartjsData";
import {$themeColors} from "@themeConfig";

export default {
  name: "LeadContactDayCountWidget",
  computed: {
    $themeColors() {
      return $themeColors
    }
  },
  components: {
    ChartjsComponentDoughnutChart,
    VueGoodTable,
    BLink,
    BForm,
    BButton,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      chartjsData,
      leadStatus: [],
      chartColors: {
        primaryColorShade: '#836AF9',
        yellowColor: '#ffe800',
        successColorShade: '#28dac6',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
      },
      data: {},
      enableWidget: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 60,
        legend: { display: false },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[0].labels[tooltipItem.index] || ''
              const value = data.datasets[0].data[tooltipItem.index]
              const total = data.datasets[0].data.reduce((acc, val) => acc + val, 0)
              const percentage = ((value / total) * 100).toFixed(2)
              return ` ${label} : ${percentage}%`
            },
          },
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: '',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
      },
      labels: ['Contacted', 'Not Contacted', 'To Be Contacted']
    }
  },

  async mounted() {
    const [status] = await Promise.all([this.getLeads()])
    this.leadStatus = status.data;

    let countData = [this.leadStatus.contacted_count, this.leadStatus.not_contacted_count, this.leadStatus.to_be_contacted_count];

    for (let i = 0; i < countData.length; i++) {
      if (countData[i] > 0) {
        this.enableWidget = true
      }
    }

    this.data = {
      datasets: [
        {
          labels: this.labels,
          data: countData,
          backgroundColor: [this.chartColors.successColorShade, this.chartColors.warningLightColor, $themeColors.primary],
          borderWidth: 0,
          pointStyle: 'rectRounded',
        },
      ],
    }

    console.log(this.data)
    console.log(this.options)

    this.options.tooltips.shadowColor = this.chartColors.tooltipShadow
  },
  methods: {
    async getLeads() {
      return await this.$api.get(
        "api/dashboard/admin-dashboard/lead-contact-count"
      );
    },
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/ui-feather.scss";

.table-custom-style-lead {
  // white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
    text-align: center !important;
    font-size: 10px !important;
  }
}

.custom-font {
  font-size: 10 !important;
}

// .cell-with-description div {
//   margin: 2px 0px;
// }

.button-style {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 15px;
}
.custom-title-margin {
  margin-left: 15px;
  margin-bottom: 15px;
}
</style>
