<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template v-if="$permissionAbility(REMOTE_ATTENDANCE_CREATE, permissions)">
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Assign
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        styleClass="vgt-table table-custom-style condensed striped"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="totalRecords"
        :isLoading.sync="isLoading"
        :rows="rows"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'id', type: 'desc' }],
        }"
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">

          <template v-if="props.column.field === 'format_user'">
            <UserCard :user="props?.row?.user?.data" />
          </template>

          <template v-if="props.column.field === 'format_ip_authorize'">
            <b-badge :variant="props.row.ip_authorize ? 'light-success' : 'light-danger'">
              {{ props.row.ip_authorize ? 'Enable' : 'Disable' }}
            </b-badge>
          </template>

          <template v-if="props.column.field === 'format_ip'">
            <pre>{{ props?.row?.ip_addresses?.join('\n') }}</pre>
          </template>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">

            <template v-if="$permissionAbility(REMOTE_ATTENDANCE_EDIT, permissions)">
                <span @click="onShow(props.row)">
                  <feather-icon
                      v-b-tooltip.hover
                      icon="Edit2Icon"
                      class="mr-50 custom-icon cursor-pointer"
                      title="Edit"
                      size="16"
                  />
                </span>
              </template>
            <template
                  v-if="$permissionAbility(REMOTE_ATTENDANCE_DELETE, permissions)"
              >
                <span @click="onDelete(props.row)">
                  <feather-icon
                      v-b-tooltip.hover
                      icon="TrashIcon"
                      class="mr-50 custom-icon cursor-pointer"
                      title="Delete"
                      size="16"
                  />
                </span>
              </template>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-remote-attendance-form"
      centered
      :title="'Create'"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="remoteAttendanceValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- select Remote Attendance Policies -->

          <b-form-group
              label="Work Type"
              label-for="work-type"
              class=""
          >
            <ValidationProvider
                name="work-type"
                v-slot="{ errors }"
                vid="work-type"
                rules=""
            >
              <v-select
                  v-model="selectWorkType"
                  :options="workTypeValueOption"
                  :reduce="(item) => item.value"
                  label="name"
                  placeholder="Select Work Type"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />

            </ValidationProvider>
          </b-form-group>

          <!-- select division-->
          <b-form-group
              label="Division"
              label-for="division-id"
              :class="!selectWorkType ? 'required-label' : ''"
          >
            <ValidationProvider
              name="division"
              v-slot="{ errors }"
              vid="division_id"
              :rules="selectWorkType ? '' : 'required'"
            >
              <v-select
                id="division-id"
                placeholder="Select Division"
                v-model="selectDivisionId"
                :options="divisionsOption"
                :reduce="(option) => option.id"
                label="name"
                @input="onChangeDivision"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <small class="text-primary">{{ policyMapDivisionTooltip }}</small>

            </ValidationProvider>
          </b-form-group>

          <!-- select department-->
          <b-form-group label="Department" label-for="department-id">
            <ValidationProvider
              name="department"
              v-slot="{ errors }"
              vid="department_id"
            >
              <v-select
                id="department-id"
                placeholder="Select Department"
                v-model="selectDepartmentsIds"
                :options="departmentsOption"
                :reduce="(option) => option.id"
                label="name"
                :disabled="departmentsOption.length > 0 ? false : true"
                multiple
                @input="onChangeDepartment"
              />
              <small class="text-danger">{{ errors[0] }}</small>

              <small class="text-primary">{{ policyMapDepartmentTooltip }}</small>

            </ValidationProvider>
          </b-form-group>

          <!-- select users-->
          <b-form-group label="Employee" label-for="user-id">
            <ValidationProvider
              name="Employees"
              v-slot="{ errors }"
              vid="user_id"
            >
              <v-select
                id="user-id"
                placeholder="Select Employee"
                v-model="selectUsersIds"
                :options="usersOption"
                :reduce="(option) => option.id"
                label="name"
                :disabled="usersOption.length > 0 ? false : true"
                multiple
                clearable
                @input="onChangeUsersTooltip"
              >
                <template #option="data">
                  <UserSelect :user="data" />
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>

              <small class="text-primary">{{ policyMapUserTooltip }}</small>

            </ValidationProvider>
          </b-form-group>

          <b-form-group
              label="IP Authorize"
              label-for="ip-authorize"
              class=""
          >
            <ValidationProvider
                name="ip-authorize"
                v-slot="{ errors }"
                vid="ip_authorize"
                rules=""
            >
              <v-select
                  v-model="ip_authorize"
                  :options="ip_authorized_options"
                  :reduce="(item) => item.value"
                  label="name"
                  placeholder="Select Ip Authorize"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <b-form-group
              label="IP"
              label-for="ip"
              :class="ip_authorize ? 'required-label' : ''"
          >
            <ValidationProvider
                name="ip"
                v-slot="{ errors }"
                vid="ip_addresses"
                :rules="ip_authorize ? 'required' : ''"
            >
              <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  id="ip"
                  class="custom-font"
                  placeholder="Type Here"
                  v-model="ip_address"
                  label="ip"
                  multiple
                  taggable
                  push-tags
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
        id="modal-remote-attendance-edit-form"
        centered
        :title="'Update'"
        hide-footer
        @hidden="hiddenEditModal"
        no-close-on-backdrop
    >
      <validation-observer ref="remoteAttendanceEditValidation">
        <b-form v-on:submit.prevent="validationEditForm">

          <b-form-group
              label="IP Authorize"
              label-for="ip-authorize"
              class=""
          >
            <ValidationProvider
                name="ip-authorize"
                v-slot="{ errors }"
                vid="ip_authorize"
                rules=""
            >
              <v-select
                  v-model="ip_authorize"
                  :options="ip_authorized_options"
                  :reduce="(item) => item.value"
                  label="name"
                  placeholder="Select Ip Authorize"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
              <small class="text-danger">{{ errors[0] }}</small>

            </ValidationProvider>
          </b-form-group>

          <b-form-group
              label="IP"
              label-for="ip_addresses"
              :class="ip_authorize ? 'required-label' : ''"
          >
            <ValidationProvider
                name="ip_addresses"
                v-slot="{ errors }"
                vid="ip_addresses"
                :rules="ip_authorize ? 'required' : ''"
            >
              <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  id="ip_addresses"
                  class="custom-font"
                  placeholder="Type Here"
                  v-model="ip_address"
                  label="ip_addresses"
                  multiple
                  taggable
                  push-tags
              >
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isFormEditSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>
          <!-- submit button -->
          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormTimepicker,
  BFormDatepicker, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max, integer } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  REMOTE_ATTENDANCE_CREATE, REMOTE_ATTENDANCE_EDIT, REMOTE_ATTENDANCE_DELETE,
} from "@/helpers/permissionsConstant";
import { workTypeConstants as workTypeValueOption } from '@/helpers/constant/workTypeConstant'


import { _lodash } from "@/helpers/lodash";
import UserSelect from '@/layouts/components/UserSelect.vue'
import UserCard from "@/layouts/components/UserCard.vue";

export default {
  name: "RemoteAttendancePolicyMayView",
  components: {
    UserCard,
    UserSelect,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormTimepicker,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      REMOTE_ATTENDANCE_CREATE,
      REMOTE_ATTENDANCE_EDIT,
      REMOTE_ATTENDANCE_DELETE,
      workTypeValueOption,
      modelType: "",

      remoteAttendanceId: "",
      selectedUser: [],
      
      selectDivisionId: "",
      divisionsOption: [],

      ip_authorize: 1,
      ip_address: [],

      policyMapDivisionTooltip: "",
      policyMapDepartmentTooltip: "",
      policyMapUserTooltip: "",

      ip_authorized_options: [
        { name: 'Yes', value: 1 },
        { name: 'No', value: 0 }
      ],

      selectDepartmentsIds: [],
      departmentsOption: [],

      selectUsersIds: [],
      usersOption: [],

      selectWorkType: "",

      pageLength: 10,
      columns: [
        {
          label: "User",
          field: "format_user",
          sortable: false,
        },
        {
          label: "IP Authorize",
          field: "format_ip_authorize",
          sortable: false,
        },
        {
          label: "IP Address",
          field: "format_ip",
          sortable: false,
        },

        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      isLoading: false,
      isFormEditSubmitLoading: false,
      isFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const divisions = await this.getDivisions()

      //  divisions
      this.divisionsOption = (divisions?.data?.data || []).map((item) => {
        return {
          name: item.name,
          id: item.id,
        };
      })

    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    workTypeConstants() {
      return workTypeConstants
    },
    async onChangeDivision(id) {

      this.divisionTooltip(id);

      this.selectDepartmentsIds = [];
      this.departmentsOption = [];
      this.selectUsersIds = [];
      this.usersOption = [];

      try {
        const res = await this.getDivisionDepartmentsById(id);

        //  departmentsOption
        this.departmentsOption = (res?.data?.data?.departments?.data || []).map(
          (item) => {
            return {
              name: item.name,
              id: item.id,
            };
          }
        );
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async onChangeDepartment(department_ids) {

      this.departmentTooltip(department_ids);

      this.selectUsersIds = [];
      this.usersOption = [];

      if(Object.keys(department_ids).length > 0)
      {
        try {
          const res = await this.getDivisionDepartmentsUsersById(
              this.selectDivisionId,
              department_ids
          );

          //  usersOption
          let userArray = [];

          (res?.data?.data?.departments || []).map((item) => {
            let departmentName = item?.name;
            (item?.users || []).map((item) => {
              userArray.push({
                avatar: item?.avatar,
                name: item?.name,
                email: item?.email,
                mobile: item?.mobile,
                employee_number: item?.employee_number,
                id: item?.id,
                departmentId: item?.department_id,
              });
            });
          });

          this.usersOption = userArray;
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: error?.response?.data?.message,
            },
          });
        }
      }
    },

    divisionTooltip(id){
        if (id) {
          this.setPolicyMapDivisionTooltip();
        } else {
          this.resetPolicyMapDivisionTooltip();
        }
    },

    departmentTooltip(department_ids){
      if(department_ids.length > 0){
        this.resetPolicyMapDivisionTooltip();
        this.setPolicyMapDepartmentTooltip();
      }else{
        this.setPolicyMapDivisionTooltip();
        this.resetPolicyMapDepartmentTooltip();
      }
    },

    onChangeUsersTooltip(selectUsersIds){
      if(selectUsersIds.length > 0){
        this.resetPolicyMapDepartmentTooltip();
        this.setPolicyMapUserTooltip();
      }else{
        this.setPolicyMapDepartmentTooltip();
        this.resetPolicyMapUserTooltip();
      }
    },


    setPolicyMapDivisionTooltip(){
      this.policyMapDivisionTooltip = "All Employees of The Above Division are Selected";
    },
    resetPolicyMapDivisionTooltip(){
      this.policyMapDivisionTooltip = "";
    },

    setPolicyMapDepartmentTooltip(){
        this.policyMapDepartmentTooltip = "All Employees of The Above Departments/Department are Selected";
    },
    resetPolicyMapDepartmentTooltip(){
      this.policyMapDepartmentTooltip = "";
    },

    setPolicyMapUserTooltip(){
      this.policyMapUserTooltip = "Only Selected Employees Will be Selected";
    },

    resetPolicyMapUserTooltip(){
      this.policyMapUserTooltip = "";
    },

    onContextStartDate(ctx) {
      this.effectiveDate = ctx.selectedYMD;
    },
    showModal() {
      this.$bvModal.show("modal-remote-attendance-form");
    },
    showEditModal() {
      this.$bvModal.show("modal-remote-attendance-edit-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-remote-attendance-form");
      this.resetModal();
    },

    hiddenEditModal() {
      this.$bvModal.hide("modal-remote-attendance-edit-form");
      this.resetEditModal();
    },
    resetModal() {
      this.modelType = "";
      this.selectDivisionId = "";
      this.selectDepartmentsIds = [];
      this.departmentsOption = [];
      this.selectUsersIds = [];
      this.usersOption = [];

      this.resetPolicyMapDivisionTooltip();
      this.resetPolicyMapDepartmentTooltip();
      this.resetPolicyMapUserTooltip();

    },
    resetEditModal() {
      this.remoteAttendanceId = ""
      this.ip_address = []
      this.ip_authorize = ""
      this.selectUsersIds = ""

      this.resetPolicyMapDivisionTooltip();
      this.resetPolicyMapDepartmentTooltip();
      this.resetPolicyMapUserTooltip();
    },
    async onShow(value) {
      this.remoteAttendanceId = value.id
      this.ip_address = value.ip_addresses || []
      this.ip_authorize = value.ip_authorize
      this.selectUsersIds = (value?.users || []).map((item) => item?.id);

      this.showEditModal()
    },

    async onDelete(row) {
      const { id } = row
      this.$swal({
        title: "Warning!",
        text: `Are You Sure, You Want To Remove ${ row?.user?.data?.name }?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/remote-attendances/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async getRemoteAttendances(params) {
      return await this.$api.get(
        "api/remote-attendances?include=user",
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
          },
        }
      );
    },
    async getDivisions() {
      return await this.$api.get("api/divisions/all");
    },
    async getDivisionDepartmentsById(id) {
      return await this.$api.get(
        `api/divisions/${id}/departments?include=departments`
      );
    },
    async getDivisionDepartmentsUsersById(id, department_ids) {
      return await this.$api.get(`api/divisions/${id}/departments/users`, {
        params: {
          department_ids: department_ids,
        },
      });
    },

    async loadItems() {
      try {
        const [remoteAttendance] = await Promise.all([
          this.getRemoteAttendances({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        const data = remoteAttendance?.data?.data;
        const meta = remoteAttendance?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.remoteAttendanceValidation
        .validate()
        .then(async (success) => {
          if (success) {
            try {
              this.isFormSubmitLoading = true;
              await this.$api.post(
                  "/api/remote-attendances",
                  {
                    division_id: this.selectDivisionId,
                    departments: this.selectDepartmentsIds,
                    users: this.selectUsersIds,
                    ip_addresses: this.ip_address,
                    work_type: this.selectWorkType,
                    ip_authorize: this.ip_authorize,
                  }
              );
              this.isFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Created",
                },
              });
            } catch (error) {
              this.isFormSubmitLoading = false;
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              if (error?.response?.data?.errors) {
                this.$refs.remoteAttendanceValidation.setErrors(
                  error?.response?.data?.errors
                );
              }
            }
          }
        });
    },
    validationEditForm: async function () {
      this.$refs.remoteAttendanceEditValidation
        .validate()
        .then(async (success) => {
          if (success) {
            try {
              this.isFormEditSubmitLoading = true;
              await this.$api.put(
                  "/api/remote-attendances/" + this.remoteAttendanceId,
                  {
                    ip_addresses: this.ip_address,
                    ip_authorize: this.ip_authorize,
                  }
              );
              this.isFormEditSubmitLoading = false;
              this.hiddenEditModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Updated",
                },
              });
            } catch (error) {
              this.isFormEditSubmitLoading = false;
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              console.log(error?.response?.data?.errors)

              if (error?.response?.data?.errors) {
                this.$refs.remoteAttendanceEditValidation.setErrors(
                  error?.response?.data?.errors
                );
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.actionBtn span{
  padding: 8px;
  margin: 1px;
  cursor: pointer;
}
</style>
