var render = function () {
  var _vm$userData, _vm$userData2, _vm$userData3, _vm$userData4, _vm$userData5, _vm$userData6, _vm$userData7, _vm$userData8, _vm$userData9, _vm$userData10, _vm$userData11, _vm$userData12, _vm$userData13, _vm$userData14, _vm$userData15, _vm$userData16, _vm$userData16$depart, _vm$userData16$depart2, _vm$userData16$depart3, _vm$userData16$depart4, _vm$userData17, _vm$userData17$depart, _vm$userData17$depart2, _vm$userData18, _vm$userData18$design, _vm$userData18$design2, _vm$userData19, _vm$userData19$roles;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-between flex-column",
    attrs: {
      "md": "12",
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start"
  }, [_c('b-avatar', {
    attrs: {
      "src": (_vm$userData = _vm.userData) === null || _vm$userData === void 0 ? void 0 : _vm$userData.avatar,
      "size": "104px",
      "rounded": ""
    }
  }), _c('div', {
    staticClass: "d-flex flex-column ml-1"
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_c('h4', {
    staticClass: "mb-1"
  }, [_vm._v(" " + _vm._s((_vm$userData2 = _vm.userData) === null || _vm$userData2 === void 0 ? void 0 : _vm$userData2.name) + " ")]), _c('span', {
    staticClass: "card-text"
  }, [_vm._v(_vm._s((_vm$userData3 = _vm.userData) === null || _vm$userData3 === void 0 ? void 0 : _vm$userData3.employee_number))]), _c('br'), _c('span', {
    staticClass: "card-text"
  }, [_vm._v(_vm._s((_vm$userData4 = _vm.userData) === null || _vm$userData4 === void 0 ? void 0 : _vm$userData4.email))])]), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_vm.$permissionAbility(_vm.USERS_PROFILE_DETAILS_ACCESS, _vm.permissions) ? [_c('b-button', {
    attrs: {
      "to": {
        name: 'admin-user-edit-details',
        params: {
          id: this.$route.params.id
        }
      },
      "variant": "primary"
    }
  }, [_vm._v(" Show Profile Details ")])] : _vm._e()], 2)])], 1)]), _c('b-col', {
    staticClass: "mt-5",
    attrs: {
      "md": "12",
      "lg": "12"
    }
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "HashIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Employee ID")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s((_vm$userData5 = _vm.userData) === null || _vm$userData5 === void 0 ? void 0 : _vm$userData5.employee_number) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "HashIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Machine Employee ID")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s((_vm$userData6 = _vm.userData) === null || _vm$userData6 === void 0 ? void 0 : _vm$userData6.machine_user_id) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "BoxIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Work Type")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s((_vm$userData7 = _vm.userData) === null || _vm$userData7 === void 0 ? void 0 : _vm$userData7.work_type) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "UserIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Username")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s((_vm$userData8 = _vm.userData) === null || _vm$userData8 === void 0 ? void 0 : _vm$userData8.name) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "MailIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Email")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s((_vm$userData9 = _vm.userData) === null || _vm$userData9 === void 0 ? void 0 : _vm$userData9.email) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "PhoneIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Contact Number")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s((_vm$userData10 = _vm.userData) === null || _vm$userData10 === void 0 ? void 0 : _vm$userData10.mobile) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CheckIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Status")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [(_vm$userData11 = _vm.userData) !== null && _vm$userData11 !== void 0 && _vm$userData11.status ? [_c('b-badge', {
    staticClass: "badge-glow",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Active ")])] : !((_vm$userData12 = _vm.userData) !== null && _vm$userData12 !== void 0 && _vm$userData12.status) ? [_c('b-badge', {
    staticClass: "badge-glow",
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(" Inactive ")])] : _vm._e()], 2)]), (_vm$userData13 = _vm.userData) !== null && _vm$userData13 !== void 0 && _vm$userData13.terminated ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CheckIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Termination Status")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [[_c('b-badge', {
    staticClass: "badge-glow",
    attrs: {
      "variant": ((_vm$userData14 = _vm.userData) === null || _vm$userData14 === void 0 ? void 0 : _vm$userData14.termination_type) === 'terminated' ? 'danger' : 'warning'
    }
  }, [_vm._v(" " + _vm._s(((_vm$userData15 = _vm.userData) === null || _vm$userData15 === void 0 ? void 0 : _vm$userData15.termination_type) === 'terminated' ? "Terminated" : "Contract Ended") + " ")])]], 2)]) : _vm._e(), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "BoxIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Division")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s((_vm$userData16 = _vm.userData) === null || _vm$userData16 === void 0 ? void 0 : (_vm$userData16$depart = _vm$userData16.department) === null || _vm$userData16$depart === void 0 ? void 0 : (_vm$userData16$depart2 = _vm$userData16$depart.data) === null || _vm$userData16$depart2 === void 0 ? void 0 : (_vm$userData16$depart3 = _vm$userData16$depart2.division) === null || _vm$userData16$depart3 === void 0 ? void 0 : (_vm$userData16$depart4 = _vm$userData16$depart3.data) === null || _vm$userData16$depart4 === void 0 ? void 0 : _vm$userData16$depart4.name) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "PackageIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Department")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s((_vm$userData17 = _vm.userData) === null || _vm$userData17 === void 0 ? void 0 : (_vm$userData17$depart = _vm$userData17.department) === null || _vm$userData17$depart === void 0 ? void 0 : (_vm$userData17$depart2 = _vm$userData17$depart.data) === null || _vm$userData17$depart2 === void 0 ? void 0 : _vm$userData17$depart2.name) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "AwardIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Designation")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s((_vm$userData18 = _vm.userData) === null || _vm$userData18 === void 0 ? void 0 : (_vm$userData18$design = _vm$userData18.designation) === null || _vm$userData18$design === void 0 ? void 0 : (_vm$userData18$design2 = _vm$userData18$design.data) === null || _vm$userData18$design2 === void 0 ? void 0 : _vm$userData18$design2.name) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "UserCheckIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Role")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s((_vm$userData19 = _vm.userData) === null || _vm$userData19 === void 0 ? void 0 : (_vm$userData19$roles = _vm$userData19.roles) === null || _vm$userData19$roles === void 0 ? void 0 : _vm$userData19$roles.data.map(function (role) {
    return role.name;
  }).join(', ')) + " ")])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }