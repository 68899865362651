var render = function () {
  var _vm$user, _vm$user2, _vm$user3, _vm$user4, _vm$user5, _vm$user6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-avatar', {
    staticClass: "mr-1",
    attrs: {
      "src": ((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.avatar) === '' || !((_vm$user2 = _vm.user) !== null && _vm$user2 !== void 0 && _vm$user2.avatar) ? '/avatar.svg' : (_vm$user3 = _vm.user) === null || _vm$user3 === void 0 ? void 0 : _vm$user3.avatar
    }
  }), _c('div', [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s((_vm$user4 = _vm.user) === null || _vm$user4 === void 0 ? void 0 : _vm$user4.name) + " "), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(_vm._s((_vm$user5 = _vm.user) === null || _vm$user5 === void 0 ? void 0 : _vm$user5.employee_number))]), _vm.user.unassigned ? _c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Not Assigned")]) : _vm._e(), _vm.user.inactive ? _c('b-badge', {
    staticClass: "ml-1",
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("Inactive")]) : _vm._e()], 1), _c('div', {
    staticClass: "font-small-2"
  }, [_vm._v(" " + _vm._s((_vm$user6 = _vm.user) === null || _vm$user6 === void 0 ? void 0 : _vm$user6.email) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }