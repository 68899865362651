var render = function () {
  var _vm$invoiceInfo, _vm$invoiceInfo2, _vm$milestoneInfo, _vm$milestoneInfo$pro, _vm$milestoneInfo$pro2, _vm$milestoneInfo$pro3, _vm$milestoneInfo$pro4, _vm$milestoneInfo2, _vm$milestoneInfo2$pr, _vm$milestoneInfo2$pr2, _vm$milestoneInfo2$pr3, _vm$milestoneInfo2$pr4, _vm$milestoneInfo3, _vm$milestoneInfo3$pr, _vm$milestoneInfo3$pr2, _vm$milestoneInfo3$pr3, _vm$milestoneInfo3$pr4, _vm$milestoneInfo4, _vm$milestoneInfo4$pr, _vm$milestoneInfo4$pr2, _vm$milestoneInfo4$pr3, _vm$milestoneInfo4$pr4, _vm$milestoneInfo5, _vm$milestoneInfo5$pr, _vm$milestoneInfo5$pr2, _vm$milestoneInfo5$pr3, _vm$milestoneInfo5$pr4, _vm$accountInfo, _vm$accountInfo2, _vm$accountInfo3, _vm$accountInfo4, _vm$accountInfo5, _vm$accountInfo6, _vm$accountInfo7, _vm$accountInfo7$coun, _vm$accountInfo7$coun2, _vm$accountInfo8, _vm$accountInfo9, _vm$accountInfo10, _vm$accountInfo11, _vm$milestoneInfo6, _vm$milestoneInfo7, _vm$milestoneInfo8, _vm$milestoneInfo9, _vm$milestoneInfo10, _vm$invoiceInfo3, _vm$invoiceInfo4, _vm$invoiceInfo5, _vm$invoiceInfo6, _vm$milestoneInfo11, _vm$milestoneInfo11$p, _vm$milestoneInfo11$p2, _vm$milestoneInfo11$p3, _vm$milestoneInfo11$p4, _vm$milestoneInfo12, _vm$milestoneInfo12$p, _vm$milestoneInfo12$p2, _vm$milestoneInfo12$p3, _vm$milestoneInfo12$p4, _vm$milestoneInfo13, _vm$milestoneInfo13$p, _vm$milestoneInfo13$p2, _vm$milestoneInfo13$p3, _vm$milestoneInfo13$p4, _vm$milestoneInfo14, _vm$milestoneInfo14$p, _vm$milestoneInfo14$p2, _vm$milestoneInfo14$p3, _vm$milestoneInfo14$p4, _vm$milestoneInfo15, _vm$milestoneInfo15$p, _vm$milestoneInfo15$p2, _vm$milestoneInfo15$p3, _vm$milestoneInfo15$p4, _vm$milestoneInfo16, _vm$accountInfo12, _vm$accountInfo13, _vm$accountInfo14, _vm$accountInfo15, _vm$accountInfo16, _vm$accountInfo16$cou, _vm$accountInfo16$cou2, _vm$accountInfo17, _vm$accountInfo18, _vm$accountInfo19, _vm$accountInfo20;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "invoice-add-wrapper"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.invoiceData === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error fetching invoice data")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" No invoice found with this invoice id. Check "), _c('b-link', {
    staticClass: "alert-link",
    attrs: {
      "to": {
        name: 'apps-invoice-list'
      }
    }
  }, [_vm._v(" Invoice List ")]), _vm._v(" for other invoices. ")], 1)]), _vm.invoiceData ? _c('b-row', {
    staticClass: "invoice-add"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "9",
      "md": "8"
    }
  }, [_c('validation-observer', {
    ref: "milestoneInvoiceValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.milestoneInvoiceValidationForm($event);
      }
    }
  }, [_c('b-card', {
    staticClass: "invoice-preview-card",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', [_c('div', {
    staticClass: "logo-wrapper"
  }, [_vm.companyLogo ? [_c('img', {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      "src": _vm.companyLogo,
      "alt": "Company Logo",
      "height": "60"
    }
  })] : [_c('h1', [_vm._v("Logo")])]], 2), _c('div', {
    domProps: {
      "innerHTML": _vm._s(this.officeAddress)
    }
  }), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v(_vm._s(this.officeEmail))]), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v(_vm._s(this.officeMobile))])]), _c('div', {
    staticClass: "invoice-number-date mt-md-0 mt-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', [((_vm$invoiceInfo = _vm.invoiceInfo) === null || _vm$invoiceInfo === void 0 ? void 0 : _vm$invoiceInfo.status) == 'paid' ? _c('b-badge', {
    attrs: {
      "variant": "light-success"
    }
  }, [_vm._v("Paid")]) : ((_vm$invoiceInfo2 = _vm.invoiceInfo) === null || _vm$invoiceInfo2 === void 0 ? void 0 : _vm$invoiceInfo2.status) == 'due' ? _c('b-badge', {
    attrs: {
      "variant": "light-danger",
      "f": ""
    }
  }, [_vm._v("Due")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-md-end mb-1"
  }, [_c('h4', {
    staticClass: "invoice-title"
  }, [_vm._v("Invoice")]), _c('b-input-group', {
    staticClass: "input-group-merge invoice-edit-input-group disabled"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "HashIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "invoice-data-id",
      "disabled": ""
    },
    model: {
      value: _vm.invoiceId,
      callback: function callback($$v) {
        _vm.invoiceId = $$v;
      },
      expression: "invoiceId"
    }
  })], 1)], 1), _c('div', {
    staticClass: "d-flex align-items-center mb-1"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Issue Date: ")]), _c('b-form-group', [_c('ValidationProvider', {
    attrs: {
      "name": "issue date",
      "vid": "issue_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control invoice-edit-input",
          model: {
            value: _vm.issueDate,
            callback: function callback($$v) {
              _vm.issueDate = $$v;
            },
            expression: "issueDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2040482050)
  })], 1)], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v(" Due Date: ")]), _c('b-form-group', [_c('ValidationProvider', {
    attrs: {
      "name": "due date",
      "vid": "due_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control invoice-edit-input",
          model: {
            value: _vm.dueDate,
            callback: function callback($$v) {
              _vm.dueDate = $$v;
            },
            expression: "dueDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1644349647)
  })], 1)], 1)])])]), _c('hr', {
    staticClass: "invoice-spacing"
  }), _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('b-row', {
    staticClass: "invoice-spacing"
  }, [_c('b-col', {
    staticClass: "mb-lg-1",
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('h5', {
    staticClass: "mb-30"
  }, [_vm._v("Invoice To:")]), _c('h6', {
    staticClass: "mb-25"
  }, [_vm._v(" " + _vm._s((_vm$milestoneInfo = _vm.milestoneInfo) === null || _vm$milestoneInfo === void 0 ? void 0 : (_vm$milestoneInfo$pro = _vm$milestoneInfo.project) === null || _vm$milestoneInfo$pro === void 0 ? void 0 : (_vm$milestoneInfo$pro2 = _vm$milestoneInfo$pro.data) === null || _vm$milestoneInfo$pro2 === void 0 ? void 0 : (_vm$milestoneInfo$pro3 = _vm$milestoneInfo$pro2.lead) === null || _vm$milestoneInfo$pro3 === void 0 ? void 0 : (_vm$milestoneInfo$pro4 = _vm$milestoneInfo$pro3.data) === null || _vm$milestoneInfo$pro4 === void 0 ? void 0 : _vm$milestoneInfo$pro4.key_contact_person) + " ")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" " + _vm._s((_vm$milestoneInfo2 = _vm.milestoneInfo) === null || _vm$milestoneInfo2 === void 0 ? void 0 : (_vm$milestoneInfo2$pr = _vm$milestoneInfo2.project) === null || _vm$milestoneInfo2$pr === void 0 ? void 0 : (_vm$milestoneInfo2$pr2 = _vm$milestoneInfo2$pr.data) === null || _vm$milestoneInfo2$pr2 === void 0 ? void 0 : (_vm$milestoneInfo2$pr3 = _vm$milestoneInfo2$pr2.lead) === null || _vm$milestoneInfo2$pr3 === void 0 ? void 0 : (_vm$milestoneInfo2$pr4 = _vm$milestoneInfo2$pr3.data) === null || _vm$milestoneInfo2$pr4 === void 0 ? void 0 : _vm$milestoneInfo2$pr4.company_name) + " ")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Address: " + _vm._s((_vm$milestoneInfo3 = _vm.milestoneInfo) === null || _vm$milestoneInfo3 === void 0 ? void 0 : (_vm$milestoneInfo3$pr = _vm$milestoneInfo3.project) === null || _vm$milestoneInfo3$pr === void 0 ? void 0 : (_vm$milestoneInfo3$pr2 = _vm$milestoneInfo3$pr.data) === null || _vm$milestoneInfo3$pr2 === void 0 ? void 0 : (_vm$milestoneInfo3$pr3 = _vm$milestoneInfo3$pr2.lead) === null || _vm$milestoneInfo3$pr3 === void 0 ? void 0 : (_vm$milestoneInfo3$pr4 = _vm$milestoneInfo3$pr3.data) === null || _vm$milestoneInfo3$pr4 === void 0 ? void 0 : _vm$milestoneInfo3$pr4.address) + " ")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Email: " + _vm._s((_vm$milestoneInfo4 = _vm.milestoneInfo) === null || _vm$milestoneInfo4 === void 0 ? void 0 : (_vm$milestoneInfo4$pr = _vm$milestoneInfo4.project) === null || _vm$milestoneInfo4$pr === void 0 ? void 0 : (_vm$milestoneInfo4$pr2 = _vm$milestoneInfo4$pr.data) === null || _vm$milestoneInfo4$pr2 === void 0 ? void 0 : (_vm$milestoneInfo4$pr3 = _vm$milestoneInfo4$pr2.lead) === null || _vm$milestoneInfo4$pr3 === void 0 ? void 0 : (_vm$milestoneInfo4$pr4 = _vm$milestoneInfo4$pr3.data) === null || _vm$milestoneInfo4$pr4 === void 0 ? void 0 : _vm$milestoneInfo4$pr4.email) + " ")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Mobile: " + _vm._s((_vm$milestoneInfo5 = _vm.milestoneInfo) === null || _vm$milestoneInfo5 === void 0 ? void 0 : (_vm$milestoneInfo5$pr = _vm$milestoneInfo5.project) === null || _vm$milestoneInfo5$pr === void 0 ? void 0 : (_vm$milestoneInfo5$pr2 = _vm$milestoneInfo5$pr.data) === null || _vm$milestoneInfo5$pr2 === void 0 ? void 0 : (_vm$milestoneInfo5$pr3 = _vm$milestoneInfo5$pr2.lead) === null || _vm$milestoneInfo5$pr3 === void 0 ? void 0 : (_vm$milestoneInfo5$pr4 = _vm$milestoneInfo5$pr3.data) === null || _vm$milestoneInfo5$pr4 === void 0 ? void 0 : _vm$milestoneInfo5$pr4.mobile) + " ")])]), _c('b-col', {
    staticClass: "mt-xl-0 mt-2 justify-content-end d-xl-flex d-block",
    attrs: {
      "xl": "6",
      "cols": "12"
    }
  }, [_vm.accountInfo ? _c('div', [_c('h6', {
    staticClass: "mb-30"
  }, [_vm._v("Payment Details:")]), _c('table', [_c('tbody', [_c('div', [_c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Bank Name:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo = _vm.accountInfo) === null || _vm$accountInfo === void 0 ? void 0 : _vm$accountInfo.bank_name))])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Account Name:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo2 = _vm.accountInfo) === null || _vm$accountInfo2 === void 0 ? void 0 : _vm$accountInfo2.name))])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Account Number:")]), _c('td', [_vm._v(" " + _vm._s((_vm$accountInfo3 = _vm.accountInfo) !== null && _vm$accountInfo3 !== void 0 && _vm$accountInfo3.account_number ? (_vm$accountInfo4 = _vm.accountInfo) === null || _vm$accountInfo4 === void 0 ? void 0 : _vm$accountInfo4.account_number : "N/A") + " ")])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Routing Number:")]), _c('td', [_vm._v(" " + _vm._s((_vm$accountInfo5 = _vm.accountInfo) !== null && _vm$accountInfo5 !== void 0 && _vm$accountInfo5.routing_number ? (_vm$accountInfo6 = _vm.accountInfo) === null || _vm$accountInfo6 === void 0 ? void 0 : _vm$accountInfo6.routing_number : "N/A") + " ")])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("Country:")]), _c('td', [_vm._v(_vm._s((_vm$accountInfo7 = _vm.accountInfo) === null || _vm$accountInfo7 === void 0 ? void 0 : (_vm$accountInfo7$coun = _vm$accountInfo7.country) === null || _vm$accountInfo7$coun === void 0 ? void 0 : (_vm$accountInfo7$coun2 = _vm$accountInfo7$coun.data) === null || _vm$accountInfo7$coun2 === void 0 ? void 0 : _vm$accountInfo7$coun2.name))])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("IBAN:")]), _c('td', [_vm._v(" " + _vm._s((_vm$accountInfo8 = _vm.accountInfo) !== null && _vm$accountInfo8 !== void 0 && _vm$accountInfo8.iban_number ? (_vm$accountInfo9 = _vm.accountInfo) === null || _vm$accountInfo9 === void 0 ? void 0 : _vm$accountInfo9.iban_number : "N/A") + " ")])]), _c('tr', [_c('td', {
    staticClass: "pr-1"
  }, [_vm._v("SWIFT code:")]), _c('td', [_vm._v(" " + _vm._s((_vm$accountInfo10 = _vm.accountInfo) !== null && _vm$accountInfo10 !== void 0 && _vm$accountInfo10.swift_code ? (_vm$accountInfo11 = _vm.accountInfo) === null || _vm$accountInfo11 === void 0 ? void 0 : _vm$accountInfo11.swift_code : "N/A") + " ")])])])])])]) : _vm._e()])], 1), _c('b-row', {
    staticClass: "invoice-spacing"
  }, [_c('b-col', {
    staticClass: "mb-lg-1",
    attrs: {
      "cols": "12",
      "xl": "6"
    }
  }, [_c('h5', {
    staticClass: "mb-30"
  }, [_vm._v("Milestone Info:")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Title: " + _vm._s((_vm$milestoneInfo6 = _vm.milestoneInfo) === null || _vm$milestoneInfo6 === void 0 ? void 0 : _vm$milestoneInfo6.title) + " ")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Total Amount: " + _vm._s((_vm$milestoneInfo7 = _vm.milestoneInfo) === null || _vm$milestoneInfo7 === void 0 ? void 0 : _vm$milestoneInfo7.total_amount) + " " + _vm._s(_vm.currency) + " ")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Total Vat Amount: " + _vm._s((_vm$milestoneInfo8 = _vm.milestoneInfo) === null || _vm$milestoneInfo8 === void 0 ? void 0 : _vm$milestoneInfo8.total_vat_amount) + " " + _vm._s(_vm.currency) + " ")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Total Tax Amount: " + _vm._s((_vm$milestoneInfo9 = _vm.milestoneInfo) === null || _vm$milestoneInfo9 === void 0 ? void 0 : _vm$milestoneInfo9.total_tax_amount) + " " + _vm._s(_vm.currency) + " ")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" Grand Total Amount: " + _vm._s((_vm$milestoneInfo10 = _vm.milestoneInfo) === null || _vm$milestoneInfo10 === void 0 ? void 0 : _vm$milestoneInfo10.grand_amount) + " " + _vm._s(_vm.currency) + " ")])]), _c('b-col', {
    staticClass: "mt-xl-0 mt-2 justify-content-end d-xl-flex d-block",
    attrs: {
      "xl": "6",
      "cols": "12"
    }
  })], 1)], 1), _c('b-card-body', {
    staticClass: "invoice-padding form-item-section"
  }, [_c('div', {
    ref: "form",
    staticClass: "repeater-form",
    style: {
      height: _vm.trHeight
    }
  }, _vm._l(_vm.invoiceData.items, function (item, index) {
    return _c('b-row', {
      key: index,
      ref: "row",
      refInFor: true,
      staticClass: "pb-2"
    }, [_c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "d-none d-lg-flex"
    }, [_c('b-row', {
      staticClass: "flex-grow-1 px-1"
    }, [_c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "5"
      }
    }, [_vm._v(" Item ")]), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "3"
      }
    }, [_vm._v(" Amount ")]), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "2"
      }
    }, [_vm._v(" Qty ")]), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "2"
      }
    }, [_vm._v(" Price ")])], 1), _c('div', {
      staticClass: "form-item-action-col"
    })], 1), _c('div', {
      staticClass: "d-flex border rounded"
    }, [_c('b-row', {
      staticClass: "flex-grow-1 p-2"
    }, [_c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "5"
      }
    }, [_c('b-form-group', [_c('ValidationProvider', {
      attrs: {
        "name": "item",
        "vid": "item",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref3) {
          var errors = _ref3.errors;
          return [_c('b-form-input', {
            attrs: {
              "type": "text"
            },
            model: {
              value: item.itemTitle,
              callback: function callback($$v) {
                _vm.$set(item, "itemTitle", $$v);
              },
              expression: "item.itemTitle"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "3"
      }
    }, [_c('b-form-group', [_c('ValidationProvider', {
      attrs: {
        "name": "amount",
        "vid": "amount",
        "rules": "required|min_value:0"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref4) {
          var errors = _ref4.errors;
          return [_c('b-form-input', {
            attrs: {
              "type": "number"
            },
            model: {
              value: item.cost,
              callback: function callback($$v) {
                _vm.$set(item, "cost", $$v);
              },
              expression: "item.cost"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "2"
      }
    }, [_c('b-form-group', [_c('ValidationProvider', {
      attrs: {
        "name": "quantity",
        "vid": "quantity",
        "rules": "required|min_value:1"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref5) {
          var errors = _ref5.errors;
          return [_c('b-form-input', {
            attrs: {
              "type": "number"
            },
            model: {
              value: item.qty,
              callback: function callback($$v) {
                _vm.$set(item, "qty", $$v);
              },
              expression: "item.qty"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "2"
      }
    }, [_c('label', {
      staticClass: "d-inline d-lg-none"
    }, [_vm._v("Price")]), _c('p', {
      staticClass: "mb-1"
    }, [_vm._v(" " + _vm._s(item.cost * item.qty) + " " + _vm._s(_vm.currency) + " ")])]), _c('b-col', {
      attrs: {
        "cols": "12",
        "lg": "5"
      }
    }, [_c('b-form-group', [_c('ValidationProvider', {
      attrs: {
        "name": "description",
        "vid": "description"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref6) {
          var errors = _ref6.errors;
          return [_c('b-form-textarea', {
            staticClass: "mb-lg-0",
            model: {
              value: item.description,
              callback: function callback($$v) {
                _vm.$set(item, "description", $$v);
              },
              expression: "item.description"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1), _c('label', {
      staticClass: "d-inline d-lg-none"
    }, [_vm._v("Description")])], 1)], 1), _c('div', {
      staticClass: "d-flex flex-column justify-content-between border-left py-50 px-25"
    }, [_c('feather-icon', {
      staticClass: "cursor-pointer",
      attrs: {
        "size": "16",
        "icon": "XIcon"
      },
      on: {
        "click": function click($event) {
          _vm.invoiceData.items.length > 1 ? _vm.removeItem(index) : null;
        }
      }
    })], 1)], 1)])], 1);
  }), 1), ((_vm$invoiceInfo3 = _vm.invoiceInfo) === null || _vm$invoiceInfo3 === void 0 ? void 0 : _vm$invoiceInfo3.status) == 'due' ? [_c('div', {
    staticClass: "mt-1"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": _vm.addNewItemInItemForm
    }
  }, [_vm._v(" Add Item ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "size": "sm",
      "variant": "primary"
    }
  }, [_vm._v(" Update ")])], 1)] : _vm._e()], 2), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-md-0 mt-3 d-flex align-items-center",
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "2",
      "order-md": "1"
    }
  }), _c('b-col', {
    staticClass: "mt-md-6 d-flex justify-content-end",
    attrs: {
      "cols": "12",
      "md": "6",
      "order": "1",
      "order-md": "2"
    }
  }, [_c('div', {
    staticClass: "invoice-total-wrapper custom-width"
  }, [_c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Sub Total:")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" " + _vm._s(_vm.subTotalAmount) + " " + _vm._s(_vm.currency) + " ")])]), _c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Vat:")]), _c('b-form-group', [_c('ValidationProvider', {
    attrs: {
      "name": "vat amount",
      "vid": "vat_amount",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": _vm.currency
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.vatAmount,
            callback: function callback($$v) {
              _vm.vatAmount = $$v;
            },
            expression: "vatAmount"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2069142497)
  })], 1)], 1), _c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Tax:")]), _c('b-form-group', [_c('ValidationProvider', {
    attrs: {
      "name": "tax amount",
      "vid": "tax_amount",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": _vm.currency
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.taxAmount,
            callback: function callback($$v) {
              _vm.taxAmount = $$v;
            },
            expression: "taxAmount"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1088083375)
  })], 1)], 1), _c('hr', {
    staticClass: "my-50"
  }), _c('div', {
    staticClass: "invoice-total-item"
  }, [_c('p', {
    staticClass: "invoice-total-title"
  }, [_vm._v("Grand Total:")]), _c('p', {
    staticClass: "invoice-total-amount"
  }, [_vm._v(" " + _vm._s(_vm.grandTotalAmount) + " " + _vm._s(_vm.currency) + " ")])])])])], 1)], 1), _c('hr', {
    staticClass: "invoice-spacing"
  }), _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Note: ")]), _c('b-form-textarea', {
    model: {
      value: _vm.remarks,
      callback: function callback($$v) {
        _vm.remarks = $$v;
      },
      expression: "remarks"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "invoice-actions",
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "3"
    }
  }, [_c('b-card', [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-secondary",
      "block": ""
    },
    on: {
      "click": _vm.printInvoice
    }
  }, [_vm._v(" Print ")]), ((_vm$invoiceInfo4 = _vm.invoiceInfo) === null || _vm$invoiceInfo4 === void 0 ? void 0 : _vm$invoiceInfo4.status) == 'due' ? [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.sidebar-invoice-add-payment",
      modifiers: {
        "sidebar-invoice-add-payment": true
      }
    }, {
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "success",
      "block": ""
    }
  }, [_vm._v(" Collect Payment ")])] : _vm._e()], 2)], 1)], 1) : _vm._e(), _c('div', {
    staticStyle: {
      "width": "100%",
      "font-family": "Arial, sans-serif",
      "margin": "0",
      "padding": "0",
      "display": "none"
    },
    attrs: {
      "id": "content"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%",
      "padding": "20px",
      "box-sizing": "border-box"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "margin-bottom": "20px"
    }
  }, [_c('div', [_c('div', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm.companyLogo ? [_c('img', {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      "src": _vm.companyLogo,
      "alt": "Company Logo",
      "height": "60"
    }
  })] : [_c('h1', [_vm._v("Logo")])]], 2), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(_vm._s(this.officeAddress))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(_vm._s(this.officeEmail))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(_vm._s(this.officeMobile))])]), _c('div', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('h4', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(" Invoice "), _c('span', {
    staticStyle: {
      "font-weight": "normal"
    }
  }, [_vm._v("# " + _vm._s(_vm.invoiceId))]), ((_vm$invoiceInfo5 = _vm.invoiceInfo) === null || _vm$invoiceInfo5 === void 0 ? void 0 : _vm$invoiceInfo5.status) == 'paid' ? _c('span', {
    staticStyle: {
      "background-color": "rgba(40, 199, 111, 0.12)",
      "color": "#008000",
      "padding": "2px 5px",
      "margin-left": "5px"
    }
  }, [_vm._v("Paid")]) : ((_vm$invoiceInfo6 = _vm.invoiceInfo) === null || _vm$invoiceInfo6 === void 0 ? void 0 : _vm$invoiceInfo6.status) == 'due' ? _c('span', {
    staticStyle: {
      "background-color": "rgba(234, 84, 85, 0.12)",
      "color": "#ff0000",
      "padding": "2px 5px",
      "margin-left": "5px"
    }
  }, [_vm._v("Due")]) : _vm._e()]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Date Issued: "), _c('b', [_vm._v(_vm._s(_vm.formatDate(_vm.issueDate)))])]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Due Date: "), _c('b', [_vm._v(_vm._s(_vm.formatDate(_vm.dueDate)))])])])]), _c('hr', {
    staticStyle: {
      "margin": "20px 0"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "margin-bottom": "20px"
    }
  }, [_c('div', [_c('h6', {
    staticStyle: {
      "margin": "0 0 10px"
    }
  }, [_vm._v("Invoice To:")]), _c('h6', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(_vm._s((_vm$milestoneInfo11 = _vm.milestoneInfo) === null || _vm$milestoneInfo11 === void 0 ? void 0 : (_vm$milestoneInfo11$p = _vm$milestoneInfo11.project) === null || _vm$milestoneInfo11$p === void 0 ? void 0 : (_vm$milestoneInfo11$p2 = _vm$milestoneInfo11$p.data) === null || _vm$milestoneInfo11$p2 === void 0 ? void 0 : (_vm$milestoneInfo11$p3 = _vm$milestoneInfo11$p2.lead) === null || _vm$milestoneInfo11$p3 === void 0 ? void 0 : (_vm$milestoneInfo11$p4 = _vm$milestoneInfo11$p3.data) === null || _vm$milestoneInfo11$p4 === void 0 ? void 0 : _vm$milestoneInfo11$p4.key_contact_person))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(_vm._s((_vm$milestoneInfo12 = _vm.milestoneInfo) === null || _vm$milestoneInfo12 === void 0 ? void 0 : (_vm$milestoneInfo12$p = _vm$milestoneInfo12.project) === null || _vm$milestoneInfo12$p === void 0 ? void 0 : (_vm$milestoneInfo12$p2 = _vm$milestoneInfo12$p.data) === null || _vm$milestoneInfo12$p2 === void 0 ? void 0 : (_vm$milestoneInfo12$p3 = _vm$milestoneInfo12$p2.lead) === null || _vm$milestoneInfo12$p3 === void 0 ? void 0 : (_vm$milestoneInfo12$p4 = _vm$milestoneInfo12$p3.data) === null || _vm$milestoneInfo12$p4 === void 0 ? void 0 : _vm$milestoneInfo12$p4.company_name))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Address: " + _vm._s((_vm$milestoneInfo13 = _vm.milestoneInfo) === null || _vm$milestoneInfo13 === void 0 ? void 0 : (_vm$milestoneInfo13$p = _vm$milestoneInfo13.project) === null || _vm$milestoneInfo13$p === void 0 ? void 0 : (_vm$milestoneInfo13$p2 = _vm$milestoneInfo13$p.data) === null || _vm$milestoneInfo13$p2 === void 0 ? void 0 : (_vm$milestoneInfo13$p3 = _vm$milestoneInfo13$p2.lead) === null || _vm$milestoneInfo13$p3 === void 0 ? void 0 : (_vm$milestoneInfo13$p4 = _vm$milestoneInfo13$p3.data) === null || _vm$milestoneInfo13$p4 === void 0 ? void 0 : _vm$milestoneInfo13$p4.address))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Email: " + _vm._s((_vm$milestoneInfo14 = _vm.milestoneInfo) === null || _vm$milestoneInfo14 === void 0 ? void 0 : (_vm$milestoneInfo14$p = _vm$milestoneInfo14.project) === null || _vm$milestoneInfo14$p === void 0 ? void 0 : (_vm$milestoneInfo14$p2 = _vm$milestoneInfo14$p.data) === null || _vm$milestoneInfo14$p2 === void 0 ? void 0 : (_vm$milestoneInfo14$p3 = _vm$milestoneInfo14$p2.lead) === null || _vm$milestoneInfo14$p3 === void 0 ? void 0 : (_vm$milestoneInfo14$p4 = _vm$milestoneInfo14$p3.data) === null || _vm$milestoneInfo14$p4 === void 0 ? void 0 : _vm$milestoneInfo14$p4.email))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Mobile: " + _vm._s((_vm$milestoneInfo15 = _vm.milestoneInfo) === null || _vm$milestoneInfo15 === void 0 ? void 0 : (_vm$milestoneInfo15$p = _vm$milestoneInfo15.project) === null || _vm$milestoneInfo15$p === void 0 ? void 0 : (_vm$milestoneInfo15$p2 = _vm$milestoneInfo15$p.data) === null || _vm$milestoneInfo15$p2 === void 0 ? void 0 : (_vm$milestoneInfo15$p3 = _vm$milestoneInfo15$p2.lead) === null || _vm$milestoneInfo15$p3 === void 0 ? void 0 : (_vm$milestoneInfo15$p4 = _vm$milestoneInfo15$p3.data) === null || _vm$milestoneInfo15$p4 === void 0 ? void 0 : _vm$milestoneInfo15$p4.mobile))])]), _c('div', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('h6', {
    staticStyle: {
      "margin": "0 0 10px"
    }
  }, [_vm._v("Payment Details:")]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Total Due: "), _c('b', [_vm._v(_vm._s((_vm$milestoneInfo16 = _vm.milestoneInfo) === null || _vm$milestoneInfo16 === void 0 ? void 0 : _vm$milestoneInfo16.grand_amount))])]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Bank Name: " + _vm._s((_vm$accountInfo12 = _vm.accountInfo) === null || _vm$accountInfo12 === void 0 ? void 0 : _vm$accountInfo12.bank_name))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Account Name: " + _vm._s((_vm$accountInfo13 = _vm.accountInfo) === null || _vm$accountInfo13 === void 0 ? void 0 : _vm$accountInfo13.name))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Account Number: " + _vm._s((_vm$accountInfo14 = _vm.accountInfo) === null || _vm$accountInfo14 === void 0 ? void 0 : _vm$accountInfo14.account_number))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Routing Number: " + _vm._s((_vm$accountInfo15 = _vm.accountInfo) === null || _vm$accountInfo15 === void 0 ? void 0 : _vm$accountInfo15.routing_number))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("Country: " + _vm._s((_vm$accountInfo16 = _vm.accountInfo) === null || _vm$accountInfo16 === void 0 ? void 0 : (_vm$accountInfo16$cou = _vm$accountInfo16.country) === null || _vm$accountInfo16$cou === void 0 ? void 0 : (_vm$accountInfo16$cou2 = _vm$accountInfo16$cou.data) === null || _vm$accountInfo16$cou2 === void 0 ? void 0 : _vm$accountInfo16$cou2.name))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("IBAN: " + _vm._s((_vm$accountInfo17 = _vm.accountInfo) !== null && _vm$accountInfo17 !== void 0 && _vm$accountInfo17.iban_number ? (_vm$accountInfo18 = _vm.accountInfo) === null || _vm$accountInfo18 === void 0 ? void 0 : _vm$accountInfo18.iban_number : "N/A"))]), _c('p', {
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v("SWIFT code: " + _vm._s((_vm$accountInfo19 = _vm.accountInfo) !== null && _vm$accountInfo19 !== void 0 && _vm$accountInfo19.swift_code ? (_vm$accountInfo20 = _vm.accountInfo) === null || _vm$accountInfo20 === void 0 ? void 0 : _vm$accountInfo20.swift_code : "N/A"))])])]), _c('h6', {
    staticStyle: {
      "margin": "0 0 10px"
    }
  }, [_vm._v("Invoice Items:")]), _c('table', {
    staticStyle: {
      "width": "100%",
      "border-collapse": "collapse",
      "margin-bottom": "20px"
    }
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.invoiceData.items, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticStyle: {
        "padding": "5px",
        "border": "1px solid #ddd"
      }
    }, [_vm._v(_vm._s(item.itemTitle))]), _c('td', {
      staticStyle: {
        "padding": "5px",
        "border": "1px solid #ddd"
      }
    }, [_vm._v(_vm._s(item.cost))]), _c('td', {
      staticStyle: {
        "padding": "5px",
        "border": "1px solid #ddd"
      }
    }, [_vm._v(_vm._s(item.qty))]), _c('td', {
      staticStyle: {
        "padding": "5px",
        "border": "1px solid #ddd"
      }
    }, [_vm._v(_vm._s(item.price))]), _c('td', {
      staticStyle: {
        "padding": "5px",
        "border": "1px solid #ddd"
      }
    }, [_vm._v(_vm._s(item.description))])]);
  }), 0)]), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "space-between",
      "margin-bottom": "20px"
    }
  }, [_c('div'), _c('div', {
    staticStyle: {
      "text-align": "right"
    }
  }, [_c('table', {
    staticStyle: {
      "width": "100%",
      "border-collapse": "collapse"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "padding": "5px",
      "border": "1px solid #ddd"
    }
  }, [_vm._v("Subtotal:")]), _c('td', {
    staticStyle: {
      "padding": "5px",
      "border": "1px solid #ddd"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.subTotalAmount) + " " + _vm._s(_vm.currency))])])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding": "5px",
      "border": "1px solid #ddd"
    }
  }, [_vm._v("VAT:")]), _c('td', {
    staticStyle: {
      "padding": "5px",
      "border": "1px solid #ddd"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.vatAmount) + " " + _vm._s(_vm.currency))])])]), _c('tr', [_c('td', {
    staticStyle: {
      "padding": "5px",
      "border": "1px solid #ddd"
    }
  }, [_vm._v("TAX:")]), _c('td', {
    staticStyle: {
      "padding": "5px",
      "border": "1px solid #ddd"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.taxAmount) + " " + _vm._s(_vm.currency))])])]), _vm._m(1), _c('tr', [_c('td', {
    staticStyle: {
      "padding": "5px",
      "border": "1px solid #ddd"
    }
  }, [_vm._v("Grand Total:")]), _c('td', {
    staticStyle: {
      "padding": "5px",
      "border": "1px solid #ddd"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.grandTotalAmount) + " " + _vm._s(_vm.currency))])])])])])])]), this.remarks ? _c('div', {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c('span', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Note: ")]), _c('span', [_vm._v(_vm._s(_vm.remarks))])]) : _vm._e()])]), _c('invoice-sidebar-send-invoice'), _c('invoice-sidebar-add-payment', {
    attrs: {
      "invoiceInfo": _vm.invoiceInfo,
      "milestoneInfo": _vm.milestoneInfo
    },
    on: {
      "loadInvoiceInfo": _vm.updateInvoiceInfo
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "padding": "5px",
      "text-align": "left"
    }
  }, [_vm._v("Item Title")]), _c('th', {
    staticStyle: {
      "padding": "5px",
      "text-align": "left"
    }
  }, [_vm._v("Cost")]), _c('th', {
    staticStyle: {
      "padding": "5px",
      "text-align": "left"
    }
  }, [_vm._v("Quantity")]), _c('th', {
    staticStyle: {
      "padding": "5px",
      "text-align": "left"
    }
  }, [_vm._v("Price")]), _c('th', {
    staticStyle: {
      "padding": "5px",
      "text-align": "left"
    }
  }, [_vm._v("Description")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('hr', {
    staticStyle: {
      "margin": "10px 0"
    }
  })])]);
}]

export { render, staticRenderFns }