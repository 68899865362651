var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "lg": "8",
      "xs": "12"
    }
  }, [_c('b-card', [_vm.selectEmpId ? _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "year",
      "options": _vm.yearConstants,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Choose Year"
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterYear,
      callback: function callback($$v) {
        _vm.filterYear = $$v;
      },
      expression: "filterYear"
    }
  })], 1)], 1) : _vm._e(), _c('b-row', [_vm.$permissionAbility(_vm.LEAVE_BALANCE_VIEW_ALL, _vm.permissions) ? _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "6"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterEmpIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Employee"
    },
    on: {
      "input": _vm.loadItems
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }], null, false, 3839851139),
    model: {
      value: _vm.selectEmpId,
      callback: function callback($$v) {
        _vm.selectEmpId = $$v;
      },
      expression: "selectEmpId"
    }
  })], 1) : _vm._e(), _vm.selectEmpId && _vm.$permissionAbility(_vm.LEAVE_BALANCE_EDIT, _vm.permissions) ? _c('b-col', {
    staticClass: "d-flex justify-content-end align-items-center",
    attrs: {
      "lg": "6",
      "md": "6",
      "xs": "6"
    }
  }, [[_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": _vm.isEditable ? 'outline-primary' : 'primary'
    },
    on: {
      "click": _vm.cancelEdit
    }
  }, [_vm._v(" " + _vm._s(_vm.isEditable ? 'Close' : 'Edit') + " ")])], _vm.isEditable ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1",
    attrs: {
      "variant": "primary",
      "disabled": _vm.invalidIds.length > 0
    },
    on: {
      "click": _vm.saveBalance
    }
  }, [_vm._v(" Save ")])] : _vm._e()], 2) : _vm._e()], 1), _c('div', [_c('vue-good-table', {
    attrs: {
      "is-loading": _vm.isLoading,
      "rows": _vm.rows,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false,
        multipleColumns: true
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      },
      "style-class": "vgt-table striped"
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$row3, _props$row4, _props$row5, _props$row7, _props$row8, _props$row9, _props$row9$leave_act, _props$row10, _props$row11, _props$row12, _props$row12$leave_ac, _props$row13, _props$row14, _props$row14$leave_ac, _props$row15;
        return [props.column.field === 'type' ? _c('div', [(_props$row = props.row) !== null && _props$row !== void 0 && _props$row.name ? [_c('b', [_vm._v(_vm._s((_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.name))])] : _vm._e()], 2) : _vm._e(), props.column.field === 'leave_allowance' ? _c('div', [props.row && !_vm.isEditable ? [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s((_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.leave_allowance) + " ")])] : _vm.isEditable ? [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "8"
          }
        }, [_c('b-form-input', {
          staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
          class: {
            'is-invalid': _vm.invalidIds.includes((_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.id)
          },
          attrs: {
            "value": (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : _props$row5.leave_allowance,
            "placeholder": "Input Number",
            "type": "text"
          },
          on: {
            "input": function input($event) {
              var _props$row6;
              return _vm.setBalance((_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.id, $event);
            }
          }
        }), _vm.invalidIds.includes((_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : _props$row7.id) ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" Invalid Balance ")]) : _vm._e()], 1)], 1)] : _vm._e()], 2) : _vm._e(), props.column.field === 'owned' ? _c('div', [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-danger"
          }
        }, [(_props$row8 = props.row) !== null && _props$row8 !== void 0 && _props$row8.leave_activity ? [_vm._v(" " + _vm._s((_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : (_props$row9$leave_act = _props$row9.leave_activity) === null || _props$row9$leave_act === void 0 ? void 0 : _props$row9$leave_act.leave_owned) + " ")] : [_vm._v(" 0 ")]], 2)], 1) : _vm._e(), props.column.field === 'format_remaining' ? _c('div', [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-success"
          }
        }, [(_props$row10 = props.row) !== null && _props$row10 !== void 0 && _props$row10.leave_activity ? [_vm._v(" " + _vm._s(((_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : _props$row11.leave_allowance) - ((_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : (_props$row12$leave_ac = _props$row12.leave_activity) === null || _props$row12$leave_ac === void 0 ? void 0 : _props$row12$leave_ac.leave_owned) <= 0 ? 0 : ((_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.leave_allowance) - ((_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : (_props$row14$leave_ac = _props$row14.leave_activity) === null || _props$row14$leave_ac === void 0 ? void 0 : _props$row14$leave_ac.leave_owned)) + " ")] : [_vm._v(" " + _vm._s((_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : _props$row15.leave_allowance) + " ")]], 2)], 1) : _vm._e()];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)], 1)], 1), _vm.$permissionAbility(_vm.LEAVE_BALANCE_EDIT, _vm.permissions) ? _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-card-code', {
    attrs: {
      "title": "Activity Log"
    }
  }, [_vm.isLoading ? [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('b-spinner', {
    attrs: {
      "type": "border",
      "label": "Loading..."
    }
  })], 1)] : [_c('div', {
    staticClass: "log-scrollable"
  }, [_c('b-card', [_vm.activityLog.length > 0 ? _c('app-timeline', [_vm._l(_vm.activityLog, function (item, index) {
    var _item$causer, _item$properties, _item$properties$attr, _item$subject, _item$properties2, _item$properties2$old, _item$properties3, _item$properties3$att;
    return [_c('app-timeline-item', {
      key: item === null || item === void 0 ? void 0 : item.id,
      attrs: {
        "icon": "InfoIcon",
        "variant": "primary",
        "title": "".concat(item === null || item === void 0 ? void 0 : (_item$causer = item.causer) === null || _item$causer === void 0 ? void 0 : _item$causer.name, " updated the '").concat(item === null || item === void 0 ? void 0 : (_item$properties = item.properties) === null || _item$properties === void 0 ? void 0 : (_item$properties$attr = _item$properties.attributes) === null || _item$properties$attr === void 0 ? void 0 : _item$properties$attr.leave_type, "' balance for ").concat(item === null || item === void 0 ? void 0 : (_item$subject = item.subject) === null || _item$subject === void 0 ? void 0 : _item$subject.name, " from ").concat(item === null || item === void 0 ? void 0 : (_item$properties2 = item.properties) === null || _item$properties2 === void 0 ? void 0 : (_item$properties2$old = _item$properties2.old) === null || _item$properties2$old === void 0 ? void 0 : _item$properties2$old.allowance, " days to ").concat(item === null || item === void 0 ? void 0 : (_item$properties3 = item.properties) === null || _item$properties3 === void 0 ? void 0 : (_item$properties3$att = _item$properties3.attributes) === null || _item$properties3$att === void 0 ? void 0 : _item$properties3$att.allowance, " days"),
        "time": _vm.formatDate(item === null || item === void 0 ? void 0 : item.created_at)
      }
    })];
  })], 2) : [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v("No Activity Log Found")])]], 2)], 1)]], 2)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }