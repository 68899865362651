<template>
  <div class="col-12">
    <b-card>
      <b-row>
        <b-col cols="2">
          <v-select
              id="year"
              v-model="filterYear"
              :options="yearConstants"
              :reduce="(item) => item.value"
              label="name"
              placeholder="Choose Year"
              v-on:input="onChangeFilterYear"
          />
        </b-col>
        <b-col cols="10">
          <!-- search input -->
          <div
            class="custom-search d-flex align-items-center justify-content-end"
          >
            <div
              class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
            >
              <b-form-input
                placeholder="Search..."
                type="text"
                class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
              />
              <template
                v-if="$permissionAbility(LEAVE_TYPES_CREATE, permissions)"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="flex-shrink-0"
                  variant="primary"
                  @click="showModal"
                >
                  Create Leave Type
                </b-button>
              </template>
            </div>
          </div>
        </b-col>
      </b-row>
      <div>
        <!-- table -->
        <vue-good-table
          :line-numbers="false"
          :rows="rows"
          :columns="columns"
          :sort-options="{
            enabled: false,
            multipleColumns: true,
            initialSortBy: [{ field: 'format_name', type: 'asc' }],
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          style-class="vgt-table striped"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: eligible_employee_types -->
            <div v-if="props.column.field === 'eligible_employee_types'">
              <template v-if="props.row?.employeeType">
                <b-badge class="mr-1">
                  {{ props.row?.employeeType?.data?.name }}
                </b-badge>
              </template>
            </div>

            <div v-if="props.column.field === 'format_name'">
              <span class="font-weight-bold">
                {{ props.row?.name }}
              </span>
            </div>

            <div v-if="props.column.field === 'format_leave_allowance'">
              <b-badge
                class="ml-1"
                variant="light-success"
              >
                {{ props.row?.leave_allowance }}
              </b-badge>
            </div>

            <template>
              <div v-if="props.column.field === 'format_gender'">
                <b-badge class="ml-1" :variant="getGenderVariant(props.row?.gender)">
                  {{ props.row?.gender }}
                </b-badge>
              </div>
            </template>

            <div v-if="props.column.field === 'format_leave_allow_before_days'">
              <b-badge
                class="ml-1"
                variant="light-warning"
              >
                {{ props.row?.leave_allow_before_days }}
              </b-badge>
            </div>

            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">

              <template v-if="$permissionAbility(LEAVE_TYPES_EDIT, permissions)">
                <span @click="onShow(props.row)">
                  <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    size="16"
                  />
                </span>
              </template>

              <template
                v-if="$permissionAbility(LEAVE_TYPES_DELETE, permissions)"
              >
                <span @click="onDelete(props.row)">
                  <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Delete"
                    size="16"
                  />
                </span>
              </template>

            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10', '25', '50', '100', '500']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>

      <b-modal
        id="modal-leave-types-form"
        centered
        :title="modelType == 'editModel' ? 'Edit Leave Type' : 'Create Leave Type'"
        hide-footer
        no-close-on-backdrop
        size="lg"
        @hidden="hiddenModal"
      >
        <validation-observer ref="leaveTypesValidation">
          <b-form @submit.prevent="validationForm">
            <b-row>
              <b-col cols="6">
                <!-- name -->
                <b-form-group
                  label="Title"
                  label-for="name"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Leave Type"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <!-- leaveAllowance -->
                <b-form-group
                  label="Leave Allowance"
                  label-for="leave-allowance"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="leave allowance"
                    vid="leave_allowance"
                    rules="required"
                  >
                    <b-form-input
                      id="leave-allowance"
                      v-model="leaveAllowance"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="No. of Leaves"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <!-- Leave Allow Before Days -->
                <b-form-group
                  label="Leave Apply Before Days"
                  label-for="leave-allow-before-days"
                  class="required-label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="leave apply before days"
                    vid="leave_allow_before_days"
                    rules="required"
                  >
                    <b-form-input
                      id="leave-allow-before-days"
                      v-model="leaveAllowBeforeDays"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="No. of Days"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <!-- select Gender-->
                <b-form-group
                  label="Gender"
                  label-for="gender"
                  class="required-label"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="gender"
                    vid="gender"
                    rules="required"
                  >
                    <v-select
                      id="gender"
                      v-model="selectGenderId"
                      placeholder="Select Gender"
                      :options="genderOption"
                      :reduce="(option) => option.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label-for="sandwich">
                  <b-row>
                    <!-- Is Sandwich -->
                    <b-col cols="12">
                      <b-form-group>
                        <b-form-checkbox
                          v-model="isSandwichCheck"
                          class="custom-control-primary"
                          @input="onChangeIsSandwichCheck"
                        >
                          Is Sandwich
                        </b-form-checkbox>
                      </b-form-group>
                      <template v-if="isSandwichCheck">
                        <b-row>
                          <!-- weekends -->
                          <b-col cols="4">
                            <b-form-group
                              label="Weekends"
                              label-for="weekends"
                            >
                              <b-row>
                                <b-col cols="12">
                                  <b-form-group>
                                    <b-form-checkbox
                                      v-model="isWeekendsAllDaysReduceCheck"
                                      class="custom-control-primary"
                                      @input="
                                        () => (weekendsReduceCustomDays = '')
                                      "
                                    >
                                      All Days Deduct

                                      <feather-icon
                                          v-b-tooltip.hover
                                          icon="InfoIcon"
                                          class="custom-icon cursor-pointer"
                                          title="Selecting 'All Days Deduct' will apply sandwich leave for weekends. For custom days, please enter the deduction number manually"
                                          size="15"
                                      />
                                    </b-form-checkbox>

                                  </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                  <!-- Leave Allow Before Days -->
                                  <b-form-group
                                    label="Custom Days Deduct"
                                    label-for="custom_days_reduce"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="custom_days_reduce"
                                      vid="weekends_reduce_custom_days"
                                    >
                                      <b-form-input
                                        id="custom_days_reduce"
                                        v-model="weekendsReduceCustomDays"
                                        type="number"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="ex: 1"
                                        size="sm"
                                        :disabled="isWeekendsAllDaysReduceCheck"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>

                          <b-col cols="4">
                            <!-- holidays -->
                            <b-form-group
                              label="Holidays"
                              label-for="holidays"
                            >
                              <b-row>
                                <b-col cols="12">
                                  <b-form-group>
                                    <b-form-checkbox
                                      v-model="isHolidaysAllDaysReduceCheck"
                                      class="custom-control-primary"
                                      @input="
                                        () => (holidaysReduceCustomDays = '')
                                      "
                                    >
                                      All Days Deduct

                                      <feather-icon
                                          v-b-tooltip.hover
                                          icon="InfoIcon"
                                          class="custom-icon cursor-pointer"
                                          title="Selecting 'All Days Deduct' will apply sandwich leave for holidays. For custom days, please enter the deduction number manually"
                                          size="15"
                                      />
                                    </b-form-checkbox>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                  <!-- Leave Allow Before Days -->
                                  <b-form-group
                                    label="Custom Days Deduct"
                                    label-for="custom_days_reduce"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="custom_days_reduce"
                                      vid="weekends_reduce_custom_days"
                                    >
                                      <b-form-input
                                        id="custom_days_reduce"
                                        v-model="holidaysReduceCustomDays"
                                        type="number"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="ex: 1"
                                        size="sm"
                                        :disabled="isHolidaysAllDaysReduceCheck"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>

                          <b-col cols="4">
                            <!-- holidays -->
                            <b-form-group
                              label="Combined"
                              label-for="combined"
                            >
                              <b-row>
                                <b-col cols="12">
                                  <b-form-group>
                                    <b-form-checkbox
                                      v-model="isCombinedAllDaysReduceCheck"
                                      class="custom-control-primary"
                                      @input="
                                        () => (combinedReduceCustomDays = '')
                                      "
                                    >
                                      All Days Deduct

                                      <feather-icon
                                          v-b-tooltip.hover
                                          icon="InfoIcon"
                                          class="custom-icon cursor-pointer"
                                          title="Selecting 'All Days Deduct' will apply sandwich leave for both weekends and holidays. For custom days, please enter the deduction number manually"
                                          size="15"
                                      />
                                    </b-form-checkbox>
                                  </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                  <!-- Leave Allow Before Days -->
                                  <b-form-group
                                    label="Custom Days Deduct"
                                    label-for="custom_days_reduce"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="custom_days_reduce"
                                      vid="combined_reduce_custom_days"
                                    >
                                      <b-form-input
                                        id="custom_days_reduce"
                                        v-model="combinedReduceCustomDays"
                                        type="number"
                                        :state="errors.length > 0 ? false : null"
                                        placeholder="ex: 1"
                                        size="sm"
                                        :disabled="isCombinedAllDaysReduceCheck"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </template>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- loading button -->
            <template v-if="isLeaveTypeFormSubmitLoading">
              <b-button
                class="float-right"
                variant="primary"
                disabled
              >
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                class="float-right"
                variant="primary"
              >
                {{ modelType == "editModel" ? "Update" : "Submit" }}
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max, min } from '@validations'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import {
  LEAVE_TYPES_CREATE,
  LEAVE_TYPES_EDIT,
  LEAVE_TYPES_DELETE,
} from '@/helpers/permissionsConstant'
import { yearConstants } from "@/helpers/constant/yearConstant";

export default {
  name: 'LeaveTypesView',
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      LEAVE_TYPES_CREATE,
      LEAVE_TYPES_EDIT,
      LEAVE_TYPES_DELETE,
      yearConstants,
      modelType: '',
      leaveTypeId: '',
      name: '',
      filterYear: new Date().getFullYear(),
      leaveAllowance: '',
      selectEmployeeTypeId: '',
      employeeTypesOption: [],
      selectGenderId: 'All',
      genderOption: [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Others', value: 'Others' },
        { label: 'All', value: 'All' },
      ],
      selectFilterYearValue: false,
      filterYearOption: [
        { label: 'Current Year', value: false },
        { label: 'Previous Year', value: true },
      ],
      leaveAllowBeforeDays: '',
      isSandwichCheck: false,
      isWeekendsAllDaysReduceCheck: true,
      isHolidaysAllDaysReduceCheck: true,
      isCombinedAllDaysReduceCheck: true,
      weekendsReduceCustomDays: '',
      holidaysReduceCustomDays: '',
      combinedReduceCustomDays: '',

      pageLength: 10,
      columns: [
        {
          label: 'Name',
          field: 'format_name',
        },
        {
          label: 'Gender',
          field: 'format_gender',
          sortable: false,
        },
        {
          label: 'Allowance',
          field: 'format_leave_allowance',
          sortable: false,
        },
        {
          label: 'Apply Before (Days)',
          field: 'format_leave_allow_before_days',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      // searchTerm: "",
      // delayTimer: null,
      // isLoading: false,
      // totalRecords: 0,
      // serverParams: {
      //   columnFilters: {},
      //   sort: [
      //     { field: "id", type: "desc" },
      //     { field: "name", type: "desc" },
      //     { field: "created_at", type: "desc" },
      //   ],
      //   page: 1,
      //   perPage: 10,
      // },
      isLeaveTypeFormSubmitLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions',
    }),
  },

  async created() {
    try {
      await Promise.all([this.loadItems()])
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },

  methods: {
    getGenderVariant(gender) {
      switch (gender) {
        case 'Male':
          return 'light-success'
        case 'Female':
          return 'light-info'
        case 'Others':
          return 'light-warning'
        default:
          return 'light-primary'
      }
    },
    onChangeIsSandwichCheck() {
      this.isWeekendsAllDaysReduceCheck = true
      this.isHolidaysAllDaysReduceCheck = true
      this.isCombinedAllDaysReduceCheck = true
      this.weekendsReduceCustomDays = ''
      this.holidaysReduceCustomDays = ''
      this.combinedReduceCustomDays = ''
    },
    onChangeCombinedReduceCustomDays(value) {
      if (value) {
        if (this.isCombinedAllDaysReduceCheck) {
          this.isCombinedAllDaysReduceCheck = false
        }
      } else {
        this.isCombinedAllDaysReduceCheck = true
      }
    },
    onChangeWeekendsReduceCustomDays(value) {
      if (value) {
        if (this.isWeekendsAllDaysReduceCheck) {
          this.isWeekendsAllDaysReduceCheck = false
        }
      } else {
        this.isWeekendsAllDaysReduceCheck = true
      }
    },
    onChangeHolidaysReduceCustomDays(value) {
      if (value) {
        if (this.isHolidaysAllDaysReduceCheck) {
          this.isHolidaysAllDaysReduceCheck = false
        }
      } else {
        this.isHolidaysAllDaysReduceCheck = true
      }
    },
    onChangeFilterYear() {
      this.loadItems()
    },
    showModal() {
      this.$bvModal.show('modal-leave-types-form')
    },
    hiddenModal() {
      this.$bvModal.hide('modal-leave-types-form')
      this.resetModal()
    },
    resetModal() {
      this.modelType = ''
      this.name = ''
      this.leaveAllowance = ''
      this.leaveAllowBeforeDays = ''
      this.selectGenderId = ''

      this.isSandwichCheck = false
      this.isWeekendsAllDaysReduceCheck = true
      this.isHolidaysAllDaysReduceCheck = true
      this.isCombinedAllDaysReduceCheck = true
      this.weekendsReduceCustomDays = ''
      this.holidaysReduceCustomDays = ''
      this.combinedReduceCustomDays = ''
    },
    onShow(value) {
      this.modelType = 'editModel'
      this.leaveTypeId = value?.id
      this.name = value?.name
      this.leaveAllowance = value?.leave_allowance
      this.leaveAllowBeforeDays = value?.leave_allow_before_days
      this.selectGenderId = value?.gender

      this.isSandwichCheck = value?.is_sandwich
      this.isWeekendsAllDaysReduceCheck = value?.weekends_reduce_all
      this.isHolidaysAllDaysReduceCheck = value?.holidays_reduce_all
      this.isCombinedAllDaysReduceCheck = value?.combined_reduce_all
      this.weekendsReduceCustomDays = value?.weekends_reduce_custom_days
      this.holidaysReduceCustomDays = value?.holidays_reduce_custom_days
      this.combinedReduceCustomDays = value?.combined_reduce_custom_days

      this.showModal()
    },

    async onDelete(row) {
      this.$swal({
        title: 'Warning!',
        text: `Are You Sure, You Want To Delete ${row.name}?`,
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/leave-types/${row.id}`)

            this.loadItems()

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Successfully Deleted',
              },
            })
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                variant: 'danger',
                text: error?.response?.data?.message,
              },
            })
          }
        }
      })
    },

    // onSearch() {
    //   if (this.delayTimer) {
    //     clearTimeout(this.delayTimer);
    //     this.delayTimer = null;
    //   }

    //   this.delayTimer = setTimeout(() => {
    //     this.loadItems();
    //   }, 1000);
    // },

    // updateParams(newProps) {
    //   this.serverParams = Object.assign({}, this.serverParams, newProps);
    // },

    // onPageChange(params) {
    //   this.updateParams({ page: params.currentPage });
    //   this.loadItems();
    // },

    // onPerPageChange(params) {
    //   this.updateParams({ perPage: params.currentPerPage });
    //   this.loadItems();
    // },

    // onSortChange(params) {
    //   this.updateParams({
    //     sort: params,
    //   });
    //   this.loadItems();
    // },

    // onColumnFilter(params) {
    //   this.updateParams(params);
    //   this.loadItems();
    // },

    async getLeaveTypes(yearValue) {
      return await this.$api.get('api/leave-types?include=employeeType', {
        params: {
          year: this.filterYear,
        },
      })
    },

    async loadItems() {
      try {
        const [leaveTypes] = await Promise.all([
          this.getLeaveTypes(this.selectFilterYearValue),
        ])

        const data = leaveTypes?.data?.data
        this.rows = data
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Warning',
            icon: 'BellIcon',
            variant: 'warning',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    async validationForm() {
      this.$refs.leaveTypesValidation.validate().then(async success => {
        if (success) {
          try {
            if (this.modelType == 'editModel') {
              this.isLeaveTypeFormSubmitLoading = true
              await this.$api.put(`/api/leave-types/${this.leaveTypeId}`, {
                name: this.name,
                gender: this.selectGenderId,
                leave_allowance: this.leaveAllowance,
                leave_allow_before_days: this.leaveAllowBeforeDays,
                is_sandwich: this.isSandwichCheck,
                weekends_reduce_all: this.isWeekendsAllDaysReduceCheck,
                holidays_reduce_all: this.isHolidaysAllDaysReduceCheck,
                combined_reduce_all: this.isCombinedAllDaysReduceCheck,
                weekends_reduce_custom_days: this.weekendsReduceCustomDays
                  ? +this.weekendsReduceCustomDays
                  : 0,
                holidays_reduce_custom_days: this.holidaysReduceCustomDays
                  ? +this.holidaysReduceCustomDays
                  : 0,
                combined_reduce_custom_days: this.combinedReduceCustomDays
                  ? +this.combinedReduceCustomDays
                  : 0,
              })
              this.isLeaveTypeFormSubmitLoading = false
              this.loadItems()

              this.hiddenModal()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Updated',
                },
              })
            } else {
              this.isLeaveTypeFormSubmitLoading = true
              await this.$api.post(
                '/api/leave-types',

                {
                  name: this.name,
                  gender: this.selectGenderId,
                  leave_allowance: this.leaveAllowance,
                  leave_allow_before_days: this.leaveAllowBeforeDays,
                  is_sandwich: this.isSandwichCheck,
                  weekends_reduce_all: this.isWeekendsAllDaysReduceCheck,
                  holidays_reduce_all: this.isHolidaysAllDaysReduceCheck,
                  combined_reduce_all: this.isCombinedAllDaysReduceCheck,
                  weekends_reduce_custom_days: this.weekendsReduceCustomDays
                    ? +this.weekendsReduceCustomDays
                    : 0,
                  holidays_reduce_custom_days: this.holidaysReduceCustomDays
                    ? +this.holidaysReduceCustomDays
                    : 0,
                  combined_reduce_custom_days: this.combinedReduceCustomDays
                    ? +this.combinedReduceCustomDays
                    : 0,
                },
              )
              this.isLeaveTypeFormSubmitLoading = false
              this.hiddenModal()

              this.loadItems()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: 'Successfully Created',
                },
              })
            }
          } catch (error) {
            this.isLeaveTypeFormSubmitLoading = false
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  variant: 'danger',
                  text: error?.response?.data?.message,
                },
              })
            }

            if (error?.response?.data?.errors) {
              this.$refs.leaveTypesValidation.setErrors(
                error?.response?.data?.errors,
              )
            }
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.actionBtn span{
  padding: 8px;
  margin: 1px;
  cursor: pointer;
}
</style>
