<template>
  <section class="invoice-add-wrapper">
 
    <b-row class="invoice-add">
      <b-col cols="12" xl="9" md="8">
       
            <b-card no-body class="invoice-preview-card">
              <b-card-body class="invoice-padding pb-0">
                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                  <div>
                    <div class="logo-wrapper">
                      <template v-if="companyLogo">
                        <img :src="companyLogo" alt="Company Logo" height="60"
                        style="margin-bottom: 20px" />
                      </template>
<!--                      <template v-else>-->
<!--                        <h1>[Logo]</h1>-->
<!--                      </template>-->
                    </div>
                  </div>

                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div class="d-flex align-items-center">
                      <div>
                        <b-badge
                            v-if="status === paymentPaidConstants"
                            variant="light-success"
                        >Paid</b-badge
                        >
                        <b-badge
                            v-else-if="status === paymentDueConstants"
                            variant="light-danger"
                            f
                        >Due</b-badge
                        >
                      </div>
                    </div>


                    <div
                      class="d-flex align-items-center justify-content-md-end mb-1"
                    >
                      <h4 class="invoice-title">Invoice</h4>
                      <b-input-group
                        class="input-group-merge invoice-edit-input-group disabled"
                      >
                        
                        <b-form-input
                          id="invoice-data-id"
                          v-model="invoiceId"
                          disabled
                        />
                      </b-input-group>
                    </div>

                    <div class="d-flex align-items-center mb-1">
                      <span class="title">Generated Date: </span>

                      <b-form-group>
                        
                          <b-form-input
                            v-model="invoiceDate"
                            class="form-control invoice-edit-input"
                            disabled
                          />
                      </b-form-group>
                    </div>
                    <div class="d-flex align-items-center">
                      <span class="title"> Payment Date: </span>
                      <b-form-group>
                        
                          <b-form-input
                            v-model="paymentDate"
                            class="form-control invoice-edit-input"
                            disabled
                          />
                          
                      </b-form-group>
                    </div>
                  </div>
                  </div>
              </b-card-body>

              <hr class="invoice-spacing" />
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing">
                  <!-- Col: Invoice To -->
                  <b-col cols="12" xl="6" class="mb-lg-1">

                    <h5 class="mb-30">Invoice From:</h5>

                    <div>
                      <b>Address</b>
                      <div v-html="this.officeAddress"></div>

                      <p class="card-text mb-0">{{ this.officeEmail }}</p>
                      <p class="card-text mb-0">{{ this.officeMobile }}</p>
                    </div>
                    
                  </b-col>

                  <!-- Col: Payment Details -->
                  <b-col
                    xl="6"
                    cols="12"
                    class="mt-xl-0 mt-2 d-flex align-items-end flex-column"
                  >
                    <h5 class="mb-30">Invoice To:</h5>

                    <h6 class="d-inline mb-25">
                      {{ sellerName }}
                    </h6>
                    <b-badge variant="light-primary" class="ms-2">{{ sellerWebsite }}</b-badge>
                    <p class="card-text mb-25">
                      Email:
                      {{
                        sellerEmail
                      }}
                    </p>
                    <p class="card-text mb-25">
                      Contact No:
                      {{
                        sellerContact
                      }}
                    </p>
                  </b-col>
                </b-row>

                
              </b-card-body>

              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                >
                  <b-row
                    ref="row"
                    class="pb-2"
                  >
                    <b-col cols="12">
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <b-col cols="12" lg="5"> Item </b-col>
                          <b-col cols="12" lg="3"> Project </b-col>
                          <b-col cols="12" lg="2"> Type </b-col>
                          <b-col class="text-right" cols="12" lg="2"> Amount </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <div class="border rounded">
                        <div v-for="(item, index) in invoiceItems" :key="index" class="d-flex">
                          <b-row  class="flex-grow-1 p-2">
                            <!-- Single Item Form Headers -->
                            <b-col cols="12" md="6" lg="5">

                              <span class="font-weight-bold">Backlink: </span>
                              <a
                                  :href="sellerWebsite + '/' + item.backlink_url"
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >
                                {{ formatWebsite(sellerWebsite + '/' + item.backlink_url) }}
                              </a>

                              <br>

                              <span class="font-weight-bold">URL: </span>
                              <a
                                  :href="item.targeted_url"
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >
                                {{ item.targeted_url }}
                              </a>
                            </b-col>

                            <b-col cols="12" md="6" lg="3">
                              {{ item.project?.data?.title}}
                            </b-col>

                            <b-col cols="12" lg="2">
                              {{ item.backlinks_type_text}}
                            </b-col>

                            <b-col class="text-right" cols="12" md="6" lg="2">
                              {{ item.currency?.data?.currency }} {{ item.cost_price }}
                            </b-col>
                          </b-row>
                        </div>
                      </div>

                    </b-col>
                  </b-row>
                </div>
               
              </b-card-body>

              <b-card-body class="invoice-padding pb-0 mb-4">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  >
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper custom-width">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Sub Total:</p>
                        <p class="invoice-total-amount">
                          {{ getCurrency() }} {{ subTotalAmount }}
                        </p>
                      </div>

                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Discount<template v-if="discountType === 'percentage'"> ({{ discount_value }}%)</template>:</p>
                        <p class="invoice-total-amount">
                          {{ getCurrency() }} {{ discountAmount }}
                        </p>
                      </div>

                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Fee<template v-if="feeType === 'percentage'"> ({{ fee_value }}%)</template>:</p>
                        <p class="invoice-total-amount">
                          {{ getCurrency() }} {{ feeAmount }}
                        </p>
                      </div>

                      <hr class="my-50" />
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">Grand Total:</p>
                        <p class="invoice-total-amount">
                          {{ getCurrency() }} {{ grandTotalAmount }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr v-if="remarks" class="invoice-spacing" />

              <!-- Note -->
               <b-card-body v-if="remarks" class="invoice-padding pt-0">
                <span class="font-weight-bold">Note: </span>
                <b-form-textarea disabled v-model="remarks" />
              </b-card-body>
            </b-card>
          
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <!-- Action Buttons -->
        <b-card>
          <b-button
              :disabled="status === paymentPaidConstants"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="success"
              class="mb-75"
              block
              @click="payInvoice()"
          >
            Pay
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            v-on:click="printInvoice"
          >
            Print
          </b-button>


          <div class="my-3">
            <h6>Reviewer:</h6>
            <UserCard :user="approver" />
          </div>

          <b-col
              cols="12"
              class="mt-xl-0 mt-2"
          >
            <div>
              <h6 class="mb-30">Payment Details:</h6>
              <table>
                <tbody>
                <div>
                  <tr>
                    <td class="pr-1">Payment Method:</td>
                    <td>{{ paymentMethod }}</td>
                  </tr>

                  <tr v-if="paymentDetails">
                    <td class="pr-1">Remarks:</td>
                    <td>{{ paymentDetails }}</td>
                  </tr>
                </div>
                </tbody>
              </table>
            </div>
          </b-col>

        </b-card>
      </b-col>
    </b-row>

    <!-- Printed Invoice  -->
    <div id="content" style="display: none; flex-direction: column; width: 100%; padding: 20px; font-family: Arial, sans-serif;">
      <div style="font-family: Arial, sans-serif; margin: 20px; padding: 20px; border: 1px solid #ccc;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
          <div>
            <div style="margin-bottom: 20px;">
              <template v-if="companyLogo">
                <img :src="companyLogo" alt="Company Logo" height="60" />
              </template>
<!--              <template v-else>-->
<!--                <h1>[Logo]</h1>-->
<!--              </template>-->
            </div>
          </div>

          <div style="text-align: right;">
            <div>
              <template v-if="status === paymentPaidConstants">
                <span style="background-color: #d4edda; color: #155724; padding: 5px 10px; border-radius: 5px;">Paid</span>
              </template>
              <template v-else-if="status === paymentDueConstants">
                <span style="background-color: #f8d7da; color: #721c24; padding: 5px 10px; border-radius: 5px;">Due</span>
              </template>
            </div>
            <h4 style="margin: 10px 0;">Invoice</h4>
            <div style="margin-bottom: 10px;">
              <strong>Invoice ID:</strong> {{ invoiceId }}
            </div>
            <div style="margin-bottom: 10px;">
              <strong>Generated Date:</strong> {{ invoiceDate }}
            </div>
            <div>
              <strong>Payment Date:</strong> {{ paymentDate }}
            </div>
          </div>
        </div>

        <hr style="margin: 20px 0;" />

        <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
          <div>
            <h5>Invoice From:</h5>
            <div>
              <strong>Address:</strong>
              <div v-html="officeAddress"></div>
              <p>Email: {{ officeEmail }}</p>
              <p>Contact No: {{ officeMobile }}</p>
            </div>
          </div>

          <div style="text-align: right;">
            <h5>Invoice To:</h5>
            <h6>{{ sellerName }}</h6>
            <span style="background-color: #cce5ff; color: #004085; padding: 5px 10px; border-radius: 5px;">{{ sellerWebsite }}</span>
            <p>Email: {{ sellerEmail }}</p>
            <p>Contact No: {{ sellerContact }}</p>
          </div>
        </div>

        <div style="border: 1px solid #ccc; border-radius: 5px; padding: 10px;">
          <div style="display: flex; border-bottom: 1px solid #ccc; padding: 10px 0;">
            <div style="flex: 1;"><strong>Item</strong></div>
            <div style="flex: 1;"><strong>Project</strong></div>
            <div style="flex: 1;"><strong>Type</strong></div>
            <div style="flex: 1; text-align: right;"><strong>Amount</strong></div>
          </div>
          <div>
            <template v-for="(item, index) in invoiceItems">
              <div style="display: flex; padding: 10px 0;">
                <div style="flex: 1;">
                  <strong>Backlink:</strong> <a :href="sellerWebsite + '/' + item.backlink_url" target="_blank">{{ formatWebsite(sellerWebsite + '/' + item.backlink_url) }}</a><br>
                  <strong>URL:</strong> <a :href="item.targeted_url" target="_blank">{{ item.targeted_url }}</a>
                </div>
                <div style="flex: 1;">{{ item.project?.data?.title }}</div>
                <div style="flex: 1;">{{ item.backlinks_type_text }}</div>
                <div style="flex: 1; text-align: right;">{{ item.currency?.data?.name }} {{ item.cost_price }}</div>
              </div>
            </template>
          </div>
        </div>

        <div style="margin-top: 20px;">
          <div style="display: flex; justify-content: space-between;">
            <div style="flex: 1;"></div>
            <div style="flex: 1; text-align: right;">
              <div>
                <p><strong>Sub Total:</strong> {{ getCurrency() }} {{ subTotalAmount }}</p>
                <p><strong>Discount <template v-if="discountType === 'percentage'"> ({{ discount_value }}%)</template>:</strong> {{ getCurrency() }} {{ discountAmount }}</p>
                <p><strong>Fee:</strong> {{ getCurrency() }} {{ feeAmount }}</p>
                <hr style="margin: 10px 0;" />
                <p><strong>Grand Total:</strong> {{ getCurrency() }} {{ grandTotalAmount }}</p>
              </div>
            </div>
          </div>
        </div>

        <hr v-if="remarks" style="margin: 20px 0;" />

        <div v-if="remarks" style="margin-top: 20px;">
          <strong>Note:</strong>
          <p>{{ remarks }}</p>
        </div>
      </div>
    </div>



  </section>
</template>
  
  <script>
  import {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BModal,
    BSpinner,
    BRow,
    BCol,
    VBTooltip,
    BCardBody,
    BCardText,
    VBToggle,
    BLink,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BTableLite,
  } from "bootstrap-vue";
  import { VueGoodTable } from "vue-good-table";
  import Ripple from "vue-ripple-directive";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, max } from "@validations";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { mapGetters } from "vuex";
  import UserAssignPolicyForm from "@/views/admin/payroll-management/UserAssignPolicyForm.vue";
  import flatPickr from "vue-flatpickr-component";
  import Logo from "@core/layouts/components/Logo.vue";
  import {
    SALARY_POLICY_CREATE,
    SALARY_POLICY_EDIT,
    SALARY_POLICY_DELETE,
    SALARY_POLICY_SHOW,
  } from "@/helpers/permissionsConstant";
  import UserCard from "@/layouts/components/UserCard.vue";
  import { paymentPaidConstants, paymentUnpaidConstants, paymentDueConstants, outreachInvoiceStatusConstants } from "@/helpers/constant/outreachPaymentStatusConstant";

  export default {
    name: "OutreachInvoiceDetailsView",
    components: {
      UserCard,
      UserAssignPolicyForm,
      BForm,
      BButton,
      BCard,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      ValidationProvider,
      ValidationObserver,
      BModal,
      BSpinner,
      BRow,
      BCol,
      VBTooltip,
      BCardBody,
      BCardText,
      VBToggle,
      BLink,
      Logo,
      BInputGroup,
      BInputGroupPrepend,
      flatPickr,
      BFormTextarea,
      BTableLite,


    },
    directives: {
      "b-tooltip": VBTooltip,
    'b-toggle': VBToggle,

      Ripple,
    },
    data() {
      return {

        paymentPaidConstants,
        paymentUnpaidConstants,
        paymentDueConstants,
        outreachInvoiceStatusConstants,

        approver: null,
        clientAddress: "",

        invoiceItems: [],

        // data
        officeAddress: "",
        officeEmail: "",
        officeMobile: "",

        sellerName: "",
        sellerEmail: "",
        sellerContact: "",

        invoiceId: "",
        invoiceDate: "",
        status: "",
        remarks: "",
        paymentDate: "",

        targetedLink: "",
        sellerWebsite: "",
        costPrice: "",
        currency: "",

        subTotalAmount: 0,
        feeAmount: 0,
        discountType: '',
        discount_value: 0,

        feeType: '',
        fee_value: 0,
        discountAmount: 0,
        grandTotalAmount: 0,

        paymentMethod: "",
        paymentDetails: "",
      };
    },
  
    computed: {
      ...mapGetters({
        permissions: "userModule/getPermissions",
      }),
      statusVariant() {
        const statusColor = {
          true: "light-success",
          false: "light-danger",
        };
  
        return (status) => statusColor[status];
      },

      companyLogo() {
      return this.$store.getters["authModule/companyLogo"];
    },
    },
  
    async created() {
      try {
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  
    methods: {

      getCurrency()
      {
        return this.invoiceItems[0]?.currency?.data?.currency
      },

      payInvoice() {
        this.$swal({
          title: "Warning!",
          text: "Are You Sure You Want To Pay This?",
          icon: "warning",
          input: "password",
          inputPlaceholder: "Enter your password",
          inputAttributes: {
            autocapitalize: "off",
            autocorrect: "off",
          },
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
            input: 'mt-1'
          },
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: "Ok",
          showLoaderOnConfirm: true,
          preConfirm: (password) => {
            if (!password) {
              this.$swal.showValidationMessage("Password is required.");
            } else {
              return password;
            }
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const id = this.$route.params.id;

              // Send the password along with the invoice payment request
              await this.$api.put(`/api/outreach-invoices/${id}/pay`, {
                password: result.value,
              });

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Invoice Successfully Paid",
                },
              });
            } catch (error) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message || "An error occurred.",
                },
              });
            }
          }
        });
      },


      formatWebsite(website) {
        const maxLength = 20;
        if (website.length > maxLength) {
          return website.slice(0, maxLength) + "...";
        }
        return website;
      },

      formatDateTime(value) {
        if (value) {
          return this.$moment(value).format("MMM Do YYYY");
        }
      },

      formatDate(value) {
        if (value) {
          return this.$moment(value).format("MMM Do YYYY");
        }
        return "Not Set";
      },

    async printInvoice() {
      const printSection = document.getElementById('content');
      // const originalContent = document.body.innerHTML;

      // Create a new window for printing
      const newWindow = window.open('', '_blank', 'width=600,height=400');
      newWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              /* Add any styles you want for the printed content here */
              body {
                font-family: Arial, sans-serif;
                margin: 20px;
              }
            </style>
          </head>
          <body>
            ${printSection.innerHTML}
          </body>
        </html>
      `);
      newWindow.document.close(); // Close the document
      newWindow.print(); // Trigger the print dialog
      newWindow.addEventListener('afterprint', function() {
          newWindow.close(); 
      }); 
    },
      async getOutreachInvoiceItems() {
        const invoiceId = this.$route.params.id;
        return await this.$api.get(`api/outreach-invoices/${invoiceId}?include=site_list.seller,backlinks,backlinks.project, backlinks.currency ,approver`);
      },
      async getSetting(params) {
        return await this.$api.get("/api/settings", {
          params: {
            select: params.select,
          },
        });
      },
  
      async loadItems() {
        try {
         
          const [getSetting, outreachInvoiceDetails ] = await Promise.all([
          this.getSetting({
            select: "account_id,address,email,mobile",
          }),
          this.getOutreachInvoiceItems(),
        ]);

          this.officeAddress = getSetting?.data?.data?.address;
          this.officeEmail = getSetting?.data?.data?.email;
          this.officeMobile = getSetting?.data?.data?.mobile;
  
          const data = outreachInvoiceDetails?.data?.data;

          this.sellerName = data?.site_list?.data?.seller?.data?.name
          this.sellerEmail = data?.site_list?.data?.seller?.data?.email ;
          this.sellerContact = data?.site_list?.data?.seller?.data?.contact_no;
          this.status = data?.status ;
          this.remarks = data?.remarks ;
          this.invoiceId = data?.invoice_number ;
          this.invoiceDate = this.formatDateTime(data?.created_at) ;
          this.paymentDate = this.formatDateTime(data?.payment_date) ;
          this.approver = data?.approver?.data ;

          this.paymentMethod = data?.payment_method_text ;
          this.paymentDetails = data?.payment_details ;

          this.invoiceItems = data?.backlinks?.data

          this.sellerWebsite = data?.site_list?.data?.seller?.data?.website

          this.subTotalAmount = data?.total_amount;
          this.feeAmount = data?.fee_amount;
          this.discountType = data?.discount_type;
          this.discount_value = data?.discount_value;

          this.feeType = data?.fee_type;
          this.fee_value = data?.fee_value;

          this.discountAmount = data?.discount_amount;
          this.grandTotalAmount = data?.grand_total;

          this.feeType = data?.fee_type;
          this.fee_value = data?.fee_value;

        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "BellIcon",
              variant: "warning",
              text: error?.response?.data?.message,
            },
          });
        }
      },
  
     
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
 @import "~@core/scss/base/pages/app-invoice.scss";

 .form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

.custom-width {
  max-width: 25rem !important;
}
  </style>
  
  