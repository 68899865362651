var render = function () {
  var _vm$lead, _vm$lead2, _vm$lead3, _vm$lead4, _vm$lead5, _vm$lead6, _vm$lead7, _vm$lead8, _vm$lead9, _vm$lead10, _vm$lead11, _vm$lead12, _vm$lead13, _vm$lead14, _vm$lead15, _vm$lead16, _vm$lead16$leadStatus, _vm$lead16$leadStatus2, _vm$lead17, _vm$lead17$leadStatus, _vm$lead17$leadStatus2, _vm$lead18, _vm$lead18$leadStatus, _vm$lead18$leadStatus2, _vm$lead19, _vm$lead20, _vm$lead20$leadStatus, _vm$lead20$leadStatus2, _vm$lead21, _vm$lead22, _vm$lead23, _vm$lead23$leadSource, _vm$lead23$leadSource2, _vm$lead24, _vm$lead25, _vm$lead25$leadCatego, _vm$lead25$leadCatego2, _vm$lead26, _vm$lead27, _vm$lead28, _vm$lead29, _vm$lead30, _vm$lead31, _vm$lead32, _vm$lead33, _vm$lead34, _vm$lead35, _vm$lead36, _vm$lead37, _vm$lead38, _vm$lead38$department, _vm$lead38$department2, _vm$lead39, _vm$lead40, _vm$lead41, _vm$lead42, _vm$lead43, _vm$lead44, _vm$lead45, _vm$lead46, _vm$lead47, _vm$lead48, _vm$lead49, _vm$lead50, _vm$lead51, _vm$lead51$leadCountr, _vm$lead52, _vm$lead53, _vm$lead54, _vm$lead55, _vm$lead56, _vm$lead57, _vm$lead58, _vm$lead59, _vm$lead59$leadStatus, _vm$lead59$leadStatus2, _vm$lead60, _vm$lead61, _vm$lead61$leadStatus, _vm$lead61$leadStatus2, _vm$lead62, _vm$lead62$leadStatus, _vm$lead62$leadStatus2, _vm$selectedLeadStatu, _vm$selectedLeadStatu2, _vm$selectedLeadStatu4, _vm$selectedLeadStatu5, _vm$selectedLeadStatu6, _vm$selectedLeadStatu7;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Title")]), (_vm$lead = _vm.lead) !== null && _vm$lead !== void 0 && _vm$lead.title ? [_c('div', [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$lead2 = _vm.lead) === null || _vm$lead2 === void 0 ? void 0 : _vm$lead2.title))])], 1)] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Key Contact Person")]), (_vm$lead3 = _vm.lead) !== null && _vm$lead3 !== void 0 && _vm$lead3.key_contact_person ? [_c('div', [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$lead4 = _vm.lead) === null || _vm$lead4 === void 0 ? void 0 : _vm$lead4.key_contact_person))])], 1)] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Company Name")]), (_vm$lead5 = _vm.lead) !== null && _vm$lead5 !== void 0 && _vm$lead5.company_name ? [_c('div', [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$lead6 = _vm.lead) === null || _vm$lead6 === void 0 ? void 0 : _vm$lead6.company_name))])], 1)] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Email")]), (_vm$lead7 = _vm.lead) !== null && _vm$lead7 !== void 0 && _vm$lead7.email ? [_c('b-link', {
    attrs: {
      "href": 'mailto:' + ((_vm$lead8 = _vm.lead) === null || _vm$lead8 === void 0 ? void 0 : _vm$lead8.email)
    }
  }, [_vm._v(" " + _vm._s((_vm$lead9 = _vm.lead) === null || _vm$lead9 === void 0 ? void 0 : _vm$lead9.email) + " ")])] : !((_vm$lead10 = _vm.lead) !== null && _vm$lead10 !== void 0 && _vm$lead10.email) ? [_c('span', [_vm._v("N/A")])] : _vm._e()], 2)]), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Web Site Link")]), (_vm$lead11 = _vm.lead) !== null && _vm$lead11 !== void 0 && _vm$lead11.website_link ? [_c('b-link', {
    attrs: {
      "href": (_vm$lead12 = _vm.lead) === null || _vm$lead12 === void 0 ? void 0 : _vm$lead12.website_link,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s((_vm$lead13 = _vm.lead) === null || _vm$lead13 === void 0 ? void 0 : _vm$lead13.website_link))])] : !((_vm$lead14 = _vm.lead) !== null && _vm$lead14 !== void 0 && _vm$lead14.website_link) ? [_vm._v(" N/A ")] : _vm._e()], 2)]), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Status")]), (_vm$lead15 = _vm.lead) !== null && _vm$lead15 !== void 0 && _vm$lead15.lead_status_id ? [_c('b-card-text', [_c('b-badge', {
    style: {
      background: (_vm$lead16 = _vm.lead) === null || _vm$lead16 === void 0 ? void 0 : (_vm$lead16$leadStatus = _vm$lead16.leadStatus) === null || _vm$lead16$leadStatus === void 0 ? void 0 : (_vm$lead16$leadStatus2 = _vm$lead16$leadStatus.data) === null || _vm$lead16$leadStatus2 === void 0 ? void 0 : _vm$lead16$leadStatus2.color_code
    },
    attrs: {
      "pill": ""
    }
  }, [_vm._v(_vm._s((_vm$lead17 = _vm.lead) === null || _vm$lead17 === void 0 ? void 0 : (_vm$lead17$leadStatus = _vm$lead17.leadStatus) === null || _vm$lead17$leadStatus === void 0 ? void 0 : (_vm$lead17$leadStatus2 = _vm$lead17$leadStatus.data) === null || _vm$lead17$leadStatus2 === void 0 ? void 0 : _vm$lead17$leadStatus2.name))]), ((_vm$lead18 = _vm.lead) === null || _vm$lead18 === void 0 ? void 0 : (_vm$lead18$leadStatus = _vm$lead18.leadStatus) === null || _vm$lead18$leadStatus === void 0 ? void 0 : (_vm$lead18$leadStatus2 = _vm$lead18$leadStatus.data) === null || _vm$lead18$leadStatus2 === void 0 ? void 0 : _vm$lead18$leadStatus2.type) == 2 && ((_vm$lead19 = _vm.lead) === null || _vm$lead19 === void 0 ? void 0 : _vm$lead19.estimation_sent) == false ? [_c('b-badge', {
    staticClass: "badge-glow mt-1",
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("Estimation Not Sent")])] : ((_vm$lead20 = _vm.lead) === null || _vm$lead20 === void 0 ? void 0 : (_vm$lead20$leadStatus = _vm$lead20.leadStatus) === null || _vm$lead20$leadStatus === void 0 ? void 0 : (_vm$lead20$leadStatus2 = _vm$lead20$leadStatus.data) === null || _vm$lead20$leadStatus2 === void 0 ? void 0 : _vm$lead20$leadStatus2.type) == 2 && (_vm$lead21 = _vm.lead) !== null && _vm$lead21 !== void 0 && _vm$lead21.estimation_sent ? [_c('b-badge', {
    staticClass: "badge-glow mt-1",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v("Estimation Sent")])] : _vm._e()], 2)] : _vm._e()], 2)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Source")]), (_vm$lead22 = _vm.lead) !== null && _vm$lead22 !== void 0 && _vm$lead22.lead_source_id ? [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$lead23 = _vm.lead) === null || _vm$lead23 === void 0 ? void 0 : (_vm$lead23$leadSource = _vm$lead23.leadSource) === null || _vm$lead23$leadSource === void 0 ? void 0 : (_vm$lead23$leadSource2 = _vm$lead23$leadSource.data) === null || _vm$lead23$leadSource2 === void 0 ? void 0 : _vm$lead23$leadSource2.name) + " ")])] : _vm._e()], 2)]), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Category")]), (_vm$lead24 = _vm.lead) !== null && _vm$lead24 !== void 0 && _vm$lead24.lead_category_id ? [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$lead25 = _vm.lead) === null || _vm$lead25 === void 0 ? void 0 : (_vm$lead25$leadCatego = _vm$lead25.leadCategory) === null || _vm$lead25$leadCatego === void 0 ? void 0 : (_vm$lead25$leadCatego2 = _vm$lead25$leadCatego.data) === null || _vm$lead25$leadCatego2 === void 0 ? void 0 : _vm$lead25$leadCatego2.name) + " ")])] : _vm._e()], 2)]), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Mobile")]), (_vm$lead26 = _vm.lead) !== null && _vm$lead26 !== void 0 && _vm$lead26.mobile ? [_c('b-link', {
    attrs: {
      "href": 'tel:' + ((_vm$lead27 = _vm.lead) === null || _vm$lead27 === void 0 ? void 0 : _vm$lead27.mobile)
    }
  }, [_vm._v(" " + _vm._s((_vm$lead28 = _vm.lead) === null || _vm$lead28 === void 0 ? void 0 : _vm$lead28.mobile) + " ")])] : _vm._e()], 2)]), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Tel")]), (_vm$lead29 = _vm.lead) !== null && _vm$lead29 !== void 0 && _vm$lead29.phone ? [_c('b-link', {
    attrs: {
      "href": 'tel:' + ((_vm$lead30 = _vm.lead) === null || _vm$lead30 === void 0 ? void 0 : _vm$lead30.phone)
    }
  }, [_vm._v(" " + _vm._s((_vm$lead31 = _vm.lead) === null || _vm$lead31 === void 0 ? void 0 : _vm$lead31.phone) + " ")])] : [_c('span', [_vm._v("N/A")])]], 2)]), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Last Contact Date & Time")]), (_vm$lead32 = _vm.lead) !== null && _vm$lead32 !== void 0 && _vm$lead32.last_contact_date ? [_c('b-card-text', [_vm._v(" " + _vm._s(_vm._f("moment")((_vm$lead33 = _vm.lead) === null || _vm$lead33 === void 0 ? void 0 : _vm$lead33.last_contact_date, "ddd, MMM Do YYYY, h:mm a")) + " ")])] : _vm._e()], 2)]), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Next Contact Date & Time")]), (_vm$lead34 = _vm.lead) !== null && _vm$lead34 !== void 0 && _vm$lead34.next_contact_date ? [_c('b-card-text', [_vm._v(" " + _vm._s(_vm._f("moment")((_vm$lead35 = _vm.lead) === null || _vm$lead35 === void 0 ? void 0 : _vm$lead35.next_contact_date, "ddd, MMM Do YYYY, h:mm a")) + " ")])] : !((_vm$lead36 = _vm.lead) !== null && _vm$lead36 !== void 0 && _vm$lead36.next_contact_date) ? [_vm._v("N/A")] : _vm._e()], 2)])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Department")]), (_vm$lead37 = _vm.lead) !== null && _vm$lead37 !== void 0 && _vm$lead37.department_id ? [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$lead38 = _vm.lead) === null || _vm$lead38 === void 0 ? void 0 : (_vm$lead38$department = _vm$lead38.department) === null || _vm$lead38$department === void 0 ? void 0 : (_vm$lead38$department2 = _vm$lead38$department.data) === null || _vm$lead38$department2 === void 0 ? void 0 : _vm$lead38$department2.name) + " ")])] : _vm._e()], 2)]), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Assigned To")]), (_vm$lead39 = _vm.lead) !== null && _vm$lead39 !== void 0 && _vm$lead39.team ? [_c('b-card-text', _vm._l(_vm.lead.team.data, function (item, index) {
    return _c('span', {
      key: index
    }, [_c('UserCard', {
      attrs: {
        "user": item
      }
    })], 1);
  }), 0)] : _vm._e()], 2)]), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Address")]), (_vm$lead40 = _vm.lead) !== null && _vm$lead40 !== void 0 && _vm$lead40.address ? [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$lead41 = _vm.lead) === null || _vm$lead41 === void 0 ? void 0 : _vm$lead41.address) + " "), _c('br'), _vm._v(" State: " + _vm._s((_vm$lead42 = _vm.lead) !== null && _vm$lead42 !== void 0 && _vm$lead42.state ? (_vm$lead43 = _vm.lead) === null || _vm$lead43 === void 0 ? void 0 : _vm$lead43.state : "N/A") + " , City: " + _vm._s((_vm$lead44 = _vm.lead) !== null && _vm$lead44 !== void 0 && _vm$lead44.city ? (_vm$lead45 = _vm.lead) === null || _vm$lead45 === void 0 ? void 0 : _vm$lead45.city : "N/A") + ", Street: " + _vm._s((_vm$lead46 = _vm.lead) !== null && _vm$lead46 !== void 0 && _vm$lead46.street ? (_vm$lead47 = _vm.lead) === null || _vm$lead47 === void 0 ? void 0 : _vm$lead47.street : "N/A") + ", Zip Code: " + _vm._s((_vm$lead48 = _vm.lead) !== null && _vm$lead48 !== void 0 && _vm$lead48.zip_code ? (_vm$lead49 = _vm.lead) === null || _vm$lead49 === void 0 ? void 0 : _vm$lead49.zip_code : "N/A") + " ")])] : _vm._e()], 2)]), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Country")]), (_vm$lead50 = _vm.lead) !== null && _vm$lead50 !== void 0 && _vm$lead50.country_id ? [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$lead51 = _vm.lead) === null || _vm$lead51 === void 0 ? void 0 : (_vm$lead51$leadCountr = _vm$lead51.leadCountry) === null || _vm$lead51$leadCountr === void 0 ? void 0 : _vm$lead51$leadCountr.data.name))])] : _vm._e()], 2)]), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Note")]), (_vm$lead52 = _vm.lead) !== null && _vm$lead52 !== void 0 && _vm$lead52.note ? [_c('b-card-text', [_vm._v(" " + _vm._s((_vm$lead53 = _vm.lead) === null || _vm$lead53 === void 0 ? void 0 : _vm$lead53.note))])] : !((_vm$lead54 = _vm.lead) !== null && _vm$lead54 !== void 0 && _vm$lead54.note) ? [_c('b-card-text', [_vm._v(" N/A")])] : _vm._e()], 2)])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Business Card (Front)")]), (_vm$lead55 = _vm.lead) !== null && _vm$lead55 !== void 0 && _vm$lead55.business_card_front ? [_c('b-img', {
    staticClass: "small-image",
    attrs: {
      "src": (_vm$lead56 = _vm.lead) === null || _vm$lead56 === void 0 ? void 0 : _vm$lead56.business_card_front
    }
  })] : [_c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "danger"
    }
  }, [_vm._v("N/A")])]], 2)]), _c('b-col', {
    attrs: {
      "cols": "6",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h5', {
    staticClass: "text-capitalize"
  }, [_vm._v("Business Card (Back) ")]), (_vm$lead57 = _vm.lead) !== null && _vm$lead57 !== void 0 && _vm$lead57.business_card_back ? [_c('b-img', {
    staticClass: "small-image",
    attrs: {
      "src": (_vm$lead58 = _vm.lead) === null || _vm$lead58 === void 0 ? void 0 : _vm$lead58.business_card_back
    }
  })] : [_c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "danger"
    }
  }, [_vm._v("N/A")])]], 2)])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "cols": "12"
    }
  }, [_c('b-card', [_c('div', [((_vm$lead59 = _vm.lead) === null || _vm$lead59 === void 0 ? void 0 : (_vm$lead59$leadStatus = _vm$lead59.leadStatus) === null || _vm$lead59$leadStatus === void 0 ? void 0 : (_vm$lead59$leadStatus2 = _vm$lead59$leadStatus.data) === null || _vm$lead59$leadStatus2 === void 0 ? void 0 : _vm$lead59$leadStatus2.type) == 2 ? [_c('b-alert', {
    attrs: {
      "variant": "success",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" This lead has been converted successfully & locked! "), ((_vm$lead60 = _vm.lead) === null || _vm$lead60 === void 0 ? void 0 : _vm$lead60.estimation_sent) == false ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 ml-1",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.showEstimationModal
    }
  }, [_vm._v(" Financial Estimation ")])] : _vm._e()], 2)])] : ((_vm$lead61 = _vm.lead) === null || _vm$lead61 === void 0 ? void 0 : (_vm$lead61$leadStatus = _vm$lead61.leadStatus) === null || _vm$lead61$leadStatus === void 0 ? void 0 : (_vm$lead61$leadStatus2 = _vm$lead61$leadStatus.data) === null || _vm$lead61$leadStatus2 === void 0 ? void 0 : _vm$lead61$leadStatus2.type) == 3 ? [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("This lead has been lost & locked!")])])] : _vm._e(), _c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.$permissionAbility(_vm.LEAD_ACTIVITY_CREATE, _vm.permissions) ? [((_vm$lead62 = _vm.lead) === null || _vm$lead62 === void 0 ? void 0 : (_vm$lead62$leadStatus = _vm$lead62.leadStatus) === null || _vm$lead62$leadStatus === void 0 ? void 0 : (_vm$lead62$leadStatus2 = _vm$lead62$leadStatus.data) === null || _vm$lead62$leadStatus2 === void 0 ? void 0 : _vm$lead62$leadStatus2.type) == 1 ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.newLogModal
    }
  }, [_vm._v(" Create Activity Log ")])] : _vm._e()] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "styleClass": "vgt-table lead-details-table striped condensed",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{
          field: 'last_contact_date',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$column2, _props$column3, _props$row4, _props$row4$leadStatu, _props$row4$leadStatu2, _props$row5, _props$row5$leadStatu, _props$row5$leadStatu2, _props$column4, _props$row6, _props$row6$created_b, _props$column5, _props$column6;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_file' ? [((_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.file.length) > 0 ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-sm",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              var _props$row2;
              return _vm.showAttachmentModal((_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.file);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "DownloadIcon"
          }
        })], 1) : [_c('b', [_vm._v("No Files To Download")])]] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_remarks' ? [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-sm",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              var _props$row3;
              return _vm.showDescriptionModal((_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.remarks);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_lead_status' ? [_c('b-badge', {
          style: {
            background: props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : (_props$row4$leadStatu = _props$row4.leadStatus) === null || _props$row4$leadStatu === void 0 ? void 0 : (_props$row4$leadStatu2 = _props$row4$leadStatu.data) === null || _props$row4$leadStatu2 === void 0 ? void 0 : _props$row4$leadStatu2.color_code
          },
          attrs: {
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : (_props$row5$leadStatu = _props$row5.leadStatus) === null || _props$row5$leadStatu === void 0 ? void 0 : (_props$row5$leadStatu2 = _props$row5$leadStatu.data) === null || _props$row5$leadStatu2 === void 0 ? void 0 : _props$row5$leadStatu2.name) + " ")])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'format_created_by' ? [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : (_props$row6$created_b = _props$row6.created_by) === null || _props$row6$created_b === void 0 ? void 0 : _props$row6$created_b.name) + " ")] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column5 = props.column) === null || _props$column5 === void 0 ? void 0 : _props$column5.field) === 'action' ? _c('span', [_vm.$permissionAbility(_vm.LEAD_ACTIVITY_EDIT, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "Edit2Icon",
            "title": "Edit",
            "variant": "warning",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.LEAD_ACTIVITY_DELETE, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              var _props$row7;
              return _vm.onDelete(props === null || props === void 0 ? void 0 : (_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : _props$row7.id);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "TrashIcon",
            "variant": "danger",
            "title": "Delete",
            "size": "16"
          }
        })], 1)] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props === null || props === void 0 ? void 0 : (_props$column6 = props.column) === null || _props$column6 === void 0 ? void 0 : _props$column6.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props === null || props === void 0 ? void 0 : props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 2), _c('b-modal', {
    attrs: {
      "id": "description-modal",
      "centered": "",
      "title": "Remarks",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenDescriptionModal
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.modalDescription) + " ")])], 1), _c('b-modal', {
    attrs: {
      "id": "attachment-modal",
      "centered": "",
      "title": "Attachments",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenAttachmentModal
    }
  }, [_vm.selectedAttachments && Array.isArray(_vm.selectedAttachments) ? _c('b-card-text', [_c('b-row', _vm._l(_vm.selectedAttachments, function (fileUrl, index) {
    return _c('b-col', {
      key: index,
      staticClass: "mb-1",
      attrs: {
        "cols": "6"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between"
    }, [_c('h5', {
      staticClass: "mb-0"
    }, [_c('b-link', {
      staticClass: "d-flex align-items-center",
      attrs: {
        "href": fileUrl.original_url,
        "target": "_blank"
      }
    }, [_c('feather-icon', {
      staticClass: "mr-2",
      attrs: {
        "icon": "DownloadIcon"
      }
    }), _vm._v(" Attachment ")], 1)], 1), _c('b-button', {
      staticClass: "ml-2",
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteAttachment(fileUrl);
        }
      }
    }, [_vm._v(" X ")])], 1)]);
  }), 1)], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "modal-activity-log-show-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit Activity Log' : 'Create Activity Log ',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "leadActvityLogValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-row', [_vm.$permissionAbility(_vm.LEAD_ACTIVITY_EDIT, _vm.permissions) ? [_c('b-col', {
    attrs: {
      "md": "10",
      "lg": "10",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Lead Status ",
      "label-for": "leadStatusId"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "lead status",
      "vid": "lead_status_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "division-id",
            "placeholder": "Assign a Status",
            "options": _vm.statusIdOption,
            "reduce": function reduce(country) {
              return country.id;
            },
            "label": "name"
          },
          on: {
            "input": _vm.checkForFinalStatus
          },
          model: {
            value: _vm.leadStatusId,
            callback: function callback($$v) {
              _vm.leadStatusId = $$v;
            },
            expression: "leadStatusId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 464460820)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.top",
      value: 'Reset Status List!',
      expression: "'Reset Status List!'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "btn-icon rounded-circle mt-2",
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.loadAllStatus
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "RefreshCcwIcon"
    }
  })], 1)], 1)] : _vm._e(), !_vm.$permissionAbility(_vm.LEAD_ACTIVITY_EDIT, _vm.permissions) ? [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "12",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lead Status *",
      "label-for": "leadStatusId"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "lead status",
      "vid": "lead_status_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "division-id",
            "placeholder": "Assign a Status",
            "options": _vm.statusIdOption,
            "reduce": function reduce(item) {
              return item.id;
            },
            "label": "name"
          },
          on: {
            "input": _vm.checkForFinalStatus
          },
          model: {
            value: _vm.leadStatusId,
            callback: function callback($$v) {
              _vm.leadStatusId = $$v;
            },
            expression: "leadStatusId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4028786612)
  })], 1)], 1)] : _vm._e()], 2), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Contact Medium ",
      "label-for": "contact-through"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "contact medium",
      "vid": "contact_through",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "contact-through",
            "options": _vm.selectContactMediumOption,
            "reduce": function reduce(medium) {
              return medium.value;
            },
            "placeholder": "Choose A Contact Medium",
            "label": "text"
          },
          model: {
            value: _vm.contactMedium,
            callback: function callback($$v) {
              _vm.contactMedium = $$v;
            },
            expression: "contactMedium"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Remarks ",
      "label-for": "remarks"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "remarks",
      "vid": "remarks",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "remarks",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Meeting Remarks"
          },
          model: {
            value: _vm.remarks,
            callback: function callback($$v) {
              _vm.remarks = $$v;
            },
            expression: "remarks"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    class: {
      'required-label': ((_vm$selectedLeadStatu = _vm.selectedLeadStatus) === null || _vm$selectedLeadStatu === void 0 ? void 0 : _vm$selectedLeadStatu.type) === 2
    },
    attrs: {
      "label": ((_vm$selectedLeadStatu2 = _vm.selectedLeadStatus) === null || _vm$selectedLeadStatu2 === void 0 ? void 0 : _vm$selectedLeadStatu2.type) === 2 ? 'Project Proposal' : 'Attach File',
      "label-for": "file"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "attachment",
      "rules": _vm.fileValidationRules,
      "vid": "lead_activity_log_file"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var _vm$selectedLeadStatu3;
        var errors = _ref5.errors;
        return [_c('b-form-file', {
          attrs: {
            "multiple": "",
            "id": "file-".concat((_vm$selectedLeadStatu3 = _vm.selectedLeadStatus) === null || _vm$selectedLeadStatu3 === void 0 ? void 0 : _vm$selectedLeadStatu3.type),
            "state": errors.length ? false : null,
            "placeholder": "Choose a file or drop it here...",
            "drop-placeholder": "Drop file here..."
          },
          on: {
            "change": _vm.handleFileChange
          },
          model: {
            value: _vm.file,
            callback: function callback($$v) {
              _vm.file = $$v;
            },
            expression: "file"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    class: {
      'required-label': ((_vm$selectedLeadStatu4 = _vm.selectedLeadStatus) === null || _vm$selectedLeadStatu4 === void 0 ? void 0 : _vm$selectedLeadStatu4.type) !== 2 && ((_vm$selectedLeadStatu5 = _vm.selectedLeadStatus) === null || _vm$selectedLeadStatu5 === void 0 ? void 0 : _vm$selectedLeadStatu5.type) !== 3
    },
    attrs: {
      "label": "Next Contact Date & Time",
      "label-for": "next_contact_date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "next contact date",
      "vid": "next_contact_date",
      "rules": ((_vm$selectedLeadStatu6 = _vm.selectedLeadStatus) === null || _vm$selectedLeadStatu6 === void 0 ? void 0 : _vm$selectedLeadStatu6.type) !== 2 && ((_vm$selectedLeadStatu7 = _vm.selectedLeadStatus) === null || _vm$selectedLeadStatu7 === void 0 ? void 0 : _vm$selectedLeadStatu7.type) !== 3 ? 'required' : ''
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "id": "next-contact-date",
            "state": errors.length > 0 ? false : null,
            "config": {
              enableTime: true,
              dateFormat: 'Y-m-d H:i:S',
              locale: 'en-US'
            },
            "placeholder": "Select Date & Time"
          },
          model: {
            value: _vm.nextContactDate,
            callback: function callback($$v) {
              _vm.nextContactDate = $$v;
            },
            expression: "nextContactDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLeadActivityFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _c('FinancialEstimationForm', {
    attrs: {
      "selectedLeadInfo": _vm.selectedLeadInfo,
      "leadTitle": _vm.leadTitle
    },
    on: {
      "loadLeadInfo": function loadLeadInfo($event) {
        return _vm.updateLeadInfo($event);
      }
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }