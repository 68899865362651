<template>
  <div>
    <!-- Dashboard & Roles -->
    <b-card-actions title="Dashboard & Roles" action-collapse>
      <!-- table -->
      <vue-good-table
        max-height="600px"
        styleClass="vgt-table striped"
        :fixed-header="true"
        :columns="columns"
        :rows="dashboard"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: name -->
          <div v-if="props.column.field === 'name'">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: all -->
          <div v-else-if="props.column.field === 'all'">
            <b-form-checkbox
              :checked="
                roleHasPermissionsIncludes(
                  mapPermissionsIds(props.row?.permissions?.data)
                )
              "
              name="check-button"
              switch
              inline
              @change="
                (isChecked) =>
                  onChangeSwitch(
                    isChecked,
                    mapPermissionsIds(props.row?.permissions?.data),
                    true
                  )
              "
            >
            </b-form-checkbox>
          </div>

          <!-- Column: access -->
          <div v-else-if="props.column.field === 'access'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'access'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: create -->
          <div v-else-if="props.column.field === 'create'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'create'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: show -->
          <div v-else-if="props.column.field === 'show'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'show'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: edit -->
          <div v-else-if="props.column.field === 'edit'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'edit'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: delete -->
          <div v-else-if="props.column.field === 'delete'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'delete'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: other -->
          <div v-else-if="props.column.field === 'others'">
            <template v-if="props.row?.permissions">
              <div v-for="(item, index) in props.row?.permissions?.data" :key="index">
                <b-row v-if="index % 2 === 0">
                  <b-col cols="6">
                    <template v-if="item.group === 'others'">
                      <b-form-checkbox
                        :key="item.id"
                        :checked="roleHasPermissions.includes(item.id)"
                        name="check-button"
                        switch
                        inline
                        @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                      >
                        {{ item.name | strSnakeToPascal }}
                      </b-form-checkbox>
                    </template>
                  </b-col>
                  <b-col v-if="props.row?.permissions?.data[index + 1]" cols="6">
                    <template v-if="props.row?.permissions?.data[index + 1].group === 'others'">
                      <b-form-checkbox
                        :key="props.row.permissions.data[index + 1].id"
                        :checked="roleHasPermissions.includes(props.row.permissions.data[index + 1].id)"
                        name="check-button"
                        switch
                        inline
                        @change="(isChecked) => onChangeSwitch(isChecked, props.row.permissions.data[index + 1].id)"
                      >
                        {{ props.row.permissions.data[index + 1].name | strSnakeToPascal }}
                      </b-form-checkbox>
                    </template>
                  </b-col>
                </b-row>
              </div>
            </template>
          </div>

        </template>
      </vue-good-table>
    </b-card-actions>

    <!-- HRMS -->
    <b-card-actions title="HRMS" action-collapse>
      <!-- table -->
      <vue-good-table
        max-height="600px"
        styleClass="vgt-table striped"
        :fixed-header="true"
        :columns="columns"
        :rows="hrms"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: name -->
          <div v-if="props.column.field === 'name'">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: all -->
          <div v-else-if="props.column.field === 'all'">
            <b-form-checkbox
              :checked="
                roleHasPermissionsIncludes(
                  mapPermissionsIds(props.row?.permissions?.data)
                )
              "
              name="check-button"
              switch
              inline
              @change="
                (isChecked) =>
                  onChangeSwitch(
                    isChecked,
                    mapPermissionsIds(props.row?.permissions?.data),
                    true
                  )
              "
            >
            </b-form-checkbox>
          </div>

          <!-- Column: access -->
          <div v-else-if="props.column.field === 'access'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'access'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: create -->
          <div v-else-if="props.column.field === 'create'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'create'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: show -->
          <div v-else-if="props.column.field === 'show'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'show'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: edit -->
          <div v-else-if="props.column.field === 'edit'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'edit'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: delete -->
          <div v-else-if="props.column.field === 'delete'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'delete'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: others -->
          <div v-else-if="props.column.field === 'others'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'others'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    {{ item?.name | strSnakeToPascal }}
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-actions>

    <!-- Lead -->
    <b-card-actions title="Lead" action-collapse>
      <!-- table -->
      <vue-good-table
        max-height="600px"
        styleClass="vgt-table striped"
        :fixed-header="true"
        :columns="columns"
        :rows="lead"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: name -->
          <div v-if="props.column.field === 'name'">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: all -->
          <div v-else-if="props.column.field === 'all'">
            <b-form-checkbox
              :checked="
                roleHasPermissionsIncludes(
                  mapPermissionsIds(props.row?.permissions?.data)
                )
              "
              name="check-button"
              switch
              inline
              @change="
                (isChecked) =>
                  onChangeSwitch(
                    isChecked,
                    mapPermissionsIds(props.row?.permissions?.data),
                    true
                  )
              "
            >
            </b-form-checkbox>
          </div>

          <!-- Column: access -->
          <div v-else-if="props.column.field === 'access'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'access'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: create -->
          <div v-else-if="props.column.field === 'create'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'create'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: show -->
          <div v-else-if="props.column.field === 'show'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'show'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: edit -->
          <div v-else-if="props.column.field === 'edit'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'edit'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: delete -->
          <div v-else-if="props.column.field === 'delete'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'delete'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: others -->
          <div v-else-if="props.column.field === 'others'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'others'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    {{ item?.name | strSnakeToPascal }}
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-actions>

    <!-- Finance -->
    <b-card-actions title="Finance" action-collapse>
      <!-- table -->
      <vue-good-table
        max-height="600px"
        styleClass="vgt-table striped"
        :fixed-header="true"
        :columns="columns"
        :rows="finance"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: name -->
          <div v-if="props.column.field === 'name'">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: all -->
          <div v-else-if="props.column.field === 'all'">
            <b-form-checkbox
              :checked="
                roleHasPermissionsIncludes(
                  mapPermissionsIds(props.row?.permissions?.data)
                )
              "
              name="check-button"
              switch
              inline
              @change="
                (isChecked) =>
                  onChangeSwitch(
                    isChecked,
                    mapPermissionsIds(props.row?.permissions?.data),
                    true
                  )
              "
            >
            </b-form-checkbox>
          </div>

          <!-- Column: access -->
          <div v-else-if="props.column.field === 'access'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'access'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: create -->
          <div v-else-if="props.column.field === 'create'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'create'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: show -->
          <div v-else-if="props.column.field === 'show'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'show'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: edit -->
          <div v-else-if="props.column.field === 'edit'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'edit'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: delete -->
          <div v-else-if="props.column.field === 'delete'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'delete'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: others -->
          <div v-else-if="props.column.field === 'others'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'others'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    {{ item?.name | strSnakeToPascal }}
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-actions>

    <!-- Task -->
    <b-card-actions title="Task" action-collapse>
      <!-- table -->
      <vue-good-table
        max-height="600px"
        styleClass="vgt-table striped"
        :fixed-header="true"
        :columns="columns"
        :rows="task"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: name -->
          <div v-if="props.column.field === 'name'">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: all -->
          <div v-else-if="props.column.field === 'all'">
            <b-form-checkbox
              :checked="
                roleHasPermissionsIncludes(
                  mapPermissionsIds(props.row?.permissions?.data)
                )
              "
              name="check-button"
              switch
              inline
              @change="
                (isChecked) =>
                  onChangeSwitch(
                    isChecked,
                    mapPermissionsIds(props.row?.permissions?.data),
                    true
                  )
              "
            >
            </b-form-checkbox>
          </div>

          <!-- Column: access -->
          <div v-else-if="props.column.field === 'access'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'access'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: create -->
          <div v-else-if="props.column.field === 'create'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'create'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: show -->
          <div v-else-if="props.column.field === 'show'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'show'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: edit -->
          <div v-else-if="props.column.field === 'edit'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'edit'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: delete -->
          <div v-else-if="props.column.field === 'delete'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'delete'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: others -->
          <div v-else-if="props.column.field === 'others'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'others'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    {{ item?.name | strSnakeToPascal }}
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-actions>

    <!-- Asset -->
    <b-card-actions title="Asset" action-collapse>
      <!-- table -->
      <vue-good-table
        max-height="600px"
        styleClass="vgt-table striped"
        :fixed-header="true"
        :columns="columns"
        :rows="asset"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: name -->
          <div v-if="props.column.field === 'name'">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: all -->
          <div v-else-if="props.column.field === 'all'">
            <b-form-checkbox
              :checked="
                roleHasPermissionsIncludes(
                  mapPermissionsIds(props.row?.permissions?.data)
                )
              "
              name="check-button"
              switch
              inline
              @change="
                (isChecked) =>
                  onChangeSwitch(
                    isChecked,
                    mapPermissionsIds(props.row?.permissions?.data),
                    true
                  )
              "
            >
            </b-form-checkbox>
          </div>

          <!-- Column: access -->
          <div v-else-if="props.column.field === 'access'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'access'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: create -->
          <div v-else-if="props.column.field === 'create'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'create'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: show -->
          <div v-else-if="props.column.field === 'show'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'show'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: edit -->
          <div v-else-if="props.column.field === 'edit'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'edit'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: delete -->
          <div v-else-if="props.column.field === 'delete'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'delete'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: others -->
          <div v-else-if="props.column.field === 'others'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'others'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    {{ item?.name | strSnakeToPascal }}
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-actions>

    <!-- Notice -->
    <b-card-actions title="Notice" action-collapse>
      <!-- table -->
      <vue-good-table
        max-height="600px"
        styleClass="vgt-table striped"
        :fixed-header="true"
        :columns="columns"
        :rows="notice"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: name -->
          <div v-if="props.column.field === 'name'">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: all -->
          <div v-else-if="props.column.field === 'all'">
            <b-form-checkbox
              :checked="
                roleHasPermissionsIncludes(
                  mapPermissionsIds(props.row?.permissions?.data)
                )
              "
              name="check-button"
              switch
              inline
              @change="
                (isChecked) =>
                  onChangeSwitch(
                    isChecked,
                    mapPermissionsIds(props.row?.permissions?.data),
                    true
                  )
              "
            >
            </b-form-checkbox>
          </div>

          <!-- Column: access -->
          <div v-else-if="props.column.field === 'access'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'access'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: create -->
          <div v-else-if="props.column.field === 'create'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'create'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: show -->
          <div v-else-if="props.column.field === 'show'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'show'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: edit -->
          <div v-else-if="props.column.field === 'edit'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'edit'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: delete -->
          <div v-else-if="props.column.field === 'delete'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'delete'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: others -->
          <div v-else-if="props.column.field === 'others'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'others'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    {{ item?.name | strSnakeToPascal }}
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-actions>

    <!-- Loan -->
    <b-card-actions title="Loan" action-collapse>
      <!-- table -->
      <vue-good-table
        max-height="600px"
        styleClass="vgt-table striped"
        :fixed-header="true"
        :columns="columns"
        :rows="loan"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: name -->
          <div v-if="props.column.field === 'name'">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: all -->
          <div v-else-if="props.column.field === 'all'">
            <b-form-checkbox
              :checked="
                roleHasPermissionsIncludes(
                  mapPermissionsIds(props.row?.permissions?.data)
                )
              "
              name="check-button"
              switch
              inline
              @change="
                (isChecked) =>
                  onChangeSwitch(
                    isChecked,
                    mapPermissionsIds(props.row?.permissions?.data),
                    true
                  )
              "
            >
            </b-form-checkbox>
          </div>

          <!-- Column: access -->
          <div v-else-if="props.column.field === 'access'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'access'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: create -->
          <div v-else-if="props.column.field === 'create'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'create'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: show -->
          <div v-else-if="props.column.field === 'show'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'show'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: edit -->
          <div v-else-if="props.column.field === 'edit'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'edit'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: delete -->
          <div v-else-if="props.column.field === 'delete'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'delete'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: others -->
          <div v-else-if="props.column.field === 'others'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'others'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    {{ item?.name | strSnakeToPascal }}
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-actions>

    <!-- Payroll -->
    <b-card-actions title="Payroll" action-collapse>
      <!-- table -->
      <vue-good-table
        max-height="600px"
        styleClass="vgt-table striped"
        :fixed-header="true"
        :columns="columns"
        :rows="payroll"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: name -->
          <div v-if="props.column.field === 'name'">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: all -->
          <div v-else-if="props.column.field === 'all'">
            <b-form-checkbox
              :checked="
                roleHasPermissionsIncludes(
                  mapPermissionsIds(props.row?.permissions?.data)
                )
              "
              name="check-button"
              switch
              inline
              @change="
                (isChecked) =>
                  onChangeSwitch(
                    isChecked,
                    mapPermissionsIds(props.row?.permissions?.data),
                    true
                  )
              "
            >
            </b-form-checkbox>
          </div>

          <!-- Column: access -->
          <div v-else-if="props.column.field === 'access'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'access'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: create -->
          <div v-else-if="props.column.field === 'create'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'create'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: show -->
          <div v-else-if="props.column.field === 'show'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'show'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: edit -->
          <div v-else-if="props.column.field === 'edit'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'edit'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: delete -->
          <div v-else-if="props.column.field === 'delete'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'delete'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: others -->
          <div v-else-if="props.column.field === 'others'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'others'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    {{ item?.name | strSnakeToPascal }}
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-actions>

    <!-- Settings -->
    <b-card-actions title="Settings" action-collapse>
      <!-- table -->
      <vue-good-table
        max-height="600px"
        styleClass="vgt-table striped"
        :fixed-header="true"
        :columns="columns"
        :rows="settings"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: name -->
          <div v-if="props.column.field === 'name'">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: all -->
          <div v-else-if="props.column.field === 'all'">
            <b-form-checkbox
              :checked="
                roleHasPermissionsIncludes(
                  mapPermissionsIds(props.row?.permissions?.data)
                )
              "
              name="check-button"
              switch
              inline
              @change="
                (isChecked) =>
                  onChangeSwitch(
                    isChecked,
                    mapPermissionsIds(props.row?.permissions?.data),
                    true
                  )
              "
            >
            </b-form-checkbox>
          </div>

          <!-- Column: access -->
          <div v-else-if="props.column.field === 'access'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'access'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: create -->
          <div v-else-if="props.column.field === 'create'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'create'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: show -->
          <div v-else-if="props.column.field === 'show'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'show'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: edit -->
          <div v-else-if="props.column.field === 'edit'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'edit'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: delete -->
          <div v-else-if="props.column.field === 'delete'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'delete'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: others -->
          <div v-else-if="props.column.field === 'others'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'others'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    {{ item?.name | strSnakeToPascal }}
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-actions>

    <!-- Subscriptions -->
    <b-card-actions title="Subscriptions" action-collapse>
      <!-- table -->
      <vue-good-table
        max-height="600px"
        styleClass="vgt-table striped"
        :fixed-header="true"
        :columns="columns"
        :rows="subscriptions"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: name -->
          <div v-if="props.column.field === 'name'">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: all -->
          <div v-else-if="props.column.field === 'all'">
            <b-form-checkbox
              :checked="
                roleHasPermissionsIncludes(
                  mapPermissionsIds(props.row?.permissions?.data)
                )
              "
              name="check-button"
              switch
              inline
              @change="
                (isChecked) =>
                  onChangeSwitch(
                    isChecked,
                    mapPermissionsIds(props.row?.permissions?.data),
                    true
                  )
              "
            >
            </b-form-checkbox>
          </div>

          <!-- Column: access -->
          <div v-else-if="props.column.field === 'access'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'access'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: create -->
          <div v-else-if="props.column.field === 'create'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'create'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: show -->
          <div v-else-if="props.column.field === 'show'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'show'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: edit -->
          <div v-else-if="props.column.field === 'edit'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'edit'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: delete -->
          <div v-else-if="props.column.field === 'delete'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'delete'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: others -->
          <div v-else-if="props.column.field === 'others'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'others'">
                  <b-form-checkbox
                    :key="item?.id"
                    :checked="roleHasPermissions.includes(item?.id)"
                    name="check-button"
                    switch
                    inline
                    @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    {{ item?.name | strSnakeToPascal }}
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-actions>

    <!-- Outreach -->
    <b-card-actions title="Outreach" action-collapse>
      <!-- table -->
      <vue-good-table
          max-height="600px"
          styleClass="vgt-table striped"
          :fixed-header="true"
          :columns="columns"
          :rows="outreach"
          :sort-options="{
            enabled: false,
          }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: name -->
          <div v-if="props.column.field === 'name'">
            <span class="text-nowrap">{{ props.row.name }}</span>
          </div>

          <!-- Column: all -->
          <div v-else-if="props.column.field === 'all'">
            <b-form-checkbox
                :checked="
                roleHasPermissionsIncludes(
                  mapPermissionsIds(props.row?.permissions?.data)
                )
              "
                name="check-button"
                switch
                inline
                @change="
                (isChecked) =>
                  onChangeSwitch(
                    isChecked,
                    mapPermissionsIds(props.row?.permissions?.data),
                    true
                  )
              "
            >
            </b-form-checkbox>
          </div>

          <!-- Column: access -->
          <div v-else-if="props.column.field === 'access'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'access'">
                  <b-form-checkbox
                      :key="item?.id"
                      :checked="roleHasPermissions.includes(item?.id)"
                      name="check-button"
                      switch
                      inline
                      @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: create -->
          <div v-else-if="props.column.field === 'create'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'create'">
                  <b-form-checkbox
                      :key="item?.id"
                      :checked="roleHasPermissions.includes(item?.id)"
                      name="check-button"
                      switch
                      inline
                      @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: show -->
          <div v-else-if="props.column.field === 'show'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'show'">
                  <b-form-checkbox
                      :key="item?.id"
                      :checked="roleHasPermissions.includes(item?.id)"
                      name="check-button"
                      switch
                      inline
                      @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: edit -->
          <div v-else-if="props.column.field === 'edit'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'edit'">
                  <b-form-checkbox
                      :key="item?.id"
                      :checked="roleHasPermissions.includes(item?.id)"
                      name="check-button"
                      switch
                      inline
                      @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: delete -->
          <div v-else-if="props.column.field === 'delete'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'delete'">
                  <b-form-checkbox
                      :key="item?.id"
                      :checked="roleHasPermissions.includes(item?.id)"
                      name="check-button"
                      switch
                      inline
                      @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    <!-- {{ item?.name | strSnakeToPascal }} -->
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: others -->
          <div v-else-if="props.column.field === 'others'">
            <template v-if="props.row?.permissions">
              <template v-for="item in props.row?.permissions?.data">
                <template v-if="item?.group == 'others'">
                  <b-form-checkbox
                      :key="item?.id"
                      :checked="roleHasPermissions.includes(item?.id)"
                      name="check-button"
                      switch
                      inline
                      @change="(isChecked) => onChangeSwitch(isChecked, item.id)"
                  >
                    {{ item?.name | strSnakeToPascal }}
                  </b-form-checkbox>
                </template>
              </template>
            </template>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-actions>
  </div>
</template>

<script>
import { BCard, BBadge, BForm, BFormCheckbox, BRow, BCol } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import strSnakeToPascal from "@/filter/strSnakeToPascal";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

export default {
  name: "RolePermissionView",
  components: {
    BForm,
    BCard,
    VueGoodTable,
    BBadge,
    BFormCheckbox,
    BCardActions,
    BRow,
    BCol,
  },

  data() {
    return {
      dashboard: [],
      hrms: [],
      lead: [],
      finance: [],
      task: [],
      asset: [],
      notice: [],
      loan: [],
      payroll: [],
      settings: [],
      subscriptions: [],
      outreach: [],

      pageLength: 10,
      columns: [
        {
          label: "Module",
          field: "name",
        },
        {
          label: "All",
          field: "all",
        },
        {
          label: "Access",
          field: "access",
        },
        {
          label: "Create",
          field: "create",
        },
        {
          label: "Show",
          field: "show",
        },
        {
          label: "Edit",
          field: "edit",
        },
        {
          label: "Delete",
          field: "delete",
        },
        {
          label: "Others",
          field: "others",
        },
      ],
      rows: [],
      roleName: "",
      roleHasPermissions: [],
    };
  },

  async created() {
    const resMD = await this.$api.get(
      "api/get-modules-permissions?include=permissions"
    );

    let dashboard = [];
    let hrms = [];
    let lead = [];
    let finance = [];
    let task = [];
    let asset = [];
    let notice = [];
    let loan = [];
    let payroll = [];
    let settings = [];
    let subscriptions = [];
    let outreach = [];


    resMD.data.data.forEach((item) => {
      switch (item.name) {
        case "Dashboard":
        case "Roles":
          dashboard.push(item);
          break;
        case "HRMS":
        case "Users":
        case "Employee Types":
        case "Division":
        case "Department":
        case "Designation":
        case "Attendance Management":
        case "Attendance":
        case "Remote Attendance":
        case "Remote Attendance Map":
        case "Leave Management":
        case "Leave Balance":
        case "Leave Types":
        case "Leave Requests":
        case "Leave Approve":
        case "User Profile":
        case "User Profile Details":
        case "User Contact Information":
        case "User Family Information":
        case "User Document":
        case "User Bank Account":
        case "User Academic Qualification":
        case "User Experience":
        case "User Designation":
        case "User Employee Type":
        case "User Custom Log":
        case "User Salary":
        case "User Password":
        case "User General Information":
        case "Attendance Reconciliation":
        case "Attendance Report":
        case "Notice":
        case "Attendance Policy":
        case "Attendance Policy Map":
          hrms.push(item);
          break;
        case "Lead Management":
        case "Lead Category":
        case "Lead Source":
        case "Lead Status":
        case "Lead Details":
        case "Lead Activity":
        case "My Leads":
        case "Lead":
          lead.push(item);
          break;
        case "Finance Management":
        case "Project":
        case "Milestone":
        case "Invoice":
        case "Expense":
        case "Account Type":
        case "Account":
        case "Account Deposit":
        case "Account Transfer":
        case "Transaction Histories":
          finance.push(item);
          break;
        case "Task Management":
        case "Boards":
        case "Task Management All Board":
        case "Global Tracker":
        case "Board Task Management":
        case "Board Sub Task Management":
        case "My Tasks":
          task.push(item);
          break;
        case "Asset Management":
        case "Asset Type":
        case "Asset":
        case "Asset Repair History":
        case "Asset Assign User History":
          asset.push(item);
          break;
        case "Notice":
          notice.push(item);
          break;
        case "Loan Management Access":
        case "Loan":
        case "Loan Approver Access":
        case "Loan Policy":
          loan.push(item);
          break;
        case "Payroll Management":
        case "Payroll Policy":
        case "Project Commission Policy":
        case "Meal Policy":
        case "Over Time Policy":
        case "Insurance Policy":
        case "Income Tax Policy":
        case "Festival Bonus Policy":
        case "Provident Fund Policy":
        case "Extra Leave Policy":
        case "Delay Fine Policy":
        case "Payroll Policy Assign":
        case "Salary Payslip":
        case "Fine Policy":
          payroll.push(item);
          break;
        case "Settings":
        case "Weekends":
        case "Holidays":
          settings.push(item);
          break;
        case "Subscription":
        case "Pricing Table":
        case "Payment History":
          subscriptions.push(item);
          break;

        case "Site List":
        case "Back Link":
        case "Outreach Invoice":
        case "Niche":
        case "Outreach Seller":
        case "Outreach Logs":
          outreach.push(item);
          break;
      }
    });

    this.dashboard = dashboard;
    this.hrms = hrms;
    this.lead = lead;
    this.finance = finance;
    this.task = task;
    this.asset = asset;
    this.notice = notice;
    this.loan = loan;
    this.payroll = payroll;
    this.settings = settings;
    this.subscriptions = subscriptions;
    this.outreach = outreach;

    const id = this.$route.params.id;
    const resRHP = await this.$api.get(`api/roles/${id}?include=permissions`);
    this.roleName = resRHP?.data?.data?.name;
    this.roleHasPermissions = resRHP?.data?.data?.permissions?.data.map(
      (item) => item.id
    );
  },

  methods: {
    roleHasPermissionsIncludes(ids) {
      let result = false;

      for (let i = 0; i < ids.length; i++) {
        if (this.roleHasPermissions.includes(ids[i])) {
          result = true;
        } else {
          return (result = false);
        }
      }

      return result;
    },
    mapPermissionsIds(permissions) {
      return permissions.map((item) => item.id);
    },
    async onChangeSwitch(isChecked, permission_ids, isAll = false) {
      const roleId = this.$route.params.id;

      let data = {
        is_all: isAll,
        is_attach: isChecked,
        permission_id: permission_ids,
      };

      if (isAll) {
        data = {
          is_all: isAll,
          is_attach: isChecked,
          permission_ids,
        };
      }

      try {
        const resPermissions = await this.$api.put(
          `api/roles/${roleId}/permission-update?include=permissions`,
          data
        );

        this.roleHasPermissions =
          resPermissions?.data?.data?.permissions?.data.map((item) => item.id);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Successfully Update",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-container > * {
  flex: 1 0 33%; /* This will make each item take up 1/3 of the container's width */
}
</style>
