import { render, staticRenderFns } from "./TaskDetailsView.vue?vue&type=template&id=90481232"
import script from "./TaskDetailsView.vue?vue&type=script&lang=js"
export * from "./TaskDetailsView.vue?vue&type=script&lang=js"
import style0 from "./TaskDetailsView.vue?vue&type=style&index=0&id=90481232&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports