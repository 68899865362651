import { ls } from "@/helpers/helpers";
import { set } from "lodash";

export const authModule = {
  namespaced: true,
  state: {
    isAuthenticated: false,
    token: null,
    companyLogo: null,
  },
  getters: {
    getIsAuthenticated: function (state) {
      return state.isAuthenticated;
    },
    getToken: function (state) {
      return state.token;
    },
    companyLogo: (state) => state.companyLogo,
  },
  mutations: {
    setIsAuthenticated: function (state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
      state.token = payload.token;
    },
    clearPersistedState: function (state, payload) {
      ls.removeAll();
      ls.clear();
    },
    setCompanyLogo: (state, payload) => {
      state.companyLogo = payload.companyLogo;
    },
  },
  actions: {
    setIsAuthenticated: function (context, payload) {
      context.commit("setIsAuthenticated", {
        isAuthenticated: payload.isAuthenticated,
        token: payload.token,
        companyLogo: payload.companyLogo,
      });
    },
    setCompanyLogo: (context, payload) => {
      context.commit("setCompanyLogo", {
        companyLogo: payload.companyLogo,
      });
    },
    clearPersistedState: function (context, payload) {
      context.commit("clearPersistedState");
    },
  },
};
