var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title",
      "label-for": "searchTerm"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "placeholder": "Search Asset By Title ...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch,
      "input": _vm.assetFiltering
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Asset Type",
      "label-for": "filterAssetTypeId"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "id": "filterAssetTypeId",
      "placeholder": "Choose Asset Type",
      "options": _vm.assetTypeConstant,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "name"
    },
    on: {
      "input": _vm.assetFiltering
    },
    model: {
      value: _vm.filter_asset_type,
      callback: function callback($$v) {
        _vm.filter_asset_type = $$v;
      },
      expression: "filter_asset_type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Department",
      "label-for": "departmentId"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "id": "departmentId",
      "placeholder": "Choose Department",
      "options": _vm.selectDepartmentIdOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "label": "name"
    },
    on: {
      "open": _vm.loadDepartments,
      "input": _vm.assetFiltering
    },
    model: {
      value: _vm.department_id,
      callback: function callback($$v) {
        _vm.department_id = $$v;
      },
      expression: "department_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "filteredStatus"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "option-disabled": "disable",
      "id": "filteredStatus",
      "placeholder": "Choose Status",
      "options": _vm.filteredStatusValueOption,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "name"
    },
    on: {
      "open": _vm.loadDepartments,
      "input": _vm.assetFiltering
    },
    model: {
      value: _vm.filter_status,
      callback: function callback($$v) {
        _vm.filter_status = $$v;
      },
      expression: "filter_status"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Warranty Filter",
      "label-for": "warranty_filter"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "id": "warranty_filter",
      "placeholder": "Enter Date Range",
      "config": {
        mode: 'range',
        defaultDate: [this.filterStartDate, this.filterEndDate]
      }
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Asset Category",
      "label-for": "filterAssetCategoryId"
    }
  }, [_c('v-select', {
    staticClass: "custom-font",
    attrs: {
      "id": "filterAssetCategoryId",
      "placeholder": "Choose Asset Category",
      "options": _vm.selectAssetCategoryIdOptions,
      "reduce": function reduce(option) {
        return option.id;
      },
      "label": "name",
      "disabled": _vm.isCategoryDisabled
    },
    on: {
      "input": _vm.getCategoryWiseAttr
    },
    model: {
      value: _vm.assetCategoryId,
      callback: function callback($$v) {
        _vm.assetCategoryId = $$v;
      },
      expression: "assetCategoryId"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('b-dropdown', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    ref: "dropdown",
    staticClass: "btn-adjust",
    attrs: {
      "id": "dropdown-form",
      "right": "",
      "variant": "primary",
      "text": "Select",
      "disabled": _vm.isAttributeDropdownDisabled
    }
  }, [_c('b-dropdown-form', {
    staticClass: "py-1"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter Attribute"
    }
  }, [_c('div', {
    staticClass: "scrollable-checkbox-group"
  }, [_c('b-form-checkbox-group', {
    staticClass: "demo-inline-spacing",
    attrs: {
      "id": "checkbox-group-1",
      "options": _vm.selectAttributeIdOptions,
      "name": "flavour-1"
    },
    on: {
      "input": _vm.getSelectedAttribute
    },
    model: {
      value: _vm.selectedAttribute,
      callback: function callback($$v) {
        _vm.selectedAttribute = $$v;
      },
      expression: "selectedAttribute"
    }
  })], 1)])], 1)]), _c('b-dropdown-divider')], 1)], 1), _vm._l(this.categoryAttributesArray, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "4"
      }
    }, [_vm.assetCategoryId ? _c('div', [_c('b-form-group', {
      attrs: {
        "label": item.name,
        "label-for": item.name
      }
    }, [_c('b-form-input', {
      staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font",
      attrs: {
        "placeholder": "Search Asset  ...",
        "type": "text"
      },
      on: {
        "keyup": _vm.onSearch,
        "input": _vm.assetFiltering
      },
      model: {
        value: _vm.searchAttributeValue[index],
        callback: function callback($$v) {
          _vm.$set(_vm.searchAttributeValue, index, $$v);
        },
        expression: "searchAttributeValue[index]"
      }
    })], 1)], 1) : _vm._e()]);
  }), _vm._l(this.filteredAttributes, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "4"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": item.name,
        "label-for": item.name
      }
    }, [_c('b-form-input', {
      staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font",
      attrs: {
        "placeholder": "Search Asset  ...",
        "type": "text"
      },
      on: {
        "keyup": _vm.onSearch,
        "input": _vm.assetFiltering
      },
      model: {
        value: _vm.searchAttributeValue[index],
        callback: function callback($$v) {
          _vm.$set(_vm.searchAttributeValue, index, $$v);
        },
        expression: "searchAttributeValue[index]"
      }
    })], 1)], 1);
  })], 2)], 1), _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.$permissionAbility(_vm.ASSET_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Create ")])] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table table-custom-style striped",
      "rows": _vm.rows,
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$column, _props$row2, _props$row3, _props$row4, _props$row5, _props$row5$asset_cat, _props$column2, _props$row6, _props$row7, _props$row8, _props$row9, _props$row10, _props$row11, _props$row12, _props$row13, _props$row14, _props$row15, _props$row16, _props$row17, _props$row18, _props$row19, _props$row19$last_ass, _props$row19$last_ass2, _props$row20, _props$row20$last_ass, _props$row20$last_ass2, _props$row21, _props$row21$last_ass, _props$row21$last_ass2, _props$row22, _props$row23, _props$row23$last_ass, _props$row23$last_ass2, _props$row24, _props$row25, _props$row25$last_ass, _props$row25$last_ass2, _props$column3, _props$row26, _props$row27, _props$row28, _props$row29, _props$row30, _props$row31;
        return [props.column.field === 'format_image' ? [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_c('b-img', {
          staticClass: "custom-asset-image",
          attrs: {
            "src": props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.asset_image
          }
        })], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_info' ? [_c('h5', {
          staticClass: "mt-1"
        }, [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.title) + " ")])], 1), _c('div', {
          staticClass: "mt-1"
        }, [_c('h6', [_vm._v("Comp SN: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.company_serial_number))])]), _c('h6', [_c('b', [_vm._v("Type: ")]), _c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(_vm.formatAssetType(props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.type)))])], 1), _c('h6', [_vm._v("Category: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : (_props$row5$asset_cat = _props$row5.asset_category) === null || _props$row5$asset_cat === void 0 ? void 0 : _props$row5$asset_cat.name))])])]), _c('b-button', {
          staticClass: "btn-sm",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.showDescriptionHistory(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": "AlertCircleIcon"
          }
        })], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_cost_info' ? [_c('h6', [_vm._v(" Purchase Cost: "), _c('FormatCurrency', {
          attrs: {
            "currency": props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.currency,
            "amount": props === null || props === void 0 ? void 0 : (_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : _props$row7.cost
          }
        })], 1), _c('h6', [_vm._v(" Repair Cost: "), _c('FormatCurrency', {
          attrs: {
            "currency": props === null || props === void 0 ? void 0 : (_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.currency,
            "amount": props === null || props === void 0 ? void 0 : (_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : _props$row9.repair_cost_sum
          }
        })], 1), _c('h6', [_vm._v(" Total Cost: "), _c('FormatCurrency', {
          attrs: {
            "currency": props === null || props === void 0 ? void 0 : (_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.currency,
            "amount": props === null || props === void 0 ? void 0 : (_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : _props$row11.total_cost
          }
        })], 1), props !== null && props !== void 0 && (_props$row12 = props.row) !== null && _props$row12 !== void 0 && _props$row12.purchase_receipt ? _c('b-link', {
          attrs: {
            "href": props === null || props === void 0 ? void 0 : (_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.purchase_receipt,
            "target": "_blank"
          }
        }, [_c('FileMinusIcon')], 1) : _vm._e()] : _vm._e(), props.column.field === 'warranty_data' ? _c('span', [_c('p'), _c('div', {
          staticClass: "mb-1"
        }, [props !== null && props !== void 0 && (_props$row14 = props.row) !== null && _props$row14 !== void 0 && _props$row14.warranty_date ? [_vm._v(" Expiration Date: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : _props$row15.warranty_date))])] : [_c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v("N/A")])]], 2), _vm._v(" Days Left: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : _props$row16.warranty))]), _c('p')]) : _vm._e(), props.column.field === 'last_assigned_data' ? _c('div', [!(props !== null && props !== void 0 && (_props$row17 = props.row) !== null && _props$row17 !== void 0 && _props$row17.last_assigned) ? _c('span', [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v(" Not Set ")])], 1) : _c('span', [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-success"
          }
        }, [(props === null || props === void 0 ? void 0 : (_props$row18 = props.row) === null || _props$row18 === void 0 ? void 0 : _props$row18.type) !== 'primary' ? _c('span', [_c('span', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row19 = props.row) === null || _props$row19 === void 0 ? void 0 : (_props$row19$last_ass = _props$row19.last_assigned) === null || _props$row19$last_ass === void 0 ? void 0 : (_props$row19$last_ass2 = _props$row19$last_ass.assigned_user) === null || _props$row19$last_ass2 === void 0 ? void 0 : _props$row19$last_ass2.name))]), _c('br'), _c('span', [_c('b-link', {
          attrs: {
            "href": 'mailto:' + (props === null || props === void 0 ? void 0 : (_props$row20 = props.row) === null || _props$row20 === void 0 ? void 0 : (_props$row20$last_ass = _props$row20.last_assigned) === null || _props$row20$last_ass === void 0 ? void 0 : (_props$row20$last_ass2 = _props$row20$last_ass.assigned_user) === null || _props$row20$last_ass2 === void 0 ? void 0 : _props$row20$last_ass2.email)
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row21 = props.row) === null || _props$row21 === void 0 ? void 0 : (_props$row21$last_ass = _props$row21.last_assigned) === null || _props$row21$last_ass === void 0 ? void 0 : (_props$row21$last_ass2 = _props$row21$last_ass.assigned_user) === null || _props$row21$last_ass2 === void 0 ? void 0 : _props$row21$last_ass2.email) + " ")])], 1)]) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$row22 = props.row) === null || _props$row22 === void 0 ? void 0 : _props$row22.type) == 'primary' ? _c('span', [_c('p', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row23 = props.row) === null || _props$row23 === void 0 ? void 0 : (_props$row23$last_ass = _props$row23.last_assigned) === null || _props$row23$last_ass === void 0 ? void 0 : (_props$row23$last_ass2 = _props$row23$last_ass.asset_assigned_to) === null || _props$row23$last_ass2 === void 0 ? void 0 : _props$row23$last_ass2.title))]), props !== null && props !== void 0 && (_props$row24 = props.row) !== null && _props$row24 !== void 0 && _props$row24.last_assigned ? _c('p', [_vm._v(" Company Sl. - " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row25 = props.row) === null || _props$row25 === void 0 ? void 0 : (_props$row25$last_ass = _props$row25.last_assigned) === null || _props$row25$last_ass === void 0 ? void 0 : (_props$row25$last_ass2 = _props$row25$last_ass.asset_assigned_to) === null || _props$row25$last_ass2 === void 0 ? void 0 : _props$row25$last_ass2.company_serial_number) + " ")]) : _vm._e()]) : _vm._e()])], 1)]) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_status' ? [(props === null || props === void 0 ? void 0 : (_props$row26 = props.row) === null || _props$row26 === void 0 ? void 0 : _props$row26.status) == 'Available' ? [_c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row27 = props.row) === null || _props$row27 === void 0 ? void 0 : _props$row27.status) + " ")])] : (props === null || props === void 0 ? void 0 : (_props$row28 = props.row) === null || _props$row28 === void 0 ? void 0 : _props$row28.status) == 'Damaged' ? [_c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row29 = props.row) === null || _props$row29 === void 0 ? void 0 : _props$row29.status) + " ")])] : (props === null || props === void 0 ? void 0 : (_props$row30 = props.row) === null || _props$row30 === void 0 ? void 0 : _props$row30.status) == 'Assigned' ? [_c('b-badge', {
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row31 = props.row) === null || _props$row31 === void 0 ? void 0 : _props$row31.status) + " ")])] : _vm._e()] : _vm._e(), props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.$permissionAbility(_vm.ASSET_EDIT, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("Show")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.ASSET_EDIT, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.ASSET_DELETE, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-asset-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit' : 'Create',
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "assetValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-row', {
    staticClass: "p-1"
  }, [_vm.asset_image ? [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('label', [_vm._v("Asset Image")]), _c('b-img', {
    staticClass: "custom-asset-image",
    attrs: {
      "center": "",
      "src": _vm.asset_image,
      "alt": "Center image"
    }
  })], 1)])] : _vm._e(), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Title ",
      "label-for": "title"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "title",
      "vid": "title",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "title",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Asset Title"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Asset Type ",
      "label-for": "assetTypeId"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "asset type",
      "vid": "asset_type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "assetTypeId",
            "placeholder": "Choose Asset Type",
            "options": _vm.assetTypeConstant,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "disabled": _vm.isEditAssetTypeDisabled
          },
          model: {
            value: _vm.asset_type_modal,
            callback: function callback($$v) {
              _vm.asset_type_modal = $$v;
            },
            expression: "asset_type_modal"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Asset Category ",
      "label-for": "assetTypeId"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "asset category",
      "vid": "asset_category_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "assetCategoryId",
            "placeholder": "Choose Asset Category",
            "options": _vm.selectAssetCategoryIdOptions,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "disabled": _vm.isEditAssetCategoryIdDisabled
          },
          on: {
            "input": _vm.getCategoryWiseAttr
          },
          model: {
            value: _vm.assetCategoryId,
            callback: function callback($$v) {
              _vm.assetCategoryId = $$v;
            },
            expression: "assetCategoryId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _vm._l(_vm.categoryAttributesArray, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      staticClass: "required-label",
      attrs: {
        "label": item.name,
        "label-for": item.name
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": item.name,
        "vid": item.name,
        "rules": +item.is_numeric ? 'required|custom_gb_rule' : 'required'
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref4) {
          var errors = _ref4.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": item.name,
              "type": "text",
              "state": errors.length > 0 ? false : null,
              "placeholder": "Input Data"
            },
            model: {
              value: _vm.attributeValue[index],
              callback: function callback($$v) {
                _vm.$set(_vm.attributeValue, index, $$v);
              },
              expression: "attributeValue[index]"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1);
  }), _vm._l(_vm.editAttributeValueOptions, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": item.name,
        "label-for": item.name
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": item.name,
        "vid": item.name
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref5) {
          var errors = _ref5.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": item.name,
              "type": "text",
              "state": errors.length > 0 ? false : null,
              "placeholder": "Input Data"
            },
            model: {
              value: _vm.editAttributeValue[index],
              callback: function callback($$v) {
                _vm.$set(_vm.editAttributeValue, index, $$v);
              },
              expression: "editAttributeValue[index]"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1);
  }), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Company Serial Number ",
      "label-for": "companySerialNumber"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "company serial number",
      "vid": "company_serial_number",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "companySerialNumber",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Company Serial Number"
          },
          model: {
            value: _vm.company_serial_number,
            callback: function callback($$v) {
              _vm.company_serial_number = $$v;
            },
            expression: "company_serial_number"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Manufacturer Serial Number ",
      "label-for": "manufacturerSerialNumber"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "manufacturer serial number",
      "vid": "manufacturer_serial_number",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "manufacturerSerialNumber",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Manufacturer Serial Number"
          },
          model: {
            value: _vm.manufacturer_serial_number,
            callback: function callback($$v) {
              _vm.manufacturer_serial_number = $$v;
            },
            expression: "manufacturer_serial_number"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Currency *",
      "label-for": "currency"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "currency",
      "vid": "currency",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "currency",
            "placeholder": "Choose a Currency",
            "options": _vm.currencyOption,
            "reduce": function reduce(option) {
              return option === null || option === void 0 ? void 0 : option.currency;
            },
            "label": "currency"
          },
          model: {
            value: _vm.selectedCurrency,
            callback: function callback($$v) {
              _vm.selectedCurrency = $$v;
            },
            expression: "selectedCurrency"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Purchase Cost ",
      "label-for": "cost"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "cost",
      "vid": "cost",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "cost",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Cost"
          },
          model: {
            value: _vm.cost,
            callback: function callback($$v) {
              _vm.cost = $$v;
            },
            expression: "cost"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Purchase Receipt ",
      "label-for": "purchase_receipt"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "purchase receipt",
      "rules": "size:3048",
      "vid": "purchase_receipt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-file', {
          attrs: {
            "id": "purchase_receipt",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Choose a file or drop it here...",
            "drop-placeholder": "Drop file here..."
          },
          model: {
            value: _vm.purchaseReceipt,
            callback: function callback($$v) {
              _vm.purchaseReceipt = $$v;
            },
            expression: "purchaseReceipt"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Purchase Date ",
      "label-for": "purchaseDate"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "purchase date",
      "vid": "purchase_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-datepicker', {
          attrs: {
            "id": "purchaseDate",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": ""
          },
          on: {
            "context": _vm.onContextPurchaseDate
          },
          model: {
            value: _vm.purchase_date,
            callback: function callback($$v) {
              _vm.purchase_date = $$v;
            },
            expression: "purchase_date"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Warranty Type",
      "label-for": "warrantyType"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "warrantyType",
      "placeholder": "Choose Warranty Type",
      "options": _vm.warrantyTypeValueOption,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "name"
    },
    model: {
      value: _vm.warranty_type,
      callback: function callback($$v) {
        _vm.warranty_type = $$v;
      },
      expression: "warranty_type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Warranty Duration",
      "label-for": "warrantyDuration"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "warranty duration",
      "rules": "min_value:0",
      "vid": "warrantyDuration"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "warrantyDuration",
            "type": "number",
            "placeholder": "Warranty Duration"
          },
          model: {
            value: _vm.warranty_duration,
            callback: function callback($$v) {
              _vm.warranty_duration = $$v;
            },
            expression: "warranty_duration"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.asset_image ? [_c('b-form-group', {
    attrs: {
      "label": "Update Asset Image",
      "label-for": "asset_image"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "asset image",
      "rules": "size:2048|ext:jpeg,png,jpg",
      "vid": "asset_image"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-file', {
          attrs: {
            "id": "asset_image",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Choose a file or drop it here...",
            "drop-placeholder": "Drop file here..."
          },
          model: {
            value: _vm.file,
            callback: function callback($$v) {
              _vm.file = $$v;
            },
            expression: "file"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2638631759)
  })], 1)] : [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Asset Image ",
      "label-for": "asset_image"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "asset image",
      "rules": "required|size:2048|ext:jpeg,png,jpg",
      "vid": "asset_image"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-file', {
          attrs: {
            "id": "asset_image",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Choose a file or drop it here...",
            "drop-placeholder": "Drop file here..."
          },
          model: {
            value: _vm.file,
            callback: function callback($$v) {
              _vm.file = $$v;
            },
            expression: "file"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)]], 2), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Status ",
      "label-for": "status"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "status",
      "vid": "status",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('v-select', {
          attrs: {
            "id": "status",
            "placeholder": "Choose Status",
            "options": _vm.statusValueOption,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name"
          },
          model: {
            value: _vm.status,
            callback: function callback($$v) {
              _vm.status = $$v;
            },
            expression: "status"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _vm.modelType === 'editModel' ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, _vm._l(_vm.categoryAttributesArray, function (item, index) {
    return _c('div', {
      key: index
    }, [_vm.assetCategoryId ? _c('div', [_c('b-form-group', {
      attrs: {
        "label": item.name,
        "label-for": item.name
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": item.name,
        "vid": item.name
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref16) {
          var errors = _ref16.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": item.name,
              "type": "text",
              "state": errors.length > 0 ? false : null,
              "placeholder": "Input Data"
            },
            model: {
              value: _vm.attributeValue[index],
              callback: function callback($$v) {
                _vm.$set(_vm.attributeValue, index, $$v);
              },
              expression: "attributeValue[index]"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1) : _vm._e()]);
  }), 0) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Description ",
      "label-for": "description"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "description",
      "vid": "description",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('quill-editor', {
          style: {
            height: '13rem !important',
            paddingBottom: '5rem !important'
          },
          attrs: {
            "options": _vm.editorOption
          },
          model: {
            value: _vm.description,
            callback: function callback($$v) {
              _vm.description = $$v;
            },
            expression: "description"
          }
        }, [_c('div', {
          attrs: {
            "slot": "toolbar",
            "id": "toolbar"
          },
          slot: "toolbar"
        }, [_c('span', {
          staticClass: "ql-formats"
        }, [_c('button', {
          staticClass: "ql-bold"
        }, [_vm._v("Bold")]), _c('button', {
          staticClass: "ql-italic"
        }, [_vm._v("Italic")]), _c('button', {
          staticClass: "ql-underline"
        }, [_vm._v("Underline")]), _c('button', {
          staticClass: "ql-strike"
        }, [_vm._v("Strike")]), _c('button', {
          staticClass: "ql-blockquote"
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "ordered"
          }
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "bullet"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "sub"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "super"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "-1"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "+1"
          }
        }), _c('button', {
          staticClass: "ql-direction",
          attrs: {
            "value": "rtl"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": ""
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "center"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "right"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "justify"
          }
        }), _c('select', {
          staticClass: "ql-color"
        }), _c('select', {
          staticClass: "ql-background"
        }), _c('select', {
          staticClass: "ql-size"
        }, [_c('option', {
          attrs: {
            "value": "small"
          }
        }), _c('option', {
          attrs: {
            "selected": ""
          }
        }), _c('option', {
          attrs: {
            "value": "large"
          }
        }), _c('option', {
          attrs: {
            "value": "huge"
          }
        })]), _c('select', {
          staticClass: "ql-font"
        }), _c('select', {
          staticClass: "ql-header"
        }, [_c('option', {
          attrs: {
            "value": "1"
          }
        }, [_vm._v("Heading 1")]), _c('option', {
          attrs: {
            "value": "2"
          }
        }, [_vm._v("Heading 2")]), _c('option', {
          attrs: {
            "value": "3"
          }
        }, [_vm._v("Heading 3")]), _c('option', {
          attrs: {
            "value": "4"
          }
        }, [_vm._v("Heading 4")]), _c('option', {
          attrs: {
            "value": "5"
          }
        }, [_vm._v("Heading 5")]), _c('option', {
          attrs: {
            "value": "6"
          }
        }, [_vm._v("Heading 6")]), _c('option', {
          attrs: {
            "selected": ""
          }
        }, [_vm._v("Normal")])]), _c('button', {
          staticClass: "ql-link"
        })])])]), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 2), _vm.isAssetFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "asset-description-log-modal",
      "scrollable": "",
      "title": "Description History",
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    }
  }, [_c('b-card-text', {
    staticClass: "mt-1"
  }, [_c('app-timeline', _vm._l(_vm.assetAcitivityLog, function (log, index) {
    return _c('app-timeline-item', {
      key: index,
      attrs: {
        "variant": "primary"
      }
    }, [_c('small', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatDateTime(log === null || log === void 0 ? void 0 : log.created_at)))]), _c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatLogDescription(log))
      }
    })])]);
  }), 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }