import {
  FINANCE_MANAGEMENT_ACCESS,
  PROJECT_ACCESS,
  MILESTONE_ACCESS,
  EXPENSE_ACCESS,
  ACCOUNT_TYPE_ACCESS,
  ACCOUNT_ACCESS,
  ACCOUNT_TRANSFER_ACCESS,
  ACCOUNT_DEPOSIT_ACCESS,
  TRANSACTION_HISTORIES_ACCESS,
} from "@/helpers/permissionsConstant";
export default [
  {
    title: "Finance & Accounts",
    icon: "BookOpenIcon",
    gate: FINANCE_MANAGEMENT_ACCESS,
    class: "finance-tour",
    children: [
      {
        title: "Projects",
        route: "admin-projects",
        gate: PROJECT_ACCESS,
      },
      {
        title: "Milestones",
        route: "admin-milestones",
        gate: MILESTONE_ACCESS,
      },
      {
        title: "Invoices",
        route: "admin-invoices",
        gate: MILESTONE_ACCESS,
      },
      {
        title: "Expense",
        route: "expense",
        gate: EXPENSE_ACCESS,
      },
      {
        title: "Account Types",
        route: "admin-account-types",
        gate: ACCOUNT_TYPE_ACCESS,
      },
      {
        title: "Accounts",
        route: "admin-accounts",
        gate: ACCOUNT_ACCESS,
      },
      {
        title: "Account Deposit",
        route: "admin-account-deposit",
        gate: ACCOUNT_DEPOSIT_ACCESS,
      },
      {
        title: "Account Transfer",
        route: "admin-account-transfer",
        gate: ACCOUNT_TRANSFER_ACCESS,
      },
      {
        title: "Transaction Histories",
        route: "admin-transaction-histories",
        gate: TRANSACTION_HISTORIES_ACCESS,
      },
    ],
  },
];
