<template>

  <b-card>
    <b-row>
      <b-col cols="12" class="company-tour">
        <h4 class="mb-2">Company Info</h4>
      </b-col>

      <!-- Check In And Out Time -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12">
        <validation-observer ref="checkInOutTimeValidation">
          <b-form @submit.prevent="onCheckInOutTimeSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <span class="text-nowrap">Check In And Out Time</span>
              </b-col>
              <b-col md="8" class="align-self-center">
                <validation-provider
                  #default="{ errors }"
                  name="check_in_out_time"
                  vid="check_in_out_time"
                >
                  <div
                    v-for="(item, index) in checkInOutTime"
                    :key="item?.id"
                    class="d-inline-block mr-1"
                  >
                    <b-form-group
                      :label="item?.label"
                      :label-for="`check-${index}`"
                    >
                      <b-form-timepicker
                        :id="`check-${index}`"
                        v-model="item.value"
                        locale="en"
                      />
                    </b-form-group>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="2" class="align-self-center">
                <!-- loading button -->
                <template v-if="isCheckInOutTimeSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <!-- Work Hours -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12">
        <validation-observer ref="workHoursValidation">
          <b-form @submit.prevent="onWorkHoursSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <span class="text-nowrap">Work Hours</span>
              </b-col>
              <b-col md="2" class="align-self-center">
                <validation-provider
                  #default="{ errors }"
                  name="work_hours"
                  vid="work_hours"
                >
                  <b-form-group label="Work Hours" label-for="work_hours">
                    <b-form-timepicker
                      id="work_hours"
                      v-model="workHours"
                      locale="en"
                      :hour12="false"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="8" class="align-self-center">
                <!-- loading button -->
                <template v-if="isWorkHoursSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <!-- delay_buffer_minutes -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12">
        <validation-observer ref="delayBufferMinutesValidation">
          <b-form @submit.prevent="onDelayBufferMinutesSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <span class="text-nowrap">Delay Buffer Minutes</span>
              </b-col>
              <b-col md="2" class="align-self-center">
                <!-- name -->

                <validation-provider
                  #default="{ errors }"
                  name="Delay Buffer Minutes"
                  vid="delay_buffer_minutes"
                  rules="required|integer"
                >
                  <b-form-input
                    id="delay_buffer_minutes"
                    type="number"
                    v-model="delayBufferMinutes"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Delay Buffer Minutes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="8" class="align-self-center">
                <!-- loading button -->
                <template v-if="isDelayBufferMinutesSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <!-- extreme_delay_buffer_minutes -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12">
        <validation-observer ref="extremeDelayBufferMinutesValidation">
          <b-form @submit.prevent="onExtremeDelayBufferMinutesSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <span class="text-nowrap"
                  >Extreme Delay Buffer <br />
                  Minutes</span
                >
              </b-col>
              <b-col md="2" class="align-self-center">
                <!-- name -->

                <validation-provider
                  #default="{ errors }"
                  name="Extreme Delay Buffer Minutes"
                  vid="extreme_delay_buffer_minutes"
                  rules="required|integer"
                >
                  <b-form-input
                    id="extreme_delay_buffer_minutes"
                    type="number"
                    v-model="extremeDelayBufferMinutes"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Extreme Delay Buffer Minutes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="8" class="align-self-center">
                <!-- loading button -->
                <template v-if="isExtremeDelayBufferMinutesSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- over_time_minutes -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12">
        <validation-observer ref="overTimeMinutesValidation">
          <b-form @submit.prevent="onOverTimeMinutesSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <span class="text-nowrap">Over Time Minutes</span>
              </b-col>
              <b-col md="2" class="align-self-center">
                <!-- name -->

                <validation-provider
                  #default="{ errors }"
                  name="Over Time Minutes"
                  vid="over_time_minutes"
                  rules="required|integer"
                >
                  <b-form-input
                    id="over_time_minutes"
                    type="number"
                    v-model="overTimeMinutes"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Over Time Minutes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="8" class="align-self-center">
                <!-- loading button -->
                <template v-if="isOverTimeMinutesSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <b-col cols="12">
        <hr class="my-2" />
      </b-col>

      <b-col cols="12">
        <validation-observer ref="minOverTimeMinutesValidation">
          <b-form @submit.prevent="minOnOverTimeMinutesSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <span class="text-nowrap">Minimum Over Time <br /> Minutes</span>
              </b-col>
              <b-col md="2" class="align-self-center">
                <!-- name -->

                <validation-provider
                  #default="{ errors }"
                  name="Minimum Over Time Minutes"
                  vid="min_over_time_minutes"
                  rules="required|integer"
                >
                  <b-form-input
                    id="min_over_time_minutes"
                    type="number"
                    v-model="minOverTimeMinutes"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Minimum Over Time Minutes"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="8" class="align-self-center">
                <!-- loading button -->
                <template v-if="isMinOverTimeMinutesSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Company Logo -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12">
        <validation-observer ref="logoValidation">
          <b-form @submit.prevent="onLogoSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <span class="text-nowrap">Company Logo</span>
              </b-col>
              <b-col md="4" class="align-self-center">
                <validation-provider
                      #default="{ errors }"
                      name="Business Card (Front)"
                      rules="size:2048|ext:jpeg,png,jpg"
                      vid="business_card_front"
                  >
                    <b-form-file
                        id="business_card_front"
                        v-model="companyLogoInput"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-col>
              <b-col md="2">
                <b-img
                    :src="companyLogo"
                    class="small-image mb-1"
                ></b-img>
              </b-col>
              <b-col md="4" class="align-self-center">
                <!-- loading button -->
                <template v-if="companyLogoSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                      type="submit"
                      class="float-right"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Company Name -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12">
        <validation-observer ref="nameValidation">
          <b-form @submit.prevent="onNameSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <span class="text-nowrap">Company Name</span>
              </b-col>
              <b-col md="2" class="align-self-center">
                <!-- name -->

                <validation-provider
                    #default="{ errors }"
                    name="Company Name"
                    vid="name"
                >
                  <b-form-input
                      id="name"
                      type="text"
                      v-model="companyName"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="8" class="align-self-center">
                <!-- loading button -->
                <template v-if="isNameSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                      type="submit"
                      class="float-right"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- email -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12">
        <validation-observer ref="emailValidation">
          <b-form @submit.prevent="onEmailSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <span class="text-nowrap">Company Email</span>
              </b-col>
              <b-col md="2" class="align-self-center">
                <!-- name -->

                <validation-provider
                  #default="{ errors }"
                  name="Company Email"
                  vid="email"
                >
                  <b-form-input
                    id="email"
                    type="text"
                    v-model="companyEmail"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="8" class="align-self-center">
                <!-- loading button -->
                <template v-if="isEmailSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- mobile -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12">
        <validation-observer ref="mobileValidation">
          <b-form @submit.prevent="onMobileSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <span class="text-nowrap">Company Mobile</span>
              </b-col>
              <b-col md="2" class="align-self-center">
                <!-- name -->

                <validation-provider
                  #default="{ errors }"
                  name="Company Mobile"
                  vid="mobile"
                >
                  <b-form-input
                    id="mobile"
                    type="text"
                    v-model="companyMobile"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Mobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="8" class="align-self-center">
                <!-- loading button -->
                <template v-if="isMobileSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- address -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12">
        <validation-observer ref="addressValidation">
          <b-form @submit.prevent="onAddressSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <span class="text-nowrap">Company Address</span>
              </b-col>
              <b-col md="4" class="align-self-center">
                <!-- name -->

                <validation-provider
                    #default="{ errors }"
                    name="Company Address"
                    vid="address"
                >
                  <b-form-textarea
                      id="address"
                      type="text"
                      v-model="companyAddress"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Address"
                      rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="6" class="align-self-center">
                <!-- loading button -->
                <template v-if="isAddressSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                      type="submit"
                      class="float-right"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- invoice -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12" class="invoice-tour">
        <h4 class="mb-2">
          Invoice Number Generate: #<b-badge pill variant="primary">
            {{ invoicePrefix + invoiceCode }}
          </b-badge>
        </h4>
      </b-col>
      <b-col cols="12">
        <validation-observer ref="invoicePrefixValidation">
          <b-form @submit.prevent="onInvoicePrefixSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <!-- name -->
                <b-form-group label="Invoice Prefix" label-for="invoice-prefix">
                  <validation-provider
                    #default="{ errors }"
                    name="invoice_prefix"
                    vid="invoice_prefix"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="invoice-prefix"
                      type="text"
                      v-model="invoicePrefix"
                      :state="errors.length > 0 ? false : null"
                      placeholder="INV"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="10" class="align-self-center">
                <!-- loading button -->
                <template v-if="isInvoicePrefixSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <b-col cols="12">
        <validation-observer ref="invoiceCodeValidation">
          <b-form @submit.prevent="onInvoiceCodeSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <!-- name -->
                <b-form-group label="Invoice Code" label-for="invoice-code">
                  <validation-provider
                    #default="{ errors }"
                    name="invoice_code"
                    vid="invoice_code"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="invoice-code"
                      type="number"
                      v-model="invoiceCode"
                      :state="errors.length > 0 ? false : null"
                      placeholder="1000"
                      :disabled="isDisableInvoiceCode"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="10" class="align-self-center">
                <!-- loading button -->
                <template v-if="isInvoiceCodeSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :disabled="isDisableInvoiceCode"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <!-- payslip -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12" class="payslip-tour">
        <h4 class="mb-2">
          Payslip Number Generate: #<b-badge pill variant="primary">
            {{ payslipPrefix + payslipCode }}
          </b-badge>
        </h4>
      </b-col>
      <b-col cols="12">
        <validation-observer ref="payslipPrefixValidation">
          <b-form @submit.prevent="onPayslipPrefixSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <!-- name -->
                <b-form-group label="Invoice Prefix" label-for="payslip-prefix">
                  <validation-provider
                    #default="{ errors }"
                    name="payslip_prefix"
                    vid="payslip_prefix"
                    rules="required|max:255"
                  >
                    <b-form-input
                      id="payslip-prefix"
                      type="text"
                      v-model="payslipPrefix"
                      :state="errors.length > 0 ? false : null"
                      placeholder="PAY"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="10" class="align-self-center">
                <!-- loading button -->
                <template v-if="isPayslipPrefixSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <b-col cols="12">
        <validation-observer ref="payslipCodeValidation">
          <b-form @submit.prevent="onPayslipCodeSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <!-- name -->
                <b-form-group label="Payslip Code" label-for="payslip-code">
                  <validation-provider
                    #default="{ errors }"
                    name="payslip_code"
                    vid="payslip_code"
                    rules="required|integer"
                  >
                    <b-form-input
                      id="payslip-code"
                      type="number"
                      v-model="payslipCode"
                      :state="errors.length > 0 ? false : null"
                      placeholder="1000"
                      :disabled="isDisablePayslipCode"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="10" class="align-self-center">
                <!-- loading button -->
                <template v-if="isPayslipCodeSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :disabled="isDisablePayslipCode"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
      <!-- Check In And Out Time -->
      <b-col cols="12">
        <hr class="my-2" />
      </b-col>
      <b-col cols="12" class="default-account-tour">
        <h4 class="mb-2">Default Account:</h4>
      </b-col>
      <b-col cols="12">
        <validation-observer ref="defaultAccountValidation">
          <b-form @submit.prevent="onDefaultAccountSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <b-form-group label="Account" label-for="account-id">
                  <ValidationProvider
                    name="account"
                    v-slot="{ errors }"
                    vid="account_id"
                    rules="required"
                  >
                    <v-select
                      id="account-id"
                      v-model="selectAccountId"
                      :options="accountsOption"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="Choose a account"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col md="10" class="align-self-center">
                <!-- loading button -->
                <template v-if="isDefaultAccountSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <b-col cols="12">
        <hr class="my-2" />
      </b-col>

      <b-col cols="12" class="default-currency-tour">
        <h4 class="mb-2">Default Currency:</h4>
      </b-col>
      <b-col cols="12">
        <validation-observer ref="defaultAccountValidation">
          <b-form @submit.prevent="onDefaultCurrencySubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <b-form-group label="Currency" label-for="account-id">
                  <ValidationProvider
                    name="currency"
                    v-slot="{ errors }"
                    vid="currency_id"
                    rules="required"
                  >
                    <v-select
                      id="currency-id"
                      v-model="selectCurrencyId"
                      :options="currenciesOption"
                      :reduce="(option) => option.id"
                      label="name"
                      placeholder="Choose a currency"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col md="10" class="align-self-center">
                <!-- loading button -->
                <template v-if="isDefaultCurrencySubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <b-col cols="12">
        <hr class="my-2" />
      </b-col>

      <b-col cols="12" class="default-fiscal-year-tour">
        <h4 class="mb-2">Default Fiscal year:</h4>
      </b-col>
      <b-col cols="12">
        <validation-observer ref="defaultFiscalYearValidation">
          <b-form @submit.prevent="onDefaultFiscalYearSubmit">
            <b-row>
              <b-col md="2" class="align-self-center">
                <b-form-group label="Start Month" label-for="month">
                  <ValidationProvider
                    name="fiscalStartMonth"
                    v-slot="{ errors }"
                    vid="fiscalStartMonth"
                    rules="required"
                  >
                    <v-select
                      id="fiscalStartMonth"
                      v-model="selectFiscalStartMonth"
                      :options="monthNameConstants"
                      :reduce="(option) => option.value"
                      label="name"
                      placeholder="Choose a month"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="2" class="align-self-center">
                <b-form-group label="Next Month" label-for="month">
                  <ValidationProvider
                    name="fiscalEndMonth"
                    v-slot="{ errors }"
                    vid="fiscalEndMonth"
                    rules="required"
                  >
                    <v-select
                      id="fiscalEndMonth"
                      v-model="selectFiscalEndMonth"
                      :options="monthNameConstants"
                      :reduce="(option) => option.value"
                      label="name"
                      placeholder="Choose a month"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="8" class="align-self-center">
                <!-- loading button -->
                <template v-if="isFiscalYearSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>

      <b-col cols="12">
        <hr class="my-2" />
      </b-col>

      <b-col cols="12" class="default-date-tour">
        <h4 class="mb-2">Default Date Format:</h4>
      </b-col>

      <b-col cols="12">
        <validation-observer ref="defaultDateFormatValidation">
          <b-form @submit.prevent="onDefaultDateFormatSubmit">
            <b-row>
              <b-col md="3" class="align-self-center">
                <b-form-group label="Select a date format" label-for="dateFormat">
                  <ValidationProvider
                    name="dateFormat"
                    v-slot="{ errors }"
                    vid="dateFormat"
                    rules="required"
                  >
                    <v-select
                      id="dateFormat"
                      v-model="selectDateFormat"
                      :options="dateFormatConstants"
                      :reduce="(option) => option.value"
                      label="name"
                      placeholder="Choose a format"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="9" class="align-self-center">
                <!-- loading button -->
                <template v-if="isSelectDateFormatSubmitLoading">
                  <b-button class="float-right" variant="primary" disabled>
                    <b-spinner small />
                    Loading...
                  </b-button>
                </template>

                <!-- submit button -->
                <template v-else>
                  <b-button
                    type="submit"
                    class="float-right"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Submit
                  </b-button>
                </template>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>





    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BForm,
  BFormCheckbox,
  BCol,
  BRow,
  BButton,
  BFormTimepicker,
  BFormGroup,
  BSpinner,
  BFormInput,
  BCardText,
  BFormTextarea, BImg, BFormFile,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { monthNameConstants } from "@/helpers/constant/monthNameConstant";
import { dateFormatConstants } from "@/helpers/constant/dateFormatConstant";
import store from '@/store'
import {mapGetters} from "vuex";
import IntroJs from 'intro.js';
import 'intro.js/introjs.css';
import {EventBus} from "@/helpers/event-bus";

export default {
  name: "GeneralSettingView",
  components: {
    BFormFile,
    BImg,
    BForm,
    BCard,
    BBadge,
    BFormCheckbox,
    BCol,
    BRow,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormTimepicker,
    BFormGroup,
    BSpinner,
    BFormInput,
    BCardText,
    BFormTextarea,
    DatePicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      settingSteps: [
        {
          element: '.company-tour',
          intro: `Configure your company's basic settings, including general preferences, contact information, and your attendance configuration in the Company section.`
        },
        {
          element: '.invoice-tour',
          intro: `Set up and customize your invoicing preferences in the Invoice section.`
        },
        {
          element: '.payslip-tour',
          intro: `Manage your payroll settings in the Payslip section.`
        },
        {
          element: '.default-account-tour',
          intro: `Set the default bank account for handling transactions.`
        },
        {
          element: '.default-currency-tour',
          intro: `Set the default currency for transactions across your application. You can choose the base currency for your company's financial operations in the Currency section.`
        },
        {
          element: '.default-fiscal-year-tour',
          intro: `Define the start and end dates for your company's fiscal year. This will be used for financial reporting and budgeting purposes.`
        },
        {
          element: '.default-date-tour',
          intro: `Set the default date formats and preferences that will be used throughout the application for consistency in record-keeping and reporting.`
        }
      ],

      checkInOutTime: [],
      companyLogo: null,
      companyLogoInput: null,
      companyLogoSubmitLoading: false,
      isCheckInOutTimeSubmitLoading: false,
      // invoice
      invoicePrefix: "",
      isInvoicePrefixSubmitLoading: false,
      invoiceCode: "",
      isInvoiceCodeSubmitLoading: false,
      isDisableInvoiceCode: false,
      // payslip
      payslipPrefix: "",
      isPayslipPrefixSubmitLoading: false,
      payslipCode: "",
      isPayslipCodeSubmitLoading: false,
      isDisablePayslipCode: false,
      // account
      selectAccountId: "",
      accountsOption: [],
      isDefaultAccountSubmitLoading: false,
      // delayBufferMinutes
      isDelayBufferMinutesSubmitLoading: false,
      delayBufferMinutes: "",
      // extreme delayBufferMinutes
      isExtremeDelayBufferMinutesSubmitLoading: false,
      extremeDelayBufferMinutes: "",

      // overTimeMinutes
      isOverTimeMinutesSubmitLoading: false,
      overTimeMinutes: "",
      // overTimeMinutes

      // minimum overTimeMinutes
      isMinOverTimeMinutesSubmitLoading: false,
      minOverTimeMinutes: "",
      // overTimeMinutes

      isWorkHoursSubmitLoading: false,
      workHours: "",
      //company address
      companyAddress: "",
      isAddressSubmitLoading: false,
      //company email
      companyEmail: "",
      isEmailSubmitLoading: false,

      //company email
      companyName: "",
      isNameSubmitLoading: false,

      //company mobile
      companyMobile: "",
      isMobileSubmitLoading: false,
      // currency
      selectCurrencyId: "",
      currenciesOption: [],
      isDefaultCurrencySubmitLoading: false,

      //Fiscal Year
      selectFiscalStartMonth: "",
      selectFiscalEndMonth: "",
      isFiscalYearSubmitLoading: false,

      monthNameConstants,

      //Date Format
      dateFormatConstants,
      selectDateFormat: "",
      isSelectDateFormatSubmitLoading: false,

    };
  },

  mounted() {

    EventBus.$on('start-setting-tour', () => {
      this.startTour()
    })

    setTimeout(() => {
      if (!this.authUser?.settingsVisited) {
          this.startTour()
        // Call API to mark settings as visited
        this.$api.put(`api/users/settings-visited`);
      }
    }, 500)
  },

  computed: {
    ...mapGetters({
      authUser: "userModule/getUser",
    }),
  },

  async created() {
    try {
      const [getSetting, getInvoiceSetting, getAccounts, getCurrencies, company] =
        await Promise.all([
          this.getSetting({
            select:
              "check_in_out_time,account_id,currency_id,delay_buffer_minutes,over_time_minutes,minimum_over_time_minutes,work_hours,address,email,mobile,extreme_delay_buffer_minutes,name,fiscal_start_month,fiscal_end_month,date_format",
          }),
          this.getInvoiceSetting(),
          this.getAccounts(),
          this.getCurrencies(),
          this.getCompany(),
        ]);

      // getSetting
      this.checkInOutTime = getSetting?.data?.data?.check_in_out_time;
      this.selectAccountId = getSetting?.data?.data?.account_id;
      this.selectCurrencyId = getSetting?.data?.data?.currency_id;
      this.companyAddress = getSetting?.data?.data?.address;
      this.companyEmail = getSetting?.data?.data?.email;
      this.companyName = getSetting?.data?.data?.name;
      this.companyMobile = getSetting?.data?.data?.mobile;
      this.selectFiscalStartMonth = getSetting?.data?.data?.fiscal_start_month;
      this.selectFiscalEndMonth = getSetting?.data?.data?.fiscal_end_month;
      this.selectDateFormat = getSetting?.data?.data?.date_format;
      this.companyLogo = company?.data?.data?.logo;


      // getInvoicePrefix
      this.invoicePrefix = getInvoiceSetting?.data?.data?.invoice_prefix;
      this.invoiceCode = getInvoiceSetting?.data?.data?.invoice_code;
      this.isDisableInvoiceCode = getInvoiceSetting?.data?.data?.ic_status;

      // Payslip
      this.payslipPrefix = getInvoiceSetting?.data?.data?.payslip_prefix;
      this.payslipCode = getInvoiceSetting?.data?.data?.payslip_code;
      this.isDisablePayslipCode = getInvoiceSetting?.data?.data?.pc_status;

      // delay_buffer_minutes
      this.delayBufferMinutes =
        getSetting?.data?.data?.delay_buffer_minutes?.toString();

      // extreme_delay_buffer_minutes
      this.extremeDelayBufferMinutes =
        getSetting?.data?.data?.extreme_delay_buffer_minutes?.toString();

      // over_time_minutes
      this.overTimeMinutes =
        getSetting?.data?.data?.over_time_minutes?.toString();

      this.minOverTimeMinutes =
        getSetting?.data?.data?.minimum_over_time_minutes?.toString();

      // work_hours
      this.workHours = getSetting?.data?.data?.work_hours;

      // getAccounts
      this.accountsOption = getAccounts?.data?.data?.map((item) => {
        let name = `${item?.name} (${item?.currency} ${item?.balance})`;
        return {
          name,
          id: item?.id,
        };
      });

      // getCurrencies
      this.currenciesOption = getCurrencies?.data?.data?.map((item) => {
        let name = `${item?.currency}`;
        return {
          name,
          id: item?.id,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {

    startTour(){
      this.settingSteps = this.settingSteps.filter(step => document.querySelector(step.element))

      const intro = IntroJs();
      intro.setOptions({
        steps: this.settingSteps,
        showStepNumbers: true,
        showBullets: true,
        exitOnOverlayClick: false,
        hidePrev: true,
        hideNext: true,
        nextToDone: true,
        nextLabel: 'Next',
        prevLabel: 'Prev',
      });
      intro.start();
    },
    async getSetting(params) {
      return await this.$api.get("/api/settings", {
        params: {
          select: params.select,
        },
      });
    },
    async getInvoiceSetting() {
      return await this.$api.get("/api/settings/invoice");
    },
    async getAccounts() {
      return await this.$api.get("/api/accounts/active/all");
    },
    async getCompany() {
      return await this.$api.get("/api/companies/current");
    },
    async getCurrencies() {
      return await this.$api.get("/api/currencies/all");
    },
    async onWorkHoursSubmit() {
      this.$refs.workHoursValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isWorkHoursSubmitLoading = true;
            await this.$api.put("/api/settings/update", {
              work_hours: this.workHours,
            });
            this.isWorkHoursSubmitLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Work Hours successfully updated",
              },
            });
          } catch (error) {
            this.isWorkHoursSubmitLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.workHoursValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
    async onCheckInOutTimeSubmit() {
      this.$refs.checkInOutTimeValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isCheckInOutTimeSubmitLoading = true;
            await this.$api.put("/api/settings/update", {
              check_in_out_time: this.checkInOutTime,
            });
            this.isCheckInOutTimeSubmitLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Check in out successfully updated",
              },
            });
          } catch (error) {
            this.isCheckInOutTimeSubmitLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.checkInOutTimeValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
    async onInvoicePrefixSubmit() {
      this.$refs.invoicePrefixValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isInvoicePrefixSubmitLoading = true;
            await this.$api.put("/api/settings/invoice/inv/prefix/update", {
              invoice_prefix: this.invoicePrefix,
            });
            this.isInvoicePrefixSubmitLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Invoice Prefix Successfully Updated",
              },
            });
          } catch (error) {
            this.isInvoicePrefixSubmitLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.invoicePrefixValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
    async onInvoiceCodeSubmit() {
      this.$refs.invoiceCodeValidation.validate().then(async (success) => {
        if (success) {
          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Generate Invoice Code?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.isInvoiceCodeSubmitLoading = true;
                await this.$api.put("/api/settings/invoice/inv/code/update", {
                  invoice_code: this.invoiceCode,
                });
                this.isDisableInvoiceCode = true;
                this.isInvoiceCodeSubmitLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Invoice Code Successfully Updated",
                  },
                });
              } catch (error) {
                this.isInvoiceCodeSubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.invoiceCodeValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },
    async onPayslipPrefixSubmit() {
      this.$refs.payslipPrefixValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.isPayslipPrefixSubmitLoading = true;
            await this.$api.put("/api/settings/invoice/pay/prefix/update", {
              payslip_prefix: this.payslipPrefix,
            });
            this.isPayslipPrefixSubmitLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Payslip Prefix Successfully Updated",
              },
            });
          } catch (error) {
            this.isPayslipPrefixSubmitLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.payslipPrefixValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
    async onPayslipCodeSubmit() {
      this.$refs.payslipCodeValidation.validate().then(async (success) => {
        if (success) {
          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Generate Payslip Code?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.isPayslipCodeSubmitLoading = true;
                await this.$api.put("/api/settings/invoice/pay/code/update", {
                  payslip_code: this.payslipCode,
                });
                this.isDisablePayslipCode = true;
                this.isPayslipCodeSubmitLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Payslip Code Successfully Updated",
                  },
                });
              } catch (error) {
                this.isPayslipCodeSubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.payslipCodeValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },
    async onDefaultAccountSubmit() {
      this.$refs.defaultAccountValidation.validate().then(async (success) => {
        if (success) {
          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Set Default Account?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.isDefaultAccountSubmitLoading = true;
                await this.$api.put("/api/settings/update", {
                  account_id: this.selectAccountId,
                });
                this.isDefaultAccountSubmitLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Default Account Successfully Updated",
                  },
                });
              } catch (error) {
                this.isDefaultAccountSubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.defaultAccountValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },
    async onDefaultCurrencySubmit() {
      this.$refs.defaultAccountValidation.validate().then(async (success) => {
        if (success) {
          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Set Default Currency?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.isDefaultCurrencySubmitLoading = true;
                await this.$api.put("/api/settings/update", {
                  currency_id: this.selectCurrencyId,
                });
                this.isDefaultCurrencySubmitLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Default Currency Successfully Updated",
                  },
                });
              } catch (error) {
                this.isDefaultCurrencySubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.defaultAccountValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },
    async onExtremeDelayBufferMinutesSubmit() {
      this.$refs.extremeDelayBufferMinutesValidation
        .validate()
        .then(async (success) => {
          if (success) {
            this.$swal({
              title: "Warning!",
              text: "Are You Sure You Want To Set Extreme Delay Buffer Minutes?",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
              showCancelButton: true,
              confirmButtonText: "Ok",
              showLoaderOnConfirm: true,
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  this.isExtremeDelayBufferMinutesSubmitLoading = true;
                  await this.$api.put("/api/settings/update", {
                    extreme_delay_buffer_minutes:
                      this.extremeDelayBufferMinutes,
                  });
                  this.isExtremeDelayBufferMinutesSubmitLoading = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Success",
                      icon: "BellIcon",
                      variant: "success",
                      text: "Extreme Delay Buffer Minutes Successfully Updated",
                    },
                  });
                } catch (error) {
                  this.isExtremeDelayBufferMinutesSubmitLoading = false;

                  if (error?.response?.data?.message) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Error",
                        icon: "BellIcon",
                        variant: "danger",
                        text: error?.response?.data?.message,
                      },
                    });
                  }

                  if (error?.response?.data?.errors) {
                    this.$refs.delayBufferMinutesValidation.setErrors(
                      error?.response?.data?.errors
                    );
                  }
                }
              }
            });
          }
        });
    },
    async onDelayBufferMinutesSubmit() {
      this.$refs.delayBufferMinutesValidation
        .validate()
        .then(async (success) => {
          if (success) {
            this.$swal({
              title: "Warning!",
              text: "Are You Sure You Want To Set Delay Buffer Minutes?",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-danger ml-1",
              },
              buttonsStyling: false,
              showCancelButton: true,
              confirmButtonText: "Ok",
              showLoaderOnConfirm: true,
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  this.isDelayBufferMinutesSubmitLoading = true;
                  await this.$api.put("/api/settings/update", {
                    delay_buffer_minutes: this.delayBufferMinutes,
                  });
                  this.isDelayBufferMinutesSubmitLoading = false;
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Success",
                      icon: "BellIcon",
                      variant: "success",
                      text: "Delay Buffer Minutes Successfully Updated",
                    },
                  });
                } catch (error) {
                  this.isDelayBufferMinutesSubmitLoading = false;

                  if (error?.response?.data?.message) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Error",
                        icon: "BellIcon",
                        variant: "danger",
                        text: error?.response?.data?.message,
                      },
                    });
                  }

                  if (error?.response?.data?.errors) {
                    this.$refs.delayBufferMinutesValidation.setErrors(
                      error?.response?.data?.errors
                    );
                  }
                }
              }
            });
          }
        });
    },
    async onOverTimeMinutesSubmit() {
      this.$refs.overTimeMinutesValidation.validate().then(async (success) => {
        if (success) {
          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Set Over Time Minutes?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.isOverTimeMinutesSubmitLoading = true;
                await this.$api.put("/api/settings/update", {
                  over_time_minutes: this.overTimeMinutes,
                });
                this.isOverTimeMinutesSubmitLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Over Time Minutes Successfully Updated",
                  },
                });
              } catch (error) {
                this.isOverTimeMinutesSubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.overTimeMinutesValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },
    async minOnOverTimeMinutesSubmit() {
      this.$refs.minOverTimeMinutesValidation.validate().then(async (success) => {
        if (success) {
          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Set Minimum Over Time Minutes?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.isMinOverTimeMinutesSubmitLoading = true;
                await this.$api.put("/api/settings/update", {
                  min_over_time_minutes: this.minOverTimeMinutes,
                });
                this.isMinOverTimeMinutesSubmitLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Over Time Minutes Successfully Updated",
                  },
                });
              } catch (error) {
                this.isMinOverTimeMinutesSubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.minOnOverTimeMinutesSubmit.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },
    async onAddressSubmit() {
      this.$refs.addressValidation.validate().then(async (success) => {
        if (success) {
          if (!this.companyAddress) {
            // Address is null, show the toast message
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: "Company address is required",
              },
            });
            return;
          }
          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Set Address?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.isAddressSubmitLoading = true;
                await this.$api.put("/api/settings/update", {
                  address: this.companyAddress,
                });
                this.isAddressSubmitLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Company address successfully updated",
                  },
                });
              } catch (error) {
                this.isAddressSubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.delayBufferMinutesValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },
    async onNameSubmit() {
      this.$refs.nameValidation.validate().then(async (success) => {
        if (success) {
          if (!this.companyName) {
            // Address is null, show the toast message
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: "Company Name is required",
              },
            });
            return;
          }

          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Set Name?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.isNameSubmitLoading = true;
                await this.$api.put("/api/settings/update", {
                  name: this.companyName,
                });
                this.isNameSubmitLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Company name successfully updated",
                  },
                });
              } catch (error) {
                this.isEmailSubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.delayBufferMinutesValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },
    async onLogoSubmit() {
      this.$refs.logoValidation.validate().then(async (success) => {
        if (success) {
          if (!this.companyLogoInput) {
            // Address is null, show the toast message
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: "Company Logo is required",
              },
            });
            return;
          }

          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Update Logo?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.companyLogoSubmitLoading = true;

                const formData = new FormData();

                formData.append("_method", "PUT");

                if (this.companyLogoInput) {
                  formData.append("logo", this.companyLogoInput);
                }

                const company = await this.$api.post("/api/companies/" + this.authUser?.company_id, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                });

                this.companyLogo = company.data.data.logo

                this.companyLogoSubmitLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Company logo successfully updated",
                  },
                });

                // Set new logo to store
                const resCompany = await this.$api.get('/api/companies/current')

                if(resCompany) {
                  await this.$store.dispatch('authModule/setCompanyLogo', {
                  companyLogo: resCompany?.data?.data?.logo,
                })
                }

              } catch (error) {
                this.isEmailSubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.delayBufferMinutesValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },

    async onEmailSubmit() {
      this.$refs.emailValidation.validate().then(async (success) => {
        if (success) {
          if (!this.companyEmail) {
            // Address is null, show the toast message
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: "Company email is required",
              },
            });
            return;
          }

          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Set Email?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.isEmailSubmitLoading = true;
                await this.$api.put("/api/settings/update", {
                  email: this.companyEmail,
                });
                this.isEmailSubmitLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Company email successfully updated",
                  },
                });
              } catch (error) {
                this.isEmailSubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.delayBufferMinutesValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },

    async onMobileSubmit() {
      this.$refs.mobileValidation.validate().then(async (success) => {
        if (success) {
          if (!this.companyMobile) {
            // mobile is null, show the toast message
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: "Company mobile number is required",
              },
            });
            return;
          }
          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Set Mobile?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.isMobileSubmitLoading = true;
                await this.$api.put("/api/settings/update", {
                  mobile: this.companyMobile,
                });
                this.isMobileSubmitLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Company mobile number successfully updated",
                  },
                });
              } catch (error) {
                this.isMobileSubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.delayBufferMinutesValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },

    async onDefaultFiscalYearSubmit() {
      this.$refs.defaultFiscalYearValidation.validate().then(async (success) => {
        if (success) {
          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Set Default Fiscal Year?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.isFiscalYearSubmitLoading = true;
                await this.$api.put("/api/settings/update", {
                  fiscal_start_month: this.selectFiscalStartMonth,
                  fiscal_end_month: this.selectFiscalEndMonth,
                });
                this.isFiscalYearSubmitLoading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Default Fiscal Year Successfully Updated",
                  },
                });
              } catch (error) {
                this.isFiscalYearSubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.defaultFiscalYearValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },

    async onDefaultDateFormatSubmit() {
      this.$refs.defaultDateFormatValidation.validate().then(async (success) => {
        if (success) {
          this.$swal({
            title: "Warning!",
            text: "Are You Sure You Want To Set Default Date Format?",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Ok",
            showLoaderOnConfirm: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              try {
                this.isSelectDateFormatSubmitLoading = true;
                const settings = await this.$api.put("/api/settings/update", {
                  date_format: this.selectDateFormat,
                });
                this.isSelectDateFormatSubmitLoading = false;
                store.commit("app/setGlobalDateFormat", settings?.data?.data?.date_format)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Default Date Format Successfully Updated",
                  },
                });
              } catch (error) {
                this.isSelectDateFormatSubmitLoading = false;

                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Error",
                      icon: "BellIcon",
                      variant: "danger",
                      text: error?.response?.data?.message,
                    },
                  });
                }

                if (error?.response?.data?.errors) {
                  this.$refs.defaultFiscalYearValidation.setErrors(
                    error?.response?.data?.errors
                  );
                }
              }
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.small-image {
  max-width: 150px; /* Limits the width to make the image smaller */
  height: auto; /* Maintains the aspect ratio */
}
</style>
