<template>
  <b-card title="Lead Status">
    <!-- chart -->
    <template v-if="data?.datasets?.length > 0">

      <template v-if="data?.datasets[0]?.data?.length > 0">
        <chartjs-component-doughnut-chart
            :height="275"
            :data="data"
            :options="options"
            class="mb-3"
        />
      </template>
      <template v-else>
        <p class="text-center font-weight-bold">No Data Found</p>
      </template>


    </template>
    <template v-else>
      <span class="d-flex justify-content-center align-items-center">
        <b-spinner variant="primary"/>
      </span>
    </template>
    <!--/ chart -->

    <!-- stocks -->
    <div
        v-for="(lead,index) in leadStatus"
        :key="lead.id"
        :class="index < leadStatus.length-1 ? 'mb-1':''"
        class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <feather-icon
            :icon="'TargetIcon'"
            size="16"
            :class="lead.color_code"
        />
        <span class="font-weight-bold ml-75 mr-25">{{ lead.name }}</span>
      </div>
      <div>
        <b-badge :style="{ backgroundColor: lead.color_code, color: 'white' }" >{{ lead.leads_count }}</b-badge>
      </div>
    </div>
    <!--/ stocks -->
  </b-card>
</template>

<script>
import {BBadge, BCard, BSpinner} from 'bootstrap-vue'
import ChartjsComponentDoughnutChart from './charts-components/ChartjsComponentDoughnutChart.vue'
import chartjsData from './chartjsData'
import {$themeColors} from "@themeConfig";

export default {
  components: {
    BSpinner,
    ChartjsComponentDoughnutChart,
    BCard,
    BBadge
  },
  data() {
    return {
      chartjsData,
      leadStatus: [],
      chartColors: {
        primaryColorShade: '#836AF9',
        yellowColor: '#ffe800',
        successColorShade: '#28dac6',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
      },
      data: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 60,
        legend: { display: false },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[0].labels[tooltipItem.index] || ''
              const value = data.datasets[0].data[tooltipItem.index]
              const total = data.datasets[0].data.reduce((acc, val) => acc + val, 0);
              const percentage = ((value / total) * 100).toFixed(2);
              return ` ${label} : ${percentage}%`
            },
          },
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: '',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
      },
    }
  },
  async mounted() {
    const [status] = await Promise.all([this.getLeadStatus()]);
    this.leadStatus = status.data.data;

    let labelData = [];
    let countData = [];
    let colorData = [];

    for (let key in this.leadStatus) {
      if (this.leadStatus.hasOwnProperty(key)) {
        const item = this.leadStatus[key];
        labelData.push(item.name);
        countData.push(item.leads_count);
        colorData.push(item.color_code);
      }
    }


    this.data = {
      datasets: [
        {
          labels: labelData,
          data: countData,
          backgroundColor: colorData,
          borderWidth: 0,
          pointStyle: 'rectRounded',
        },
      ],
    }

    this.options.tooltips.shadowColor = this.chartColors.tooltipShadow
  },
  methods: {
    async getLeadStatus() {
      return this.$api.get("api/lead-status/active-all");
    },
  }
}
</script>
